import React from 'react';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

const StepField = ({ stepData, currentState }) => {
  const getTextColor = (isCurrentStep) => (isCurrentStep ? 'black' : '#e0e0e0');
  return (
    <List sx={{ marginBottom: '20px' }}>
      <ListItem>
        <ListItemText primary={stepData[currentState]} />
        <Box
          sx={{
            display: { xs: 'none', sm: 'flex' },
            flexWrap: 'wrap',
          }}
        >
          {
            stepData.map((item, index) => {
              const isCurrentStep = index === currentState;
              return (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'baseline',
                    backgroundColor: isCurrentStep ? '#e0e0e0' : 'white',
                    border: '1px solid #e0e0e0',
                    marginRight: '5px',
                    padding: '5px 10px',
                    borderRadius: '8px',
                    width: 'fit-content',
                  }}
                  key={`step-${index}`}
                >
                  <Typography sx={{ fontSize: '20px', fontWeight: 'bold', marginRight: '5px', color: getTextColor(isCurrentStep), }}>{index + 1}</Typography>
                  <Typography sx={{ fontSize: '14px', color: getTextColor(isCurrentStep), }}>{item}</Typography>
                </Box>
              )
            })
          }
        </Box>
      </ListItem>
      <Divider component="li" />
    </List >
  );
}

export default StepField;