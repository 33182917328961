import React from 'react';
import addGroupStyle from '../group.module.scss';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

const FieldContainer = ({ label, children }) => (
    <Grid container sx={{ display: 'flex',  justifyContent: { xs: "center", sm: 'flex-start' }, alignItems: 'center', margin: '0 0 20px 0' }} spacing={0}>
        <Grid item xs={4} md={2} >
            <label className={addGroupStyle.labelField}>{label}</label>
        </Grid>
        <Grid item xs={6} md={4}>
            <Box>
                {children}
            </Box>
        </Grid>
    </Grid>
);

export default FieldContainer;