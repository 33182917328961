import React from 'react';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

import FieldContainer from "./FieldContainer";

import { ReactComponent as InputClearBtn } from 'assets/img/icon/btn_input_clear.svg';

export const InputObj = ({ id, placeholder, type, error, onChange }) => (
  <TextField
    sx={{
      '& .MuiOutlinedInput-root': {
        height: '40px', borderRadius: '10px',
        '& fieldset': { borderColor: '#EEF0F2', }
      },
    }}
    id={id}
    variant="outlined"
    type={type}
    error={error}
    placeholder={placeholder}
    onChange={onChange}
  />
);

const InputField = ({ label, id, placeholder, type, error, onChange}) => (
  <FieldContainer label={label}>
    <Box sx={{ minWidth: 180 }} noValidate autoComplete="off">
      <FormControl fullWidth>
        <InputObj
          id={id}
          placeholder={placeholder}
          type={type}
          error={error}
          onChange={onChange}
        />
      </FormControl>
    </Box>
  </FieldContainer>
);

export default InputField;