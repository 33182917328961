import dayjs from "dayjs";

export const printDatesInRange = (start, end) => {
  const dates = [];
  for (let date = start; date <= end; date = date.add(1, "day")) {
    dates.push(date.format("MMMM DD YYYY"));
  }
  return dates;
};

