import React from 'react';

import dayjs from 'dayjs';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormHelperText from '@mui/material/FormHelperText';

import FieldContainer from "./FieldContainer";

import { ReactComponent as DatePickerBtn } from 'assets/img/icon/icn_menu_schedule.svg';

export const DatePickerInput = ({ formatDate, value, onChange, helperText }) => {
    const dateValue = value ? dayjs(value) : null;
    return (
        <DatePicker
            sx={{
                '& .MuiInputBase-root': {
                    height: '40px',
                    borderRadius: '10px',
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: helperText ? '#D3302F' : '#EEF0F2',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                        borderColor: "#454D76",
                    },
                },
            }}
            slots={{ openPickerIcon: () => <DatePickerBtn /> }}
            format={formatDate}
            value={dateValue}
            onChange={onChange}
        />
    );
}

const DatepickerField = ({ label, formatDate, value, onChange, helperText }) => (
    <FieldContainer label={label}>
        <Box
            sx={{ minWidth: 180 }}
        >
            <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePickerInput
                        formatDate={formatDate}
                        value={value}
                        onChange={onChange}
                        helperText={helperText}
                    />
                </LocalizationProvider>
                <FormHelperText error={!!helperText}>{helperText}</FormHelperText>
            </FormControl>
        </Box>
    </FieldContainer>
);

export default DatepickerField;