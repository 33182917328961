import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as OpenInNew } from "assets/img/icon/btn_external_expand.svg";
import Carousel from "./Carousel";

const Cards = ({ children, numPages }) => {
  const [currentPage, setCurrentPage] = useState(0);

  const startIndex = currentPage * 2;
  /*   const endIndex = Math.min(startIndex + 2, children.length); */

  /*   const firstItem =
    children.length === 1 ? (
      <Grid item xs={5} style={{ flex: 1 }} />
    ) : (
      <Grid item xs={5} style={{ flex: 1 }}>
        {children[startIndex]}
      </Grid>
    ); */

  return (
    <Card
      className="card"
      variant="outlined"
      style={{ position: "relative", borderRadius: "20px", height: "163px" }}
    >
      <CardContent
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <IconButton
          style={{ position: "absolute", top: 8, right: 12 }}
          disableRipple
          sx={{
            "&:hover": {
              cursor: "not-allowed",
            },
          }}
        >
          <OpenInNew />
        </IconButton>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            paddingTop: "20px",
          }}
        >
          {children}
        </div>
        {/* <Box sx={{ p: 2 }}>
          <Carousel
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            numPages={numPages}
          />
        </Box> */}
      </CardContent>
    </Card>
  );
};

export default Cards;
