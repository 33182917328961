import React from 'react';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import FieldContainer from "./FieldContainer";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import { ReactComponent as InputClearBtn } from 'assets/img/icon/btn_input_clear.svg';

export const RadioObj = ({ id, defaultVal, value, onChange }) => (
  <RadioGroup row
    aria-labelledby="demo-radio-buttons-group-label"
    defaultValue={defaultVal}
    name="radio-buttons-group"
    id={id}
  >
    {value.map((item, index) => (
      <FormControlLabel key={index} value={item} label={item} 
        control={
          <Radio 
            sx={{ color: '#6E738C', '&.Mui-checked': { color: '#0A87A9',}, }} 
            onChange={onChange}
          />
        }
      />
    ))}
  </RadioGroup>
);

const RadioField = ({ label, id, defaultVal, value, onChange}) => (
  <FieldContainer label={label}>
    <Box sx={{ minWidth: 180 }} noValidate autoComplete="off">
      <FormControl fullWidth>
        <RadioObj
          id={id}
          defaultVal={defaultVal}
          value={value}
          onChange={onChange}
        />
      </FormControl>
    </Box>
  </FieldContainer>
);

export default RadioField;