//** Required */
export const PATH = {
  // login: {
  //   main: "/login",
  //   name: "Login",
  // },
  dashboard: {
    main: "/dashboard",
    name: "首頁",
  },
  summary: {
    main: "/summary",
    name: "健康追蹤",
  },
  recipient_setting: {
    main: "/recipient_setting",
    name: "照護資訊",
  },
  devices: {
    main: "/devices",
    name: "智慧家庭管理",
  },
  calendar: {
    main: "/calendar",
    name: "行事曆",
  },
  event: {
    main: "/event",
    name: "通知訊息",
  },
  user_setting: {
    main: "/user_setting",
    name: "User Setting",
  },
  add_recipient: {
    main: "/add_recipient",
    name: "add Recipient",
  },
  add_group: {
    main: "/add_group",
    name: "add HomeGroup",
  },
  IoTDashboard: {
    main: "/IoTDashboard",
    name: "old Dashboard",
  },
  question: {
    main: "/question",
    name: "支援",
  },
  policy: {
    main: "/policy",
    name: "隱私權政策",
  }
};
//** Other */
export const OPEN_ALL = {
  enableOrgDp: true,
  enableSiteDp: true,
};

export const FORBID_ALL = {
  enableOrgDp: false,
  enableSiteDp: false,
};