import React from 'react';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import FormHelperText from '@mui/material/FormHelperText';

import FieldContainer from './FieldContainer';


export const TimePickerInput = ({ value, onChange, views, format, isAMPM, helperText }) => (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={['TimePicker']}>
            <FormControl sx={{ overflow: 'hidden' }}>
                <TimePicker
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            height: '40px',
                            borderRadius: '10px',
                            '& fieldset': {
                                borderColor: helperText ? '#D3302F' : '#EEF0F2',
                            }
                        },
                    }}
                    value={value}
                    onChange={onChange}
                    views={views}
                    format={format}
                    ampm={isAMPM}
                />
                <FormHelperText error={!!helperText}>{helperText}</FormHelperText>
            </FormControl>
        </DemoContainer>
    </LocalizationProvider>
);


const TimePickerField = ({ label, value, onChange, views, format, isAMPM, helperText }) => (
    <FieldContainer label={label}>
        <Box sx={{ minWidth: 180 }}>
            <FormControl fullWidth>
                <TimePickerInput
                    value={value}
                    onChange={onChange}
                    views={views}
                    format={format}
                    ampm={isAMPM}
                    helperText={helperText}
                />
            </FormControl>
        </Box>
    </FieldContainer>
);

export default TimePickerField;