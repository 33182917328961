// Verify whether the token exists
export const isAuthenticated = () => {
  const token = localStorage.getItem("token");
  return !!token;
};

// Function to check if the token is expired
export const isTokenExpired = () => {
  const expiresTime = localStorage.getItem('expiresTime');
  const currentTime = Date.now() / 1000;

  return expiresTime ? currentTime >= parseInt(expiresTime, 10) : true;
};

