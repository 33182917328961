import { useState, useEffect } from "react";
import mainStyle from "./dashboard.module.scss";
// import { tabTheme, panelTheme } from './style';

import { useWebSocket } from "hook/WebSocketContext";
import { useAuth } from "../../hook/AuthContext";
import { useOutletContext } from "react-router-dom";
import { cloneDeep, isNil } from "lodash";
import { Dimensions } from 'react-native-web';
import { useSelector, useDispatch } from "react-redux";
import { useMediaQuery, useTheme } from "@mui/material";

import ModalContainer from 'components/ModalContainer';
import ModalforCheck from 'components/ModalforCheck';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Stack from "@mui/joy/Stack";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import Card from "@mui/material/Card";
import Typography from '@mui/material/Typography';
import CardContent from "@mui/material/CardContent";
import Healthcare from "./Healthcare";
import Calendar from "./Calendar";
import settingIcon from "assets/img/icon/icn_profile_setting.svg";
import eventIcon from "assets/img/icon/icn_profile_event.svg";
// import Footer from "ui/Footer";

const Dashboard = () => {
  // if (!userView) {
  //   navigation('/login');
  // }
  const [wsData, setWsData] = useOutletContext();
  const ws = useWebSocket();
  const { token } = useAuth();

  const userInfo = useSelector((state) => state.user.userInfo);
  const [openModal, setOpenModal] = useState(false);
  const [user, setUser] = useState({});
  
  useEffect(() => {
    if(Object.keys(userInfo).length !== 0){
      if(isNil(userInfo.lastUsedHomegroupId)){
        setOpenModal(true)
      }else{
        setUser(userInfo)
      }
    }
    // console.log(userInfo)
  }, [userInfo]);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(1440));
  const isMobilePPI = useMediaQuery(theme.breakpoints.down(768)); // 390x844, 430x932
  const [hideCalendar, setHideCalendar] = useState(false);
  const [fullScreenArrange, setFullScreenArrange] = useState(true);
  const windowHeightMobile = Dimensions.get('window').height-100;

  const getSizeH = () => ({
    wHeight: window.innerHeight 
    //Dimensions.get('window').height,
    // innerWidth: window.innerWidth
  })

  const handleSizeH = () => {
    setWindowHeightWeb(getSizeH().wHeight-200)
  }

  useEffect(() => {
    window.addEventListener('resize', handleSizeH)
    return () => window.removeEventListener('resize', handleSizeH)
  }, []);

  const orignHeight = window.innerHeight>1023? window.innerHeight-200: 815;
  // console.log(Dimensions.get('window').height)
  // console.log(orignHeight)
  const [windowHeightWeb, setWindowHeightWeb] = useState(orignHeight);

  // const windowHeightWebChild = Dimensions.get('window').height-250;
  // const windowHeightTab = Dimensions.get('window').height-255;
  // fullScreenArrange||hideCalendar?  '860px': 
  const [tabValue, setTabValue] = useState('1');
  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleChangeView = () => {
    let val = cloneDeep(hideCalendar);
    setHideCalendar(!val);
  };

  useEffect(() => {
    if(isSmallScreen){ setFullScreenArrange(true) }
    else{ setFullScreenArrange(false) }
  }, [isSmallScreen]);

  useEffect(() => {
    if(isMobilePPI){ setFullScreenArrange(false) }
    else{
      if(isSmallScreen){ setFullScreenArrange(true) }
      else{ setFullScreenArrange(false) }
    }
  }, [isMobilePPI]);

  // call all device
  // const getBoxData = () => {
  //   const boxesSendData = {
  //     resource: `/boxes`,
  //     verb: "read",
  //     accessToken: token,
  //   };
  //   ws.send(JSON.stringify(boxesSendData));
  // };

  // useEffect(() => {
  //   getBoxData();
  // }, []);

  // const panelTheme_H = {
  //   padding: '20px 20px 10px 20px', 
  //   position: "relative",
  //   border: '1px solid', 
  //   borderRadius: '0px 20px 20px 20px', 
  //   borderColor: 'rgba(0, 0, 0, 0.09)', 
  //   backgroundColor:'white',
  //   minHeight: windowHeightTab+'px'
  // }
  // const panelTheme_C = {
  //   padding: '20px 20px 10px 20px', 
  //   position: "relative",
  //   border: '1px solid', 
  //   borderRadius: '0px 20px 20px 20px', 
  //   borderColor: 'rgba(0, 0, 0, 0.09)', 
  //   backgroundColor:'white',
  //   minHeight: windowHeightTab+'px',
  //   maxHeight: windowHeightTab+'px',
  //   overflow: 'auto'
  // }

  return (
    <>
      <ModalContainer 
        display={openModal}
        modalWidth={'750px'} //550px, 750px, empty=1000px,
        children={
          <ModalforCheck 
            title={'Notice'}
            content={'Please join the home group and configure the device.'}
            checkBtn={'Get Started'}
            setOpenModal={setOpenModal}
            navigatePage={'add_group'}
          />
        }
      />
      <>
        <Grid container style={{ justifyContent:'space-between'}}> {/* minHeight: hideCalendar? windowHeightWeb+'px': '', */}
          <Grid item style={{ width: isMobilePPI||fullScreenArrange||hideCalendar? '100%': '65%', marginBottom: hideCalendar? '':'25px',
            minHeight: isMobilePPI? windowHeightMobile+'px': windowHeightWeb+'px',
            // maxHeight: hideCalendar||fullScreenArrange? '960px': ''
            }}>
            {/* maxHeight: isMobilePPI? windowHeightMobile+'px': windowHeightWeb+'px' */}
            { isMobilePPI && <>
              <div variant="outlined"
                style={{ minHeight: 'inherit', }}> 
                {/*border: '1px solid rgba(0, 0, 0, 0.12)', borderRadius: "20px", backgroundColor: '#fff',  */}
                <Healthcare user={user} wsData={wsData} setWsData={setWsData} isMobilePPI={isMobilePPI}/>
              </div>
              </>
            }
            { !isMobilePPI && <>
              <Healthcare user={user}  wsData={wsData} setWsData={setWsData} isMobilePPI={isMobilePPI}
                hideCalendar={hideCalendar} setHideCalendar={setHideCalendar}/>
              </>
            }
          </Grid>
          {!hideCalendar && <Grid item style={{ width: isMobilePPI||fullScreenArrange? '100%': '33%', marginBottom: '25px',
            minHeight: isMobilePPI? windowHeightMobile+'px': fullScreenArrange? '': windowHeightWeb+'px',
            }}>
            <Card variant="outlined"
              style={{ minHeight: 'calc(100%)', backgroundColor: '#fff', 
              border: '0px solid rgba(0, 0, 0, 0.12)', 
              boxShadow: 'rgba(0,0,0,0.08) 0 8px 16px 0',
              borderRadius: "20px", 
              height: isMobilePPI||fullScreenArrange? '':  windowHeightWeb+'px', 
              overflow:'auto'
              }}>
              <Calendar wsData={wsData} setWsData={setWsData} fullScreenArrange={fullScreenArrange} isMobilePPI={isMobilePPI}/>
            </Card>
          </Grid>}
        </Grid>
      </>
    </>
  );
};

export default Dashboard;
