import mainStyle from './footer.module.scss';

import { useEffect, useState } from "react";
// import { useRoute } from '@react-navigation/native';
import { useNavigate, useLocation, redirect } from 'react-router-dom';
import * as Setting from  "../../pages/Login/Setting"
import Box from '@mui/material/Box';
import List from '@mui/material/List';

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

// page utils
const Footer = () => {

  return (
    <>
      <Grid container alignItems="center" justifyContent="space-evenly" sx={{p: 1}} >
        <div style={{color: 'rgb(109, 114, 140)', lineHeight: '35px'}} >© 2024 D-link Corporation. All rights reserved.</div>
      </Grid>
    </>
  );
};

export default Footer;