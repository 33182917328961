import React from 'react';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import FormControlLabel from '@mui/material/FormControlLabel';
import FieldContainer from "./FieldContainer";

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { ReactComponent as InputClearBtn } from 'assets/img/icon/btn_input_clear.svg';

export const DateObj = ({ id, onChange }) => (
  <LocalizationProvider dateAdapter={AdapterDayjs} id={'1232222'}>
    <DatePicker 
      id={'12322'}
      sx={{
        '& .MuiOutlinedInput-root': {
          height: '40px', borderRadius: '10px',
          '& fieldset': { borderColor: '#EEF0F2', }
        },
      }}
      onChange={onChange}
    />
    {/* <DemoItem label={<Label componentName="DatePicker" valueType="date" />}>
      <DatePicker />
    </DemoItem> */}
  </LocalizationProvider>
);

const DateField = ({ label, id, onChange}) => (
  <FieldContainer label={label}>
    <Box sx={{ minWidth: 180 }} noValidate autoComplete="off">
      <FormControl fullWidth>
        <DateObj
          id={id}
          onChange={onChange}
        />
      </FormControl>
    </Box>
  </FieldContainer>
);

export default DateField;