import './modal-container.component.scss';

import { memo, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button';
import IconButton from "@mui/material/IconButton";

import close from "assets/img/icon/icn_nav_close.svg";
const newOpenTheme = { position: "absolute", top: 8, right: 12 }
const btnStyle = {
  textTransform: 'none',
  backgroundColor: '#0A87A9',
  width: '150px',
  borderRadius: '10px',
  color: '#FFFFFF',
  lineHeight: 1.25,
  fontSize: '16px',
  fontWeight: 600,
  margin: '10px 0px 5px 0px',
  padding: '10px 20px',
  '&:hover': {
    backgroundColor: '#05647E',
  }
}

const ModalforCheck = (props) => {
  const {
    title, content, checkBtn,
    setOpenModal, navigatePage,
  } = props;

  const handleClose = () => {
    setOpenModal(false);
  };

  const navigate = useNavigate();
  const goPage = () => {
    setOpenModal(false);
    if(navigatePage){
      let path = "/" + navigatePage;
      navigate(path);
    }
  };

  return (
    <>
      <div className={`custom-modal-check`}>
        {title &&
          <Typography color="#0087a9" fontWeight="600" fontSize="24px">
            {title}
          </Typography>
        }
        <IconButton style={newOpenTheme} onClick={handleClose}>
          <img src={close} alt="openInNew" width={"25px"} />
        </IconButton>
      </div>
      <div className={`custom-modal-check`}>
        {content && 
          <Typography fontSize="20px" color="rgba(0, 0, 0, 0.8)">
            {content}
          </Typography>
        }
      </div>
      <div className={`custom-modal-check`}>
        {/* {cancelBtn && <Button sx={btnStyle} variant="contained" onClick={handleClose}>{cancelBtn}</Button>} */}
        {/* {saveBtn && <Button sx={btnStyle} variant="contained" onClick={handleClose}>{saveBtn}</Button>} */}
        {checkBtn && <Button sx={btnStyle} variant="contained" onClick={goPage}>{checkBtn}</Button>}
      </div>
    </>
  );
}

export default memo(ModalforCheck);