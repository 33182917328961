import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";
import { useWebSocket } from "hook/WebSocketContext";
import { useAuth } from "../../hook/AuthContext";

import { PATH } from "router/config/const";

import _, { cloneDeep } from 'lodash';
import * as Yup from 'yup';
import { useFormik, FormikProvider } from 'formik';
import dayjs from 'dayjs';

import FullCalendar from '@fullcalendar/react';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import timelinePlugin from '@fullcalendar/timeline';

import { useTheme, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import Tooltip from '@mui/material/Tooltip';

import DeleteFilled from '@ant-design/icons/DeleteFilled';
import PlusOutlined from '@ant-design/icons/PlusOutlined';

import Spinner from 'components/Spinner';
import Breadcrumb from 'components/Breadcrumb';
import AvatarGroup from 'components/AvatarGroup';
import ModalContainer from 'components/ModalContainer';
import ModalforForm from './components/ModalforForm';

import CalendarToolbar from './CalendarToolbar';
import CalendarModalContent from './CalendarModalContent';

import { FAKE_EVENTS_DATA, CALENDAR_EVENT_COLORS } from './const';

import { toUnixTimestamp, unixToISO8601, toAmPmTime } from '../../utils/utils';

const CalendarStyled = styled(Box)(({ theme, calendarView }) => ({
  width: 'calc(100% + 2px)',
  marginLeft: -1,


  // hide license message
  '& .fc-license-message': {
    display: 'none'
  },
  '& .fc .fc-daygrid .fc-scroller-liquid-absolute': {
    overflow: 'hidden !important'
  },

  '& .fc-col-header ': {
    width: '100% !important',
  },

  '& .fc .fc-daygrid-body ': {
    width: '100% !important'
  },

  '& .fc-scrollgrid-sync-table': {
    width: '100% !important'
  },

  '& .fc .fc-timegrid-axis-frame': {
    justifyContent: 'center'
  },

  '& .fc-scrollgrid-sync-inner': {
    width: '100%',
    color: '#000000',
    fontSize: '14px',
    lineHeight: 1.43,
    textAlign: 'center',
  },

  // basic style
  '& .fc': {
    '--fc-bg-event-opacity': 1,
    '--fc-border-color': '#f0f0f0',
    '--fc-daygrid-event-dot-width': '10px',
    '--fc-today-bg-color': '#cfe6f1',
    '--fc-list-event-dot-width': '10px',
    '--fc-event-border-color': 'none',
    '--fc-event-bg-color': 'none',
    '--fc-now-indicator-color': '#ff4d4f',
    '--fc-highlight-color': '#f9fafb',
    color: '#262626',
    background: '#ffffff',
    fontFamily: theme.typography.fontFamily,
  },

  '& .fc td': {
    'verticalAlign': 'inherit'
  },

  // date text
  '& .fc .fc-daygrid-day-top': {
    display: 'grid',
    '& .fc-daygrid-day-number': {
      textAlign: 'center',
      color: '#737791',
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: 1.43
    }
  },

  // today circle
  '& .fc .fc-day-today .fc-daygrid-day-number': {
    color: '#ffffff',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: 1.43,
    backgroundColor: '#0087a9',
    borderRadius: '50%',
    width: '24px',
    height: '24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '6px auto',
  },

  // weekday
  '& .fc .fc-col-header-cell': {
    background: '#f5f5f5',
    position: 'relative',
    height: calendarView === 'timeGridWeek' || calendarView === 'timeGridDay' ? '60px' : '40px',
  },

  '& .fc .fc-col-header-cell-cushion': {
    color: '#737791',
    fontSize: '14px',
    lineHeight: 1.43,
    padding: 10
  },

  '& .fc .fc-timegrid-slot-label-cushion': {
    color: '#000000',
    fontSize: '14px',
    lineHeight: 1.43,
    textAlign: 'center',
  },

  // events
  // '& .fc-direction-ltr .fc-daygrid-event.fc-event-end, .fc-direction-rtl .fc-daygrid-event.fc-event-start': {
  //   marginLeft: 4,
  //   marginBottom: 6,
  //   borderRadius: 4,
  //   background: '#1677ff',
  //   border: 'none'
  // },

  // '& .fc-direction-ltr .fc-daygrid-more-link': {
  //   color: '#424962',
  //   fontSize: '12px',
  //   lineHeight: 1.67
  // },

  // '& .fc-h-event .fc-event-main': {
  //   padding: 4,
  //   paddingLeft: 8
  // },

  // popover when multiple events
  '& .fc .fc-more-popover': {
    border: 'none',
    borderRadius: 6,
    zIndex: 1200
  },

  '& .fc .fc-more-popover .fc-popover-body': {
    background: '#f5f5f5',
    borderBottomLeftRadius: 4,
    borderBottomRightRadius: 4
  },

  '& .fc .fc-popover-header': {
    padding: 12,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    background: '#d9d9d9',
    color: '#262626'
  },

  // agenda view
  '& .fc-theme-standard .fc-list-day-cushion': {
    background: '#eef0f2',
    height: '60px',
    display: 'flex',
    alignItems: 'center',
  },

  '& .fc .fc-list-table td': {
    borderBottom: '1px solid #eef0f2'
  },

  '& .fc-direction-ltr .fc-list-table .fc-list-event-graphic': {
    borderLeft: '1px solid #eef0f2'
  },

  '& .fc .fc-day': {
    cursor: 'pointer'
  },

  '& .fc .fc-timeGridDay-view .fc-timegrid-slot': {
    background: '#ffffff'
  },

  '& .fc .fc-timegrid-slot': {
    cursor: 'pointer',
    height: '30px',
  },

  '& .fc .fc-list-event-time': {
    height: '60px',
    color: '#424962',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: 1.43,
  },

  '& .fc .fc-list-event:hover td': {
    cursor: 'pointer',
    background: '#f5f5f5'
  },

  '& .fc-timegrid-event-harness-inset .fc-timegrid-event, .fc-timegrid-event.fc-event-mirror, .fc-timegrid-more-link': {
    boxShadow: 'none',
  },
}));

const Calendar = () => {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'))

  const [wsData, setWsData] = useOutletContext();
  const ws = useWebSocket();
  const { token } = useAuth();

  const homeGroupId = useSelector((state) => state.user.userInfo).lastUsedHomegroupId;

  const [openSpinner, setOpenSpinner] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [calendarView, setCalendarView] = useState();
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState();
  const [date, setDate] = useState(new Date());
  const [currentCalendarViewDateRange, setCurrentCalendarViewDateRange] = useState([]);
  const [selectedRange, setSelectedRange] = useState(null);
  const calendarRef = useRef(null);

  const AVATARS = useRef([]);

  const EventSchema = Yup.object().shape({
    title: Yup.string().max(255).required('Title is required'),
    description: Yup.string().max(5000),
    end: Yup.date().when('start', (start, schema) => start && schema.min(start, 'End date must be later than start date')),
    start: Yup.date(),
    color: Yup.string().max(255),
    textColor: Yup.string().max(255)
  });

  const formik = useFormik({
    validationSchema: EventSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const newEvent = {
          title: values.title,
          description: values.description,
          color: values.color,
          textColor: values.textColor,
          allDay: values.allDay,
          start: values.start,
          end: values.end
        };

        if (selectedEvent) {
          setCalendarEvents(calendarEvents.map(event => event === selectedEvent ? newEvent : event));
        } else {
          setCalendarEvents([...calendarEvents, newEvent]);
        }

        setSubmitting(false);
        setModalOpen(false);
      } catch (error) {
        console.error(error);
      }
    }
  });

  function getInitialValues(event, range) {
    const newEvent = {
      title: '',
      description: '',
      color: '#1890ff',
      textColor: '#fff',
      allDay: false,
      start: range ? new Date(range.start) : new Date(),
      end: range ? new Date(range.end) : new Date()
    };

    if (event || range) {
      return _.merge({}, newEvent, event);
    }

    return newEvent;
  };

  function getAvatarGroupPictures(data) {
    return data.reduce((acc, item) => {
      acc[item.name] = item.portrait;
      return acc;
    }, {});
  }

  function handleChangeDateRange(calendarApi) {
    const calendarView = calendarApi.view;
    const startOfView = calendarView.activeStart;
    const endOfView = calendarView.activeEnd;

    setCurrentCalendarViewDateRange([startOfView, endOfView]);
  }

  function handleDateToday() {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.today();

      setDate(calendarApi.getDate());

      handleChangeDateRange(calendarApi)
    }
  };

  function handleViewChange(newView) {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.changeView(newView);
      setCalendarView(newView);

      handleChangeDateRange(calendarApi)
    }
  };

  function handleDatePrev() {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.prev();
      setDate(calendarApi.getDate());

      handleChangeDateRange(calendarApi)
    }
  };

  function handleDateNext() {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.next();
      setDate(calendarApi.getDate());

      handleChangeDateRange(calendarApi)
    }
  };

  function handleRangeSelect(arg) {
    const calendarEl = calendarRef.current;
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      calendarApi.unselect();
    }

    setSelectedRange({ start: arg.start, end: arg.end });
    setModalOpen(true);
  };

  function handleEventSelect(arg) {
    if (arg?.event?.id) {
      const event = calendarEvents.find((event) => event.id === arg.event.id);
      setSelectedEvent(event);
    }
    setModalOpen(true);
  };

  function handleEventUpdate({ event, revert }) {
    const dropEvent = event.extendedProps
    if (dropEvent.type === 'takeBloodPressure' || dropEvent.type === 'takeMedicine') {
      revert();
      return
    }
    // setCalendarEvents(calendarEvents.map(event =>
    //   event.id === dropEvent.id
    //     ? { ...event, allDay: dropEvent.allDay, start: dropEvent.start, end: dropEvent.end }
    //     : event
    // ));
  };

  function handleModal() {
    if (isModalOpen) {
      setSelectedEvent(null);
    }
    setModalOpen(!isModalOpen);
  };

  function handleDelete() {
    setCalendarEvents(calendarEvents.filter(event => event.id !== selectedEvent.id));
    setSelectedEvent(null);
    setModalOpen(false);
  };

  useEffect(() => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      handleChangeDateRange(calendarApi)
    }
  }, [])

  useEffect(() => {
    if (homeGroupId) {
      const requestHomegroupsElder = {
        resource: `/homegroups/${homeGroupId}/members/Elder`,
        verb: 'read',
        accessToken: token,
      };

      ws.send(JSON.stringify(requestHomegroupsElder));
    }
  }, [homeGroupId]);

  useEffect(() => {
    if (homeGroupId && currentCalendarViewDateRange?.length === 2) {
      setOpenSpinner(true)
      const [startTime, endTime] = toUnixTimestamp(currentCalendarViewDateRange);

      const requestCalendarCron = {
        resource: "/users/0/crons/calendar",
        verb: "read",
        data: {
          filter: {
            homeGroupId,
            startTime,
            endTime
          },
          projection: ["dailyCrons"]
        },
        accessToken: token,
      };

      ws.send(JSON.stringify(requestCalendarCron));
    }
  }, [homeGroupId, currentCalendarViewDateRange]);

  useEffect(() => {
    // 拿頭像圖片
    if (wsData.updateTag === "homegroups_read" && wsData.updateData) {
      const allElderData = cloneDeep(wsData);
      const readMembersData = allElderData.updateData.members;
      AVATARS.current = getAvatarGroupPictures(readMembersData);
    }
    // 拿日曆所有事件
    if (wsData.updateTag === "read_crons_calendar" && wsData.updateData && AVATARS.current) {
      const allCronData = cloneDeep(wsData);
      const readData = allCronData.updateData.dailyCrons;
      const formatReadData = Object.values(
        readData.reduce((acc, data) => {
          data.crons.forEach(({ id, name: title, type, items, startTime, endTime }) => {
            // 判斷是否為自定義事件 actions
            const typeCondition = type === 'actions'
            const event = {
              id,
              title,
              description: typeCondition ? items.description : "",
              type: typeCondition ? items.type : type,
              eventColors: CALENDAR_EVENT_COLORS[typeCondition ? items.type : type],
              allDay: typeCondition ? items.allDay : false,
              start: startTime,
              end: endTime,
              elder: typeCondition ? items.elderly : items.elder,
            };
            // 在 acc 裡面沒有該 id 的情況下,將 event 加入 acc
            if (!acc[id]) {
              acc[id] = {
                ...event,
                start: unixToISO8601(startTime),
                end: unixToISO8601(endTime),
              };
            } else {
              // 在 acc 裡面有該 id 的情況下, 更新 event 的 start 與 end
              acc[id].start = unixToISO8601(Math.min(toUnixTimestamp(acc[id].start), startTime));
              acc[id].end = unixToISO8601(Math.max(toUnixTimestamp(acc[id].end), endTime));
            }
          });
          return acc;
        }, {})
      )

      console.log('readData :>> ', readData);

      console.log('formatReadData :>> ', formatReadData);

      setCalendarEvents(formatReadData);
      setOpenSpinner(false)
    }
  }, [wsData])

  useEffect(() => {
    const calendarEl = calendarRef.current;

    if (calendarEl) {
      const calendarApi = calendarEl.getApi();
      const newView = matchDownSM ? 'listWeek' : 'dayGridMonth';
      calendarApi.changeView(newView);
      setCalendarView(newView);
    }
  }, [matchDownSM]);

  useEffect(() => {
    formik.setValues(getInitialValues(selectedEvent, selectedRange));
  }, [selectedEvent, selectedRange]);

  return (
    <>
      <Spinner open={openSpinner} />
      <Breadcrumb path={PATH.calendar}></Breadcrumb>
      <CalendarStyled calendarView={calendarView}>
        <CalendarToolbar
          date={date}
          view={calendarView}
          onClickNext={handleDateNext}
          onClickPrev={handleDatePrev}
          onClickToday={handleDateToday}
          onChangeView={handleViewChange}
        />

        <FullCalendar
          weekends
          editable
          droppable
          selectable
          events={calendarEvents}
          ref={calendarRef}
          rerenderDelay={10}
          initialDate={date}
          initialView={calendarView}
          dayMaxEventRows={2}
          eventDisplay="block"
          headerToolbar={false}
          allDayMaintainDuration
          eventResizableFromStart
          select={handleRangeSelect}
          eventDrop={handleEventUpdate}
          eventClick={handleEventSelect}
          eventResize={handleEventUpdate}
          height={matchDownSM ? 'auto' : 720}
          plugins={[listPlugin, dayGridPlugin, timelinePlugin, timeGridPlugin, interactionPlugin]}
          dayHeaderContent={({ date }) => {
            const dayNames = ["週日", "週一", "週二", "週三", "週四", "週五", "週六"];
            const listWeekDayNames = ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六", "星期日"];

            const dayOfMonth = date.getDate();
            const dayName = dayNames[date.getUTCDay()];
            const listWeekDayName = listWeekDayNames[date.getUTCDay()];

            const today = dayjs().date();
            const formattedListWeekDate = `${date.getFullYear()} 年 ${date.getMonth() + 1} 月 ${date.getDate()} 日`;

            if (calendarView === 'timeGridWeek' || calendarView === 'timeGridDay') {
              const todayCircleStyle = {
                backgroundColor: '#0087a9',
                borderRadius: '50%',
                width: '20px',
                height: '20px',
              }
              return (
                <Box sx={{
                  position: 'absolute', width: '100%', height: '100%', top: '0', left: '0',
                  display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingTop: '1px',
                }}>
                  <Box
                    style={today === dayOfMonth ? todayCircleStyle : {}}
                    sx={{
                      color: today === dayOfMonth ? '#ffffff' : '#737791',
                      fontSize: '14px',
                      fontWeight: 600,
                      lineHeight: 1.43,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginBottom: '4px'
                    }}
                  >
                    {dayOfMonth}
                  </Box>
                  <Box sx={{ color: '#737791', fontSize: '14px', lineHeight: 1.43 }}>
                    {dayName}
                  </Box>
                </Box>
              );
            } if (calendarView === 'listWeek') {
              return (
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
                  <Box sx={{ color: '#737791', fontSize: '14px', fontWeight: 600, lineHeight: 1.43 }}>
                    {listWeekDayName}
                  </Box>
                  <Box sx={{ color: '#737791', fontSize: '14px', fontWeight: 600, lineHeight: 1.43 }}>
                    {formattedListWeekDate}
                  </Box>
                </Box>
              );
            } else {
              return <Box>{dayName}</Box>;
            }
          }}
          slotLabelFormat={(date) => {
            const hours = date.date.hour;
            const period = hours < 12 ? '上午' : '下午';
            const formattedHours = hours % 12 || 12;

            return `${period} ${formattedHours} 點`;
          }}
          allDayContent="整天"
          eventContent={({ event }) => {
            const eventInfo = event.extendedProps;

            return (
              <Box
                sx={{
                  backgroundColor: calendarView === 'listWeek' ? 'unset' : eventInfo.eventColors[1],
                  borderLeft: calendarView === 'listWeek' ? 'unset' : `8px solid ${eventInfo.eventColors[0]}`,
                  height: calendarView === 'timeGridWeek' || calendarView === 'timeGridDay' && event.allDay ? '60px' : '36px',
                  margin: calendarView === 'dayGridMonth' ? '5px' : 0,
                  padding: 0,
                  borderRadius: '5px',
                  color: '#424962',
                  fontSize: calendarView === 'listWeek' ? "14px" : '12px',
                  fontWeight: 600,
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                {
                  calendarView === 'listWeek' && <Box sx={{ width: '10px', height: '10px', backgroundColor: eventInfo.eventColors[0], borderRadius: '50%' }} />
                }
                <AvatarGroup avatarContainerMargin="0 -2px 0 8px" avatarWidthAndHeight={calendarView === 'listWeek' ? "26px" : "24px"}
                  data={eventInfo.elder} condition={AVATARS.current} length={2} />
                {
                  event.startStr && calendarView !== 'listWeek' &&
                  <Box component="span">
                    {event.allDay ? "整天" : toAmPmTime(event.start)}
                  </Box>
                }
                {event.title}
              </Box>
            );
          }}
          dayPopoverFormat={{
            month: 'numeric',
            day: 'numeric',
          }}
          eventTimeFormat={{
            hour: '2-digit',
            minute: '2-digit',
            hour12: false,
          }}
        />
      </CalendarStyled>

      {/* <ModalContainer
        display={isModalOpen}
        modalWidth="750px"
        children={
          <FormikProvider value={formik}>
            <ModalforForm
              title={selectedEvent ? 'Edit Event' : 'Add Event'}
              content={
                <CalendarModalContent formik={formik} />
              }
              setOpenModal={isModalOpen}
              iconOnClick={handleModal}
              actionButtons={[
                selectedEvent && { 'icon': <DeleteFilled />, 'onClick': handleDelete },
                { 'text': 'Cancel', 'onClick': handleModal },
                { 'text': 'Add', 'onClick': null }
              ]}
            />
          </FormikProvider>
        }
      /> */}

      <Tooltip title="Add New Event">
        <SpeedDial
          ariaLabel="add-event-fab"
          sx={{
            display: 'inline-flex', position: 'sticky', bottom: 80, left: '100%', transform: 'translate(-50%, -50% )',
            '& .MuiFab-primary': {
              width: '56px',
              height: '56px',
              backgroundColor: '#0087a9',
              color: '#fff',
              '&:hover': {
                backgroundColor: '#006b85',
              },
            },
          }}
          icon={<PlusOutlined style={{ fontSize: '1.3rem' }} />}
          onClick={handleModal}
        />
      </Tooltip>
    </>
  );
};

export default Calendar;