import React from 'react';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import FieldContainer from "./FieldContainer";
// import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import { styled } from '@mui/system';
import { ReactComponent as InputClearBtn } from 'assets/img/icon/btn_input_clear.svg';

const Textarea = styled(TextareaAutosize)(
  ({ theme }) => `
    padding: 8px 12px;
    border-radius: 10px;
    font: inherit;
    border: 1px solid #EEF0F2;
    resize: none;`,
);

export const TextareaObj = ({ id, placeholder, onChange }) => (
  <Textarea
    id={id}
    maxRows={6}
    placeholder={placeholder}
    onChange={onChange}
  />
);

const AreaField = ({ label, id, placeholder, type, error, onChange}) => (
  <FieldContainer label={label}>
    <Box sx={{ minWidth: 180 }} noValidate autoComplete="off">
      <FormControl fullWidth>
        <TextareaObj
          id={id}
          placeholder={placeholder}
          onChange={onChange}
        />
      </FormControl>
    </Box>
  </FieldContainer>
);

export default AreaField;