import AppstoreOutlined from '@ant-design/icons/AppstoreOutlined';
import LayoutOutlined from '@ant-design/icons/LayoutOutlined';
import PicCenterOutlined from '@ant-design/icons/PicCenterOutlined';
import OrderedListOutlined from '@ant-design/icons/OrderedListOutlined';

export const CALENDAR_VIEW_OPTIONS = [
    {
      label: '月',
      value: 'dayGridMonth',
      icon: AppstoreOutlined
    },
    {
      label: '週',
      value: 'timeGridWeek',
      icon: LayoutOutlined
    },
    {
      label: '日',
      value: 'timeGridDay',
      icon: PicCenterOutlined
    },
    {
      label: '議程',
      value: 'listWeek',
      icon: OrderedListOutlined
    }
  ];

export const FAKE_EVENTS_DATA =  [
  {
      "id": "5e8882f1f0c9216397e05a9b",
      "allDay": false,
      "color": "#f5222d",
      "description": "SCRUM Planning",
      "start": "2024-10-09T19:48:14.904Z",
      "end": "2024-10-09T22:03:14.904Z",
      "title": "Repeating Event"
  },
  {
      "id": "5e8882fcd525e076b3c1542c",
      "allDay": true,
      "color": "#faad14",
      "description": "Sorry, John!",
      "start": "2024-10-14T00:48:14.904Z",
      "end": "2024-10-14T01:03:14.904Z",
      "title": "Conference"
  },
  {
      "id": "5e8882e440f6322fa399eeb8",
      "allDay": true,
      "color": "#52c41a",
      "description": "Inform about new contract",
      "start": "2024-10-19T01:33:14.904Z",
      "end": "2024-10-18T01:33:14.904Z",
      "title": "All Day Event"
  },
  {
      "id": "5e8882fcd525e076b3c1542d",
      "allDay": false,
      "color": "#f6ffed",
      "textColor": "#52c41a",
      "description": "Sorry, Stebin Ben!",
      "start": "2024-10-19T20:33:14.904Z",
      "end": "2024-10-20T00:03:14.904Z",
      "title": "Opening Ceremony"
  },
  {
      "id": "5e8882eb5f8ec686220ff138",
      "allDay": true,
      "color": "#8c8c8c",
      "description": "Discuss about new partnership",
      "start": "2024-10-18T01:33:14.904Z",
      "end": "2024-10-20T00:33:14.904Z",
      "title": "Long Event"
  },
  {
      "id": "5e88830672d089c53c46ece3",
      "allDay": false,
      "description": "Get a new quote for the payment processor",
      "start": "2024-10-22T06:30:14.904Z",
      "end": "2024-10-22T08:30:14.904Z",
      "title": "Breakfast"
  },
  {
      "id": "5e888302e62149e4b49aa609",
      "allDay": false,
      "color": "#fffbe6",
      "textColor": "#faad14",
      "description": "Discuss about the new project",
      "start": "2024-10-22T09:45:14.904Z",
      "end": "2024-10-22T15:30:14.904Z",
      "title": "Meeting"
  },
  {
      "id": "5e888302e62149e4b49aa709",
      "allDay": false,
      "color": "#f5222d",
      "description": "Let's Go",
      "start": "2024-10-22T09:00:14.904Z",
      "end": "2024-10-22T11:30:14.904Z",
      "title": "Anniversary Celebration"
  },
  {
      "id": "5e888302e69651e4b49aa609",
      "allDay": false,
      "description": "Discuss about the new project",
      "start": "2024-10-23T06:58:14.904Z",
      "end": "2024-10-23T07:28:14.904Z",
      "title": "Send Gift"
  },
  {
      "id": "5e8883062k8149e4b49aa709",
      "allDay": false,
      "color": "#faad14",
      "description": "Let's Go",
      "start": "2024-10-23T05:18:14.904Z",
      "end": "2024-10-23T06:48:14.904Z",
      "title": "Birthday Party"
  },
  {
      "id": "5e8882f1f0c9216396e05a9b",
      "allDay": false,
      "color": "#8c8c8c",
      "description": "SCRUM Planning",
      "start": "2024-10-23T05:03:14.904Z",
      "end": "2024-10-23T06:03:14.904Z",
      "title": "Repeating Event"
  },
  {
      "id": "5e888302e62149e4b49aa610",
      "allDay": false,
      "color": "#f5222d",
      "description": "Let's Go",
      "start": "2024-10-23T05:18:14.904Z",
      "end": "2024-10-23T06:23:14.904Z",
      "title": "Dinner"
  },
  {
      "id": "5e8882eb5f8ec686220ff131",
      "allDay": true,
      "description": "Discuss about new partnership",
      "start": "2024-10-27T01:33:14.904Z",
      "end": "2024-10-30T02:33:14.904Z",
      "title": "Long Event"
  },
  {
      "id": "5e888302e62349e4b49aa609",
      "allDay": false,
      "color": "#1890ff",
      "textColor": "#e6f7ff",
      "description": "Discuss about the project launch",
      "start": "2024-10-28T01:48:14.904Z",
      "end": "2024-10-28T01:53:14.904Z",
      "title": "Meeting"
  },
  {
      "id": "5e888302e62149e4b49ab609",
      "allDay": false,
      "color": "#52c41a",
      "description": "Discuss about the tour",
      "start": "2024-11-03T05:18:14.904Z",
      "end": "2024-11-03T06:23:14.905Z",
      "title": "Happy Hour"
  }
]

export const CALENDAR_EVENT_COLORS = {
    ['outside']: ['#7ebbd6', '#e9f4fa'],
    ['hospital']: ['#55ccdd', '#e6fffb'],
    ['other']: ['#737791', '#efefef'],
    ['takeBloodPressure']: ['#ff947a', '#fff7e6'],
    ['takeMedicine']: ['#d3adf7', '#f9f0ff'],
}