import Sdk from "casdoor-js-sdk";
import * as Conf from "./Conf";

export const ServerUrl = process.env.REACT_APP_BACKEND_URL; //backend URL

export const CasdoorSdk = new Sdk(Conf.sdkConfig);

export const isLoggedIn = () => {
  const token = localStorage.getItem("token");
  return token !== null && token.length > 0;
};

export const setSettingToken = (token) => {
  localStorage.setItem("token", token);
};

export const getRefreshToken = (refreshToken) => {
  localStorage.setItem("refreshToken", refreshToken);
};

export const goToLink = (link) => {
  window.location.href = link;
};

export const getSigninUrl = () => {
  return CasdoorSdk.getSigninUrl();
};

export const getUserinfo = () => {
  return fetch(`${ServerUrl}/api/userinfo`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  }).then((res) => res.json());
};

export const getUsers = () => {
  return fetch(`${Conf.sdkConfig.serverUrl}/api/get-users?owner=${Conf.sdkConfig.organizationName}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  }).then((res) => res.json());
};

export const logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("expiresTime");
};

export const showMessage = (message) => {
  alert(message);
};
