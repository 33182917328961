export const iconTitleTheme = {
  marginTop: '45px',
  marginBottom: '15px'
}

export const iconTheme = {
  marginTop: '15px'
}

export const iconThemeSelected = {
  backgroundColor:'#0A87A9',
  height: '33px',
  width: '33px',
  borderRadius: '20%',
  padding: '9px 0px 0px 9px',
  marginTop: '10px'
}