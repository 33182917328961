import mainStyle from './device.module.scss';
import Box from '@mui/material/Box';
import { PATH } from "router/config/const";
import Breadcrumb from 'components/Breadcrumb';

const Devices = () => {

  return (
    <>
      <Breadcrumb path={PATH.devices}></Breadcrumb>
      <Box sx={{ display: 'flex', width: '100%'}}>Devices content</Box>
    </>
    
  );
};

export default Devices;