import mainStyle from "./login.module.scss";
import React, { useState, useEffect } from "react";
import * as Setting from "./Setting";

const Login = () => {
  const casdoorLogin = () => {
    Setting.goToLink(Setting.getSigninUrl());
  };

  useEffect(() => {
    casdoorLogin();
  }, []);

  /* return (
    <div
      style={{
        textAlign: "center",
        alignItems: "center",
      }}
    >
      Redirecting to Casdoor login...
    </div>
  ); */
};

export default Login;
