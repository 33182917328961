export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const DAYS_NAMES = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const TIMES_LIST = [
  "0:00 am", "0:30 am",
  "1:00 am", "1:30 am",
  "2:00 am", "2:30 am",
  "3:00 am", "3:30am",
  "4:00 am", "4:30 am",
  "5:00 am", "5:30 am",
  "6:00 am", "6:30 am",
  "7:00 am", "7:30 am",
  "8:00 am", "8:30 am",
  "9:00 am", "9:30 am",
  "10:00 am", "10:30 am",
  "11:00 am", "11:30 am",
  "12:00 pm", "12:30 pm",
  "1:00 pm", "1:30 pm",
  "2:00 pm", "2:30 pm",
  "3:00 pm", "3:30 pm",
  "4:00 pm", "4:30 pm",
  "5:00 pm", "5:30 pm",
  "6:00 pm", "6:30 pm",
  "7:00 pm", "7:30 pm",
  "8:00 pm", "8:30 pm",
  "9:00 pm", "9:30 pm",
  "10:00 pm", "10:30 pm",
  "11:00 pm", "11:30 pm",
];

export const TIMING = {
  ['AC']: "Before meal",
  ['PC']: "After meal",
  ['F']: "With meal",
  ['HS']: "Before sleep",
  ['PRN']: "When needed"
}