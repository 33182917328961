import React, { useState } from 'react';
import mainStyle from './group.module.scss';

import { PATH } from "router/config/const";
import { useOutletContext } from "react-router-dom";

import { cloneDeep } from "lodash";

import { useWebSocket } from "hook/WebSocketContext";
import { useAuth } from "hook/AuthContext";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import Breadcrumb from 'components/Breadcrumb';
import StepField from './components/StepField';

import { INIT_CREATE_HOME_GROUP_FIELDS_VALID, CHECK_CREATE_HOME_GROUP_FIELDS } from './const';

import CreateHomeGroup from './CreateHomeGroup';
import InviteUser from './InviteUser';

const AddGroup = () => {
  const [wsData, setWsData] = useOutletContext();
  const ws = useWebSocket();
  const { token } = useAuth();

  const [currentState, setCurrentState] = useState(0);

  const [isCreateHomeGroupFieldValid, setIsCreateHomeGroupFieldValid] = useState([INIT_CREATE_HOME_GROUP_FIELDS_VALID])

  function checkIfFieldIsRequired(data) {
    let hasError = false;
    let newValidateField = cloneDeep(isCreateHomeGroupFieldValid);

    CHECK_CREATE_HOME_GROUP_FIELDS.forEach(checkField => {
      if (Array.isArray(data[checkField.field]) ? !(data[checkField.field].join('').length === 4) : data[checkField.field] === '' || Number.isNaN(data[checkField.field])) {
        hasError = true;
        newValidateField[checkField.field] = checkField.message;
      } else {
        newValidateField[checkField.field] = "";
      }
    });

    setIsCreateHomeGroupFieldValid(newValidateField);

    return hasError;
  }

  function handleNext(data) {
    const isValid = checkIfFieldIsRequired(data);

    let formatData = cloneDeep(data);
    formatData.pageCode = formatData.pageCode.join('');

    const requestCreateHomeGroup = {
      resource: "/homegroups",
      verb: "create",
      accessToken: token,
      data: formatData  
    };

    if (!isValid) {
      ws.send(JSON.stringify(requestCreateHomeGroup));
      setCurrentState(currentState + 1);
    }
  }

  return (
    <>
      <Breadcrumb path={PATH.add_group}></Breadcrumb>
      <Card>
        <CardContent>
          <StepField
            stepData={["Create Home Group", "Invite User"]}
            currentState={currentState}
          />
          {
            currentState === 0
              ? <CreateHomeGroup
                setCurrentState={setCurrentState}
                isValidateField={isCreateHomeGroupFieldValid}
                setIsValidateField={setIsCreateHomeGroupFieldValid}
                handleNext={handleNext}
              />
              : currentState === 1 && <InviteUser />
          }
        </CardContent>
      </Card>
    </>
  );
};

export default AddGroup;