import React, { useState, useEffect } from "react";
import mainStyle from '../../dashboard.module.scss';
import recipientStyle from '../recipient.module.scss';
import { cloneDeep, isNil } from 'lodash';
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import Grid from "@mui/material/Grid";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { Chart as ChartJS } from "chart.js/auto";
import { Chart } from 'react-chartjs-2';

import { defaultChartData, defaultChartOptions, defaultChartPlugin } from "../const";
import controller from '../controller';
import { ChartSummaryData } from "../simuData";

dayjs.extend(utc);
dayjs.extend(timezone);

const localStorageId = 'DASHBOARD_CHART';

const ChartSummary = (props) => {
  const {
    wsData, setWsData
  } = props;

  const [chartData, setChartData] = useState(cloneDeep(defaultChartData));
  const [chartOptions, setChartOptions] = useState(cloneDeep(defaultChartOptions));
  const [chartPlugin, setChartPlugin] = useState(cloneDeep(defaultChartPlugin));

  const [timeRange, setTimeRange] = useState(7);

  function initChart(range) {
    let result = [];
    let now = controller.getDateYMD(dayjs());
    let dataObj = {
      id: 0,
      year: dayjs(now).format('YYYY'),
      month: dayjs(now).format('MM'),
      hour: '0', //dayjs(now).format('HH'),
      SBP: null,
      DBP: null,
      PULSE: null,
      // numberOfDay: 0,
      // numberOfMonth: 0
    };

    if(range === 365){
      dataObj.day = '01'
      let firstDayOfMonth = dataObj.year+'-'+dataObj.month+'-01';
      dataObj.week = dayjs(firstDayOfMonth).format('ddd')
      dataObj.dateStamp = parseInt(dayjs(firstDayOfMonth).format('X'))
      dataObj.label = dayjs(firstDayOfMonth).format('MMM')
      result.push(dataObj)

      for (let i = 1; i < 12; i++) {
        let prevObj = cloneDeep(result[0]);
        let preTime = prevObj.dateStamp-1;
        prevObj.id = i;
        prevObj.year = dayjs(preTime*1000).format('YYYY');
        prevObj.month = dayjs(preTime*1000).format('MM');
        let firstDayOfPreMonth = prevObj.year+'-'+prevObj.month+'-'+prevObj.day;
        prevObj.week = dayjs(firstDayOfPreMonth).format('ddd')
        prevObj.dateStamp = parseInt(dayjs(firstDayOfPreMonth).format('X'))
        prevObj.label = dayjs(firstDayOfPreMonth).format('MMM')

        result.unshift(prevObj)
      }
    }else{
      dataObj.day = dayjs(now).format('DD')
      dataObj.week = dayjs(now).format('ddd')
      dataObj.dateStamp = parseInt(dayjs(now).format('X'))
      dataObj.label = dataObj.month + '/' + dataObj.day;// dayjs(now).format('ddd')
      //dayjs(now).format('MM')+'/'+dayjs(now).format('DD')+' '+dayjs(now).format('ddd')
      result.push(dataObj)

      for (let i = 1; i < range; i++) {
        let prevObj = cloneDeep(result[0]);
        let preTime = prevObj.dateStamp-86400;
        prevObj.id = i;
        prevObj.dateStamp = preTime;
        prevObj.year = dayjs(preTime*1000).format('YYYY');
        prevObj.month = dayjs(preTime*1000).format('MM');
        prevObj.day = dayjs(preTime*1000).format('DD');
        prevObj.week = dayjs(preTime*1000).format('ddd');
        prevObj.label = prevObj.month+'/'+prevObj.day; //dayjs(preTime*1000).format('ddd') 

        result.unshift(prevObj)
      }
    }
    return result;
  }

  function generateChart(data, chart, range=7) {

    let updateData = cloneDeep(chartData)
    
    chart.forEach(subchart => {
      data.forEach((subdata, index) => {
        let wsYear = dayjs(subdata.timestamp*1000).format('YYYY')
        let wsMonth = dayjs(subdata.timestamp*1000).format('MM')
        let wsDay = dayjs(subdata.timestamp*1000).format('DD')
        // if(range === 365){
        //   if(subchart.month === wsMonth && subchart.year === wsYear){ //
        //     subchart.SBP = null //subdata.SBP;
        //     subchart.DBP = null //subdata.DBP;
        //     subchart.PULSE = null //subdata.PULSE;
        //   }
        // }else{
          if(subchart.month === wsMonth && subchart.day === wsDay){
            subchart.SBP = Math.round(subdata.systolic);
            subchart.DBP = Math.round(subdata.diastolic);
            subchart.PULSE = Math.round(subdata.heartRate);
            // if(subdata.numberOfDay)
            // subchart.numberOfDay = subdata.numberOfDay;
            // subchart.numberOfMonth = subdata.numberOfMonth;
          }
        // }
      })
    });

    updateData.labels = chart.map((item) => item.label);
    updateData.datasets[0].data = chart.map((item) => item.SBP)
    updateData.datasets[1].data = chart.map((item) => item.DBP)
    updateData.datasets[2].data = chart.map((item) => item.PULSE)

    setChartData(updateData);
  }

  const addNewWsData = async (data) => {
    let updateData = cloneDeep(chartData);
    let latest = updateData.datasets[0].data.length -1;
    let count = (timeRange === 365)? data.numberOfMonth: data.numberOfDay;
    let avgSBP = updateData.datasets[0].data[latest];
    let avgDBP = updateData.datasets[1].data[latest];
    let avgPULSE = updateData.datasets[2].data[latest];

    if(avgSBP === null || avgSBP === ''){ avgSBP = 0; }
    let tmpSBP = (avgSBP * (count-1)+ data.data.systolic)/count;

    if(avgDBP === null || avgDBP === ''){ avgDBP = 0; }
    let tmpDBP = (avgDBP * (count-1)+ data.data.diastolic)/count;

    if(avgPULSE === null || avgPULSE === ''){ avgPULSE = 0; }
    let tmpPULSE = (avgPULSE * (count-1)+ data.data.heartRate)/count;
    
    updateData.datasets[0].data[latest] = Math.round(tmpSBP);
    updateData.datasets[1].data[latest] = Math.round(tmpDBP);
    updateData.datasets[2].data[latest] = Math.round(tmpPULSE);
    setChartData(updateData);
  };

  useEffect(() => {
    if (wsData.updateTag === 'report_creat' && wsData.updateData) {
      console.log('chart summary get creat ws');
      console.log(wsData.updateData);
      addNewWsData(cloneDeep(wsData.updateData));
    } else if (wsData.updateTag === 'report_read' && wsData.updateData){
      console.log('chart summary get read ws')
      console.log(wsData.updateData)
      let tmpData = cloneDeep(wsData.updateData);
      let emptyData = [], sortData = [];
      if(tmpData.weeklyAverage === null && tmpData.weeklyAverage === null && tmpData.weeklyAverage === null){
        formatData(emptyData);
      }else{
        if(timeRange === 7 && tmpData.weeklyAverage !== null){
          sortData = tmpData.weeklyAverage.sort(sortTime)
        }else if(timeRange === 30 && tmpData.monthlyAverage !== null){
          sortData = tmpData.monthlyAverage.sort(sortTime)
        }else if(timeRange === 365 && tmpData.yearlyAverage !== null){
          sortData = tmpData.yearlyAverage.sort(sortTime)
        }

        if (sortData.length > 0) {
          formatData(sortData);
        }
      }
    }
  }, [wsData]);

  const formatData = async (sortData) => {
    let defaultData = cloneDeep(sortData);
    let defaultChart = initChart(timeRange);
    generateChart(defaultData, defaultChart, timeRange)
  }

  function sortTime(a,b){
    return a.timestamp-b.timestamp;
  }

  return (
    <>
      <Card variant="outlined" style={{ width: '100%', height: '400px', marginTop: '20px', borderRadius: '10px',}}>
        <Box style={{padding: '10px', width: 'auto', height: '30px'}}>
          <Typography variant="h5" component="div" color="#0A87A9" style={{fontWeight:"bold"}}>
            BP & Pulse
          </Typography>
        </Box>
        <Box style={{padding: '10px 5px', width: 'auto', height: '300px'}}>
          {/* <Chart type='line' data = {chartData} options = {chartOptions} plugins={[chartPlugin]}></Chart> */}
          <Chart type='line' data = {chartData} options = {chartOptions} plugins={[chartPlugin]}></Chart>
        </Box>
              {/* <Box style={{padding: '0px', width: 'auto', height: '30px'}}> */}
        <Grid container alignItems="center" justifyContent="center" spacing={0}>
          <div style={{marginLeft: '0px',width:'10px', height: '10px', borderRadius: '50%',backgroundColor: '#ABB9E8'}}></div>
          <span style={{marginLeft: '5px', color: '#6f748f'}}>SBP</span>
          <div style={{marginLeft: '30px',width:'10px', height: '10px', borderRadius: '50%',backgroundColor: '#4FD1C5'}}></div>
          <span style={{marginLeft: '5px', color: '#6f748f'}}>DBP</span>
          <div style={{marginLeft: '30px',width:'10px', height: '10px', borderRadius: '50%',backgroundColor: '#CB6B6E'}}></div>
          <span style={{marginLeft: '5px', color: '#6f748f'}}>PULSE</span>
        </Grid>
              {/* </Box> */}
      </Card>
    </>
  );
};

export default ChartSummary;
