import React, { useState, useEffect } from 'react';

import { useWebSocket } from "hook/WebSocketContext";
import { useAuth } from "../../hook/AuthContext";
import { useOutletContext } from "react-router-dom";
import { cloneDeep, isEqual } from "lodash";

import { useMediaQuery, useTheme } from "@mui/material";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

import { PATH } from "router/config/const";
import Breadcrumb from 'components/Breadcrumb';

import RecipientSidebar from "./RecipientSidebar";
import ProfileSetting from "./ProfileSetting";
import MedicationSchedule from "./MedicationSchedule";
import HealthReminders from "./HealthReminders";

import {
  InitProfileSettingData, InitMedicationScheduleData, InitHealthyTracking,
  InitProfileSettingFieldValid, InitMedicationScheduleFieldValid, InitHealthRemindersFieldValid,
  CheckRemindersFields, CheckMedicinesFields
} from "./const"

const Recipient = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(1280));

  const [wsData, setWsData] = useOutletContext();
  const ws = useWebSocket();
  const { token } = useAuth();
  // 切換 tab 切換成對應的資料結構
  const [tabValue, setTabValue] = useState('2');
  const [profileSettingData, setProfileSettingData] = useState('');
  const [medicationScheduleData, setMedicationScheduleData] = useState([InitMedicationScheduleData]);
  const [healthRemindersData, setHealthRemindersData] = useState([InitHealthyTracking]);
  const initData = tabValue === '1' ? InitProfileSettingData : tabValue === '2' ? InitMedicationScheduleData : tabValue === '3' ? InitHealthyTracking : '';
  const addScheduleData = tabValue === '1' ? profileSettingData : tabValue === '2' ? medicationScheduleData : tabValue === '3' ? healthRemindersData : '';
  const setData = tabValue === '1' ? setProfileSettingData : tabValue === '2' ? setMedicationScheduleData : tabValue === '3' ? setHealthRemindersData : '';
  //  驗證機制
  const [isProfileSettingFieldValid, setIsProfileSettingFieldValid] = useState([])
  const [isMedicationScheduleFieldValid, setIsMedicationScheduleFieldValid] = useState([InitMedicationScheduleFieldValid])
  const [isHealthRemindersFieldValid, setIsHealthRemindersFieldValid] = useState([InitHealthRemindersFieldValid])
  const initValidateField = tabValue === '1' ? InitProfileSettingFieldValid : tabValue === '2' ? InitMedicationScheduleFieldValid : tabValue === '3' ? InitHealthRemindersFieldValid : '';
  const isValidateField = tabValue === '1' ? isProfileSettingFieldValid : tabValue === '2' ? isMedicationScheduleFieldValid : tabValue === '3' ? isHealthRemindersFieldValid : '';
  const setIsValidateField = tabValue === '1' ? setIsProfileSettingFieldValid : tabValue === '2' ? setIsMedicationScheduleFieldValid : tabValue === '3' ? setIsHealthRemindersFieldValid : '';
  // read cron flag 防止重複收到 read cron 的資料
  const [allCronFlag, setAllCronFlag] = useState(true)
  // 關閉 modal 的 state
  const [alertDialogProfileSettingOpen, setAlertDialogProfileSettingOpen] = useState({ state: false, id: null });
  const [alertDialogMedicationScheduleOpen, setAlertDialogMedicationScheduleOpen] = useState({ state: false, id: null });
  const [alertDialogHealthRemindersOpen, setAlertDialogHealthRemindersOpen] = useState({ state: false, id: null });
  const alertDialog = tabValue === '1' ? alertDialogProfileSettingOpen : tabValue === '2' ? alertDialogMedicationScheduleOpen : tabValue === '3' ? alertDialogHealthRemindersOpen : '';
  const setAlertDialog = tabValue === '1' ? setAlertDialogProfileSettingOpen : tabValue === '2' ? setAlertDialogMedicationScheduleOpen : tabValue === '3' ? setAlertDialogHealthRemindersOpen : '';
  // 自動更新的機制
  const [profileSettingCacheData, setProfileSettingCacheData] = useState([])
  const [medicationScheduleCacheData, setMedicationScheduleCacheData] = useState([])
  const [healthRemindersCacheData, setHealthRemindersCacheData] = useState([])
  const cacheData = tabValue === '1' ? profileSettingCacheData : tabValue === '2' ? medicationScheduleCacheData : tabValue === '3' ? healthRemindersCacheData : '';
  const setCacheData = tabValue === '1' ? setProfileSettingCacheData : tabValue === '2' ? setMedicationScheduleCacheData : tabValue === '3' ? setHealthRemindersCacheData : '';

  const [profileSettingAutoUpdateData, setProfileSettingAutoUpdateData] = useState([])
  const [medicationScheduleAutoUpdateData, setMedicationScheduleAutoUpdateData] = useState([])
  const [healthRemindersAutoUpdateData, setHealthRemindersAutoUpdateData] = useState([])
  const setAutoUpdateData = tabValue === '1' ? setProfileSettingAutoUpdateData : tabValue === '2' ? setMedicationScheduleAutoUpdateData : tabValue === '3' ? setHealthRemindersAutoUpdateData : '';

  function handleTabChange(event, newValue) {
    sendUpdateData(addScheduleData, cacheData);
    setTabValue(newValue);
  }

  function renderPanels(data, tabPanelPlaceholder, Component, tabValue) {
    if (data.length === 0) {
      return (
        <TabPanel
          sx={{
            padding: '20px 20px 10px 20px',
            position: "relative",
            border: '1px solid #F2F3F4',
            borderRadius: '0px 20px 20px 20px',
            backgroundColor: 'white',
            marginBottom: '20px'
          }}
          value={tabValue}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '30rem' }}>
            {tabPanelPlaceholder}
          </Box>
        </TabPanel>
      );
    }
    return data.map((item, index) => (
      <TabPanel
        key={`${tabValue}-${item.id}`}
        sx={{
          padding: '20px 20px 10px 20px',
          position: "relative",
          border: '1px solid #F2F3F4',
          borderRadius: index === 0 ? '0px 20px 20px 20px' : '20px',
          backgroundColor: 'white',
          marginBottom: '20px'
        }}
        value={tabValue}
      >
        <Component
          ws={ws}
          data={item}
          setData={setData}
          alertDialog={alertDialog}
          setAlertDialog={setAlertDialog}
          isValidateField={isValidateField[index]}
          setIsValidateField={setIsValidateField}
          token={token}
        />
      </TabPanel>
    ));
  };

  function generateCronExpression(data) {
    const times = data.dailyJobAtTimes.map(time => {
      const [hours, minutes, seconds] = time.split(':').map(Number);
      const daysInterval = data.dailyJobInterval;
      return `${minutes} ${hours} */${daysInterval} * *`;
    });

    return times;
  }

  function checkIfFieldIsRequired(data) {
    let hasError = false;
    let newValidateField = cloneDeep(isValidateField);

    const validateFieldIndex = newValidateField.findIndex(field => field.id === data.id);

    if (validateFieldIndex !== -1) {
      CheckRemindersFields.forEach(checkField => {
        if (Array.isArray(data[checkField.field]) ? data[checkField.field].length === 0 : data[checkField.field] === '' || Number.isNaN(data[checkField.field])) {
          hasError = true;
          newValidateField[validateFieldIndex][checkField.field] = checkField.message;
        } else {
          newValidateField[validateFieldIndex][checkField.field] = "";
        }
      });

      if (data.type === 'takeMedicine') {
        data.medicines.forEach((medicine, index) => {
          CheckMedicinesFields.forEach(checkField => {
            if (!medicine[checkField.field]) {
              hasError = true;
              if (!newValidateField[validateFieldIndex].medicines[index]) {
                newValidateField[validateFieldIndex].medicines[index] = {};
              }
              newValidateField[validateFieldIndex].medicines[index][checkField.field] = checkField.message;
            } else {
              if (!newValidateField[validateFieldIndex].medicines[index]) {
                newValidateField[validateFieldIndex].medicines[index] = {};
              }
              newValidateField[validateFieldIndex].medicines[index][checkField.field] = "";
            }
          });
        });
      }
    }

    setIsValidateField(newValidateField);
    return hasError;
  }

  function sendUpdateData(editingData, originalData) {
    let newAutoUpdateData = [];

    editingData.forEach((item, index) => {
      if (index === 0) return item;
      if (!isEqual(item, originalData[index - 1])) {
        newAutoUpdateData.push(item);
      }
    });
    if (newAutoUpdateData.length > 0) {
      setAutoUpdateData(newAutoUpdateData);
      newAutoUpdateData.forEach((updateData) => {
        const newPatterns = generateCronExpression(updateData)
        updateData.patterns = newPatterns
        // user api 還沒開發先寫假資料
        updateData.username = "Father"
        const isValid = checkIfFieldIsRequired(updateData);

        const requestUpdateOneCron = {
          resource: `/users/0/crons/${updateData.id}`,
          verb: "update",
          accessToken: token,
          data: updateData
        };

        if (!isValid) {
          ws.send(JSON.stringify(requestUpdateOneCron));
        }
      });
    }
  };

  function updateCacheData(data) {
    const updateData = (setEditingData, setCacheData, setAutoUpdateData) => {
      setEditingData(prevState => prevState.map(item => (item.id === data.id ? data : item)));
      setCacheData(prevState => prevState.map(item => (item.id === data.id ? data : item)));
      setAutoUpdateData(prevState => prevState.filter(item => item.id !== data.id));
    };

    if (data.type === "takeMedicine") {
      updateData(setMedicationScheduleData, setMedicationScheduleCacheData, setMedicationScheduleAutoUpdateData);
    }

    if (data.type === "takeBloodPressure") {
      updateData(setHealthRemindersData, setHealthRemindersCacheData, setHealthRemindersAutoUpdateData);
    }
  }

  useEffect(() => {
    const requestAllCron = {
      resource: "/users/0/crons",
      verb: "read",
      accessToken: token,
    }
    ws.send(JSON.stringify(requestAllCron));
  }, [])

  useEffect(() => {
    // 取資料
    if (wsData.updateTag === "allCron" && wsData.updateData && allCronFlag) {
      const allCronData = cloneDeep(wsData);
      const readData = allCronData.updateData;
      // medication schedule
      const medicationScheduleReadData = readData.filter(item => item.type === 'takeMedicine');
      setMedicationScheduleData((prevState) => ([...prevState, ...medicationScheduleReadData]));
      const readMedicationScheduleValidateFieldData = medicationScheduleReadData.map(item => ({
        ...isMedicationScheduleFieldValid[0],
        id: item.id,
        medicines: item.medicines.map(() => ({
          name: "",
          quantity: "",
          shape: ""
        }))
      }))
      setIsMedicationScheduleFieldValid((prevState) => ([...prevState, ...readMedicationScheduleValidateFieldData]));
      setMedicationScheduleCacheData((prevState) => ([...prevState, ...medicationScheduleReadData]));
      // health reminders
      const healthRemindersReadData = readData.filter(item => item.type === 'takeBloodPressure');
      setHealthRemindersData((prevState) => ([...prevState, ...healthRemindersReadData]));
      const readHealthRemindersValidateFieldData = healthRemindersReadData.map(item => ({
        ...isHealthRemindersFieldValid[0],
        id: item.id,
      }))
      setIsHealthRemindersFieldValid((prevState) => ([...prevState, ...readHealthRemindersValidateFieldData]));
      setHealthRemindersCacheData((prevState) => ([...prevState, ...healthRemindersReadData]));
      setAllCronFlag(false);
    }
    // 新增資料
    if (wsData.updateTag === "createCron" && wsData.updateData) {
      const createCronData = cloneDeep(wsData);
      const newCreateData = createCronData.updateData;

      setData((prevState) => {
        const newState = [...prevState];
        newState[0] = newCreateData;
        newState.unshift(initData);
        return newState;
      });

      setCacheData((prevState) => {
        const newState = [...prevState];
        newState.unshift(newCreateData)
        return newState;
      });

      const newValidateFieldData = [newCreateData].map(item => {
        const { medicines } = item || {};
        if (medicines) {
          return {
            ...initValidateField,
            id: item.id,
            medicines: medicines.map(() => ({
              name: "",
              quantity: "",
              shape: ""
            }))
          };
        } else {
          return {
            ...initValidateField
          };
        }
      });

      setIsValidateField((prevState) => {
        const newState = [...prevState];
        newState[0] = newValidateFieldData[0];
        newState.unshift(initValidateField);
        return newState;
      });
    }
    // 刪除資料
    if (wsData.updateTag === "deleteCron" && wsData.updateData.errorCode === 0) {
      const currentDataId = wsData.updateData.resource.match(/\d+$/);
      const findCurrentDataId = currentDataId ? parseInt(currentDataId[0]) : null;
      const updateStateData = (setStateFunction) => {
        setStateFunction(prevState => prevState.filter(item => item.id !== findCurrentDataId));
      };

      updateStateData(setData);
      updateStateData(setCacheData);
      updateStateData(setAutoUpdateData);
      updateStateData(setIsValidateField);
      setAlertDialog({ state: false, id: null });
    }
    // 更新資料
    if (wsData.updateTag === "updateCron" && wsData.updateData.errorCode === 0) {
      updateCacheData(wsData.updateData.data);
    }
  }, [wsData])

  useEffect(() => {
    let interval;
    if (!allCronFlag) {
      interval = setInterval(() => {
        sendUpdateData(addScheduleData, cacheData);
      }, 5000);
    }
    return () => clearInterval(interval);
  }, [allCronFlag, addScheduleData, cacheData]);

  return (
    <>
      <Breadcrumb path={PATH.recipient_setting}></Breadcrumb>
      <Box sx={{ marginTop: '20px', display: 'flex', width: '100%', overflow: 'hidden' }}>
        <Grid container spacing={2}>
          <Grid item lg={isSmallScreen ? 12 : 3} xl={2}>
            <Box sx={{ display: 'flex', flexDirection: isSmallScreen ? 'row' : 'column', alignItems: 'center', justifyContent: 'center' }}>
              <RecipientSidebar ws={ws} addScheduleData={addScheduleData} generateCronExpression={generateCronExpression} checkIfFieldIsRequired={checkIfFieldIsRequired} token={token} />
            </Box>
          </Grid>
          <Grid item lg={isSmallScreen ? 12 : 9} xl={10}>
            <Box sx={{ width: '100%', typography: 'body1' }}>
              <TabContext value={tabValue}>
                <Box>
                  <TabList onChange={handleTabChange} aria-label="lab API tabs example"
                    TabIndicatorProps={{ sx: { backgroundColor: "white", height: '1px' } }}
                    sx={{
                      '& button': { backgroundColor: '#EEF0F2', color: '#737791' },
                      '& button: active': { backgroundColor: '#FFFFFF', color: '#0087A8' },
                      '& button: focus': { backgroundColor: '#FFFFFF', color: '#0087A8' },
                      '& button: blur': { backgroundColor: '#FFFFFF', color: '#0087A8' },
                      '& .MuiButtonBase-root': {
                        '&.Mui-selected': { backgroundColor: '#FFFFFF', color: '#0087A8', fontWeight: 600 },
                      },
                    }}
                  >
                    <Tab label="Profile Setting" sx={{ textTransform: "none", borderRadius: '20px 0 0 0', minWidth: "15%", fontSize: "16px", lineHeight: 1.25, padding: '20px 50px' }} value="1" disabled />
                    <Tab label="Medication Schedule" sx={{ textTransform: "none", minWidth: "15%", fontSize: "16px", lineHeight: 1.25, padding: '20px 50px' }} value="2" />
                    <Tab label="Health Reminders" sx={{ textTransform: "none", borderRadius: '0 20px 0 0', minWidth: "15%", fontSize: "16px", lineHeight: 1.25, padding: '20px 50px' }} value="3" />
                  </TabList>
                </Box>
                <TabPanel value="1"><ProfileSetting /></TabPanel>
                {renderPanels(medicationScheduleData, 'Please add new medication schedule.', MedicationSchedule, "2")}
                {renderPanels(healthRemindersData, 'Please add new health reminders.', HealthReminders, "3")}
              </TabContext>
            </Box>
          </Grid>
        </Grid>
      </Box >
    </>
  );
};

export default Recipient;