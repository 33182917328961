import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import ImageUploaderField from './components/ImageUploaderField';
import InputField, { VerificationCodeInputField } from './components/InputField';
import TextAreaField from './components/TextAreaField';

import { INIT_CREATE_HOME_GROUP_DATA } from './const';

const CreateHomeGroup = ({ handleNext, isValidateField, setIsValidateField }) => {

  const userId = useSelector((state) => state.user.userInfo).id;

  const [createHomeGroupData, setCreateHomeGroupData] = useState(INIT_CREATE_HOME_GROUP_DATA)

  function handleOnChange(fieldName, newValue, isClear = false) {
    setCreateHomeGroupData(prevData => ({
      ...prevData,
      [fieldName]: isClear ? '' : newValue,
    }));

    if (fieldName !== 'pageCode' && isValidateField[fieldName]) {
      setIsValidateField(prevData => ({
        ...prevData,
        [fieldName]: '',
      }));
    }
  };

  useEffect(() => {
    if (userId) {
      handleOnChange('creatorId', userId);
    }
  }, [userId])

  useEffect(() => {
    if (createHomeGroupData.pageCode.join('').length === 4 && isValidateField.pageCode) {
      setIsValidateField(prevData => ({
        ...prevData,
        pageCode: '',
      }));
    }
  }, [createHomeGroupData.pageCode])
  

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4}>
          <ImageUploaderField
            imagePreview={createHomeGroupData.portrait}
            setImagePreview={(newValue) => handleOnChange('portrait', newValue)}
          />
        </Grid>

        <Grid item xs={12} md={8} container spacing={2}>
          <Grid item xs={12}>
            <InputField
              id="groupName"
              label="Group Name"
              placeholder="Group Name"
              value={createHomeGroupData.name}
              onChange={(event) => handleOnChange('name', event.target.value)}
              iconClick={(event) => handleOnChange('name', event.target.value, true)}
              helperText={isValidateField.name}
            />
          </Grid>
          <Grid item xs={12}>
            <InputField
              id="address"
              label="Address"
              placeholder="Address"
              value={createHomeGroupData.address}
              onChange={(event) => handleOnChange('address', event.target.value)}
              iconClick={(event) => handleOnChange('address', event.target.value, true)}
              helperText={isValidateField.address}
            />
          </Grid>
          <Grid item xs={12}>
            <VerificationCodeInputField
              label="Page Code"
              value={createHomeGroupData.pageCode}
              setValue={(newValue) => handleOnChange('pageCode', newValue)}
              helperText={isValidateField.pageCode}
            />
          </Grid>
          <Grid item xs={12}>
            <TextAreaField
              id="note"
              label="note"
              placeholder="note"
              value={createHomeGroupData.description}
              onChange={(event) => handleOnChange('description', event.target.value)}
            />
          </Grid>
          <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: 'row' }, justifyContent: "center", alignItems: "center", width: { xs: "100%", sm: '60%' } }}>
            <Grid item xs={12} md={4}>
              <Button
                sx={{
                  fontSize: "16px",
                  fontWeight: 600,
                  borderRadius: "10px",
                  backgroundColor: "#0A87A9",
                  padding: "8px 16px",
                  "&:hover": {
                    backgroundColor: "#05647E",
                  },
                  marginTop: { xs: "10px", sm: 0 }
                }}
                variant="contained"
                onClick={() => handleNext(createHomeGroupData)}
              >
                Create Home Group
              </Button>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default CreateHomeGroup;