import dayjs from 'dayjs';

import Father from "assets/img/elderMaleFemale/GettyImages-916097436.png";
import Mother from "assets/img/elderMaleFemale/GettyImages-109839766.png";
import Uncle from "assets/img/elderMaleFemale/GettyImages-1334476598.png";

const today = dayjs().format('YYYY/M/D')
const endOfDay = dayjs(today).endOf('day');
const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

export const UserData = [
  {
    name: 'Father',
    personalInfo: 'Age 65 | Male',
    picture: Father,
    state: false
  },
  {
    name: 'Mother',
    personalInfo: 'Age 62 | Female',
    picture: Mother,
    state: true
  },
  // {
  //   name: 'Uncle',
  //   personalInfo: 'Age 63 | Male',
  //   picture: Uncle,
  //   state: true
  // }
]

export const InitProfileSettingData = {}

export const InitMedicationScheduleData = {
  type: "takeMedicine",
  name: "",
  enabled: false,
  timing: "",
  dailyJobAtTimes: ["00:00:00", "08:00:00", "12:00:00", "16:00:00"],
  patterns: [],
  dailyJobInterval: "",
  snooze: "",
  timezone: userTimeZone,
  startTime: dayjs(today).unix(),
  endTime: dayjs(endOfDay).unix(),
  medicines: [{
    name: "",
    quantity: "",
    shape: "",
  }]
}

export const InitHealthyTracking = {
  type: "takeBloodPressure",
  name: "",
  enabled: false,
  dailyJobAtTimes: ["00:00:00", "08:00:00", "12:00:00", "16:00:00"],
  patterns: [],
  dailyJobInterval: "",
  snooze: "",
  timezone: userTimeZone,
  startTime: dayjs(today).unix(),
  endTime: dayjs(endOfDay).unix(),
}

export const DosageData = [
  {
    label: 'Before meal',
    value: 'AC'
  },
  {
    label: 'After meal',
    value: 'PC'
  },
  {
    label: 'With meal',
    value: 'F'
  },
  {
    label: 'Before sleep',
    value: 'HS'
  },
  {
    label: 'When needed',
    value: 'PRN'
  },
];

export const QuantityData = [
  { label: '1', value: 1 }, { label: '2', value: 2 }, { label: '3', value: 3 }, { label: '4', value: 4 }, { label: '5', value: 5 },
  { label: '6', value: 6 }, { label: '7', value: 7 }, { label: '8', value: 8 }, { label: '9', value: 9 }, { label: '10', value: 10 },
  { label: '11', value: 11 }, { label: '12', value: 12 }, { label: '13', value: 13 }, { label: '14', value: 14 }, { label: '14', value: 14 },
  { label: '15', value: 15 }, { label: '16', value: 17 }, { label: '18', value: 18 }, { label: '19', value: 19 }, { label: '20', value: 20 }
];

export const DoseData = [
  {
    label: 'capsule',
    value: 'capsule',
  },
  {
    label: 'tablet',
    value: 'tablet',
  },
  {
    label: 'powder',
    value: 'powder',
  },
  {
    label: 'liquid',
    value: 'liquid',
  },
];

export const NewMedication = {
  name: '',
  quantity: '',
  shape: '',
};


export const InitProfileSettingFieldValid = {}

export const InitMedicationScheduleFieldValid = {
  name: '',
  timing: '',
  patterns: '',
  addTime: '',
  startTime: '',
  dailyJobInterval: '',
  endTime: '',
  snooze: '',
  medicines: [{
    name: '',
    quantity: '',
    shape: '',
  }],
}

export const InitHealthRemindersFieldValid = {
  name: '',
  patterns: '',
  addTime: '',
  startTime: '',
  dailyJobInterval: '',
  endTime: '',
  snooze: '',
}

export const CheckRemindersFields = [
  { field: 'name', message: 'This field is required.' },
  { field: 'timing', message: 'This field is required.' },
  { field: 'patterns', message: 'Reminder cannot be empty.' },
  { field: 'startTime', message: 'This field is required.' },
  { field: 'dailyJobInterval', message: 'This field is required.' },
  { field: 'endTime', message: 'This field is required.' },
  { field: 'snooze', message: 'This field is required.' }
];

export const CheckMedicinesFields = [
  { field: 'name', message: 'This field is required.' },
  { field: 'quantity', message: 'This field is required.' },
  { field: 'shape', message: 'This field is required.' }
];