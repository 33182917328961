import React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const AlertDialog = ({ opneState, onClose, dialogTitle, dialogContent, actionButton }) => (
  <Dialog
    open={opneState}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      {dialogTitle}
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        {dialogContent}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      {actionButton}
    </DialogActions>
  </Dialog>
);

export default AlertDialog