import React from "react";
import { useEffect, useState } from "react";
import deviceStyle from "./aibox.module.scss";
import Cards from "./components/Cards";
import Grid from "@mui/material/Grid";
import { useMediaQuery, useTheme } from "@mui/material";
import { ReactComponent as AIBox } from "assets/img/icon/img_ai_box.svg";
import { useWebSocket } from "hook/WebSocketContext";

const Aibox = (props) => {
  const { wsData, setWsData } = props;
  const ws = useWebSocket();
  const [devicesData, setDevicesData] = useState([]);
  /*   const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(1440));
  const isSmallWhiteSpace = useMediaQuery(theme.breakpoints.down(1550)); */

  useEffect(() => {
    if (wsData.updateTag === "box_read" && wsData.updateData) {
      const initDevicesData = wsData.updateData;
      if (initDevicesData && initDevicesData.length > 0) {
        const box = initDevicesData[0].box;
        setDevicesData(box);
      }
    }
  }, [wsData]);

  return (
    <Cards numPages={1}>
      <Grid container spacing={2} key={devicesData.length} alignItems="center">
        <Grid item xs={6} className={deviceStyle["aibox-image-container"]}>
          <AIBox className={deviceStyle["aibox-image"]} />
        </Grid>
        <Grid item xs={6} className={deviceStyle["aibox-info-container"]}>
          <Grid
            container
            direction="column"
            alignItems="flex-start"
            spacing={1}
          >
            <Grid item className={deviceStyle["aibox-title"]}>
              {devicesData.name}
            </Grid>
            <Grid item className={deviceStyle["aibox-status"]}>
              {devicesData.online ? "Online" : "Offline"}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Cards>
  );
};

export default Aibox;
