import father from "assets/img/elderMaleFemale/GettyImages-916097436.png";
import mother from "assets/img/elderMaleFemale/GettyImages-109839766.png";
import uncle from "assets/img/elderMaleFemale/GettyImages-1334476598.png";
import grandmother from "assets/img/elderMaleFemale/GettyImages-1358678970.png";
import grandfather from "assets/img/elderMaleFemale/GettyImages-1190895679.png";

export const CHINESE_MONTHS = ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'];

export const WEEK_DAYS = ["日", "一", "二", "三", "四", "五", "六"];

export const FAKE_DATA = [
    {
        id: 1,
        type: "醫療",
        activity: "高血壓回診",
        participants: ['爸爸'],
        date: '2024-10-02',
        starttime: '15:30',
        endtime: '17:00',
        color: ['#55ccdd', '#e6fffb'],
    },
    {
        id: 2,
        type: "外出活動",
        activity: "游泳",
        participants: ['爸爸'],
        date: '2024-10-15',
        starttime: '16:00',
        endtime: '18:00',
        color: ['#7ebbd6', '#e9f4fa'],
    },
    {
        id: 3,
        type: "其他",
        activity: "王冀懷泡茶聊天",
        participants: ['爸爸'],
        date: '2024-10-20',
        starttime: '14:00',
        endtime: '18:00',
        color: ['#737791', '#efefef'],
    },
    {
        id: 4,
        type: "生理量測",
        activity: "量血壓",
        participants: ['爸爸'],
        date: '2024-10-21',
        starttime: '08:30',
        endtime: '09:00',
        color: ['#ff947a', '#fff7e6'],
    },
    {
        id: 5,
        type: "用藥",
        activity: "用藥",
        participants: ['爸爸'],
        date: '2024-10-21',
        starttime: '11:30',
        endtime: '11:45',
        color: ['#d3adf7', '#f9f0ff'],
    },
    {
        id: 6,
        type: "生理量測",
        activity: "量血壓",
        participants: ['爸爸'],
        date: '2024-10-22',
        starttime: '08:30',
        endtime: '09:00',
        color: ['#ff947a', '#fff7e6'],
    },
    {
        id: 7,
        type: "用藥",
        activity: "用藥",
        participants: ['爸爸'],
        date: '2024-10-22',
        starttime: '11:30',
        endtime: '11:45',
        color: ['#d3adf7', '#f9f0ff'],
    },
    {
        id: 8,
        type: "外出活動",
        activity: "游泳",
        participants: ['爸爸'],
        date: '2024-10-22',
        starttime: '16:00',
        endtime: '18:00',
        color: ['#7ebbd6', '#e9f4fa'],
    },
    {
        id: 9,
        type: "醫療",
        activity: "高血壓回診",
        participants: ['爸爸'],
        date: '2024-10-22',
        starttime: '14:00',
        endtime: '15:30',
        color: ['#55ccdd', '#e6fffb'],
    },
    {
        id: 10,
        type: "生理量測",
        activity: "量血壓",
        participants: ['爸爸'],
        date: '2024-10-23',
        starttime: '08:30',
        endtime: '09:00',
        color: ['#ff947a', '#fff7e6'],
    },
    {
        id: 11,
        type: "用藥",
        activity: "用藥",
        participants: ['爸爸'],
        date: '2024-10-23',
        starttime: '11:30',
        endtime: '11:45',
        color: ['#d3adf7', '#f9f0ff'],
    },
    {
        id: 12,
        type: "生理量測",
        activity: "量血壓",
        participants: ['爸爸'],
        date: '2024-10-24',
        starttime: '08:30',
        endtime: '09:00',
        color: ['#ff947a', '#fff7e6'],
    },
    {
        id: 13,
        type: "用藥",
        activity: "用藥",
        participants: ['爸爸'],
        date: '2024-10-24',
        starttime: '11:30',
        endtime: '11:45',
        color: ['#d3adf7', '#f9f0ff'],
    },
    {
        id: 14,
        type: "生理量測",
        activity: "量血壓",
        participants: ['爸爸'],
        date: '2024-10-25',
        starttime: '08:30',
        endtime: '09:00',
        color: ['#ff947a', '#fff7e6'],
    },
    {
        id: 15,
        type: "用藥",
        activity: "用藥",
        participants: ['爸爸'],
        date: '2024-10-25',
        starttime: '11:30',
        endtime: '11:45',
        color: ['#d3adf7', '#f9f0ff'],
    },
    {
        id: 16,
        type: "其他",
        activity: "女兒回家",
        participants: ['爸爸'],
        date: '2024-10-25',
        starttime: '20:30',
        endtime: '20:30',
        color: ['#737791', '#efefef'],
    },
    {
        id: 17,
        type: "生理量測",
        activity: "量血壓",
        participants: ['爸爸'],
        date: '2024-10-26',
        starttime: '08:30',
        endtime: '09:00',
        color: ['#ff947a', '#fff7e6'],
    },
    {
        id: 18,
        type: "用藥",
        activity: "用藥",
        participants: ['爸爸'],
        date: '2024-10-26',
        starttime: '11:30',
        endtime: '11:45',
        color: ['#d3adf7', '#f9f0ff'],
    },
    {
        id: 19,
        type: "外出活動",
        activity: "吃飯",
        participants: ['爸爸'],
        date: '2024-10-26',
        starttime: '18:30',
        endtime: '20:00',
        color: ['#7ebbd6', '#e9f4fa'],
    },
    {
        id: 20,
        type: "生理量測",
        activity: "量血壓",
        participants: ['爸爸'],
        date: '2024-10-27',
        starttime: '08:30',
        endtime: '09:00',
        color: ['#ff947a', '#fff7e6'],
    },
    {
        id: 21,
        type: "用藥",
        activity: "用藥",
        participants: ['爸爸'],
        date: '2024-10-27',
        starttime: '11:30',
        endtime: '11:45',
        color: ['#d3adf7', '#f9f0ff'],
    },
    {
        id: 22,
        type: "生理量測",
        activity: "量血壓",
        participants: ['爸爸'],
        date: '2024-10-28',
        starttime: '08:30',
        endtime: '09:00',
        color: ['#ff947a', '#fff7e6'],
    },
    {
        id: 23,
        type: "用藥",
        activity: "用藥",
        participants: ['爸爸'],
        date: '2024-10-28',
        starttime: '11:30',
        endtime: '11:45',
        color: ['#d3adf7', '#f9f0ff'],
    },
    {
        id: 24,
        type: "生理量測",
        activity: "量血壓",
        participants: ['爸爸'],
        date: '2024-10-29',
        starttime: '08:30',
        endtime: '09:00',
        color: ['#ff947a', '#fff7e6'],
    },
    {
        id: 25,
        type: "用藥",
        activity: "用藥",
        participants: ['爸爸'],
        date: '2024-10-29',
        starttime: '11:30',
        endtime: '11:45',
        color: ['#d3adf7', '#f9f0ff'],
    },
    {
        id: 26,
        type: "外出活動",
        activity: "游泳",
        participants: ['爸爸'],
        date: '2024-10-29',
        starttime: '16:00',
        endtime: '18:00',
        color: ['#7ebbd6', '#e9f4fa'],
    },
    {
        id: 27,
        type: "生理量測",
        activity: "量血壓",
        participants: ['爸爸'],
        date: '2024-10-30',
        starttime: '08:30',
        endtime: '09:00',
        color: ['#ff947a', '#fff7e6'],
    },
    {
        id: 28,
        type: "用藥",
        activity: "用藥",
        participants: ['爸爸'],
        date: '2024-10-30',
        starttime: '11:30',
        endtime: '11:45',
        color: ['#d3adf7', '#f9f0ff'],
    },
    {
        id: 29,
        type: "外出活動",
        activity: "大學同學會",
        participants: ['爸爸'],
        date: '2024-10-30',
        starttime: '17:30',
        endtime: '21:00',
        color: ['#7ebbd6', '#e9f4fa'],
    },
    {
        id: 30,
        type: "生理量測",
        activity: "量血壓",
        participants: ['爸爸'],
        date: '2024-10-31',
        starttime: '08:30',
        endtime: '09:00',
        color: ['#ff947a', '#fff7e6'],
    },
    {
        id: 31,
        type: "用藥",
        activity: "用藥",
        participants: ['爸爸'],
        date: '2024-10-31',
        starttime: '11:30',
        endtime: '11:45',
        color: ['#d3adf7', '#f9f0ff'],
    },
]

// export const FAKE_DATA = [
//     {
//         id: 1,
//         type: "外出活動",
//         activity: "大安森林公園",
//         participants: ['爸爸', '媽媽'],
//         date: '2024-10-01',
//         starttime: '',
//         endtime: '',
//         color: ['#7ebbd6', '#e9f4fa'],
//     },
//     {
//         id: 2,
//         type: "生理量測",
//         activity: "量血壓",
//         participants: ['爸爸', '媽媽', '叔叔', '奶奶', '爺爺'],
//         date: '2024-10-01',
//         starttime: '08:00',
//         endtime: '08:00',
//         color: ['#ff947a', '#fff7e6'],
//     },
//     {
//         id: 3,
//         type: "用藥",
//         activity: "用藥",
//         timing: '飯前',
//         participants: ['爸爸', '媽媽'],
//         date: '2024-10-01',
//         starttime: '11:30',
//         endtime: '11:30',
//         color: ['#d3adf7', '#f9f0ff'],
//     },
//     {
//         id: 4,
//         type: "醫療",
//         activity: "回診",
//         participants: ['爸爸'],
//         date: '2024-10-01',
//         starttime: '14:15',
//         endtime: '16:15',
//         color: ['#55ccdd', '#e6fffb'],
//     },
//     {
//         id: 5,
//         type: "外出活動",
//         activity: "朋友來訪",
//         participants: ['爸爸'],
//         date: '2024-10-01',
//         starttime: '20:00',
//         endtime: '21:00',
//         color: ['#737791', '#efefef'],
//     },
//     {
//         id: 6,
//         type: "生理量測",
//         activity: "量血壓",
//         participants: ['爸爸', '媽媽', '叔叔'],
//         date: '2024-10-01',
//         starttime: '22:00',
//         endtime: '22:00',
//         color: ['#ff947a', '#fff7e6'],
//     },
//     {
//         id: 7,
//         type: "生理量測",
//         activity: "量血壓",
//         participants: ['爸爸', '媽媽', '叔叔'],
//         date: '2024-10-02',
//         starttime: '08:00',
//         endtime: '08:00',
//         color: ['#ff947a', '#fff7e6'],
//     },
//     {
//         id: 8,
//         type: "用藥",
//         activity: "用藥",
//         timing: '飯前',
//         participants: ['爸爸', '媽媽'],
//         date: '2024-10-02',
//         starttime: '11:30',
//         endtime: '11:30',
//         color: ['#d3adf7', '#f9f0ff'],
//     },
//     {
//         id: 9,
//         type: "外出活動",
//         activity: "朋友來訪",
//         participants: ['爸爸'],
//         date: '2024-10-02',
//         starttime: '13:00',
//         endtime: '14:00',
//         color: ['#737791', '#efefef'],
//     },
//     {
//         id: 10,
//         type: "外出活動",
//         activity: "大安森林公園",
//         participants: ['爸爸', '媽媽'],
//         date: '2024-10-15',
//         starttime: '',
//         endtime: '',
//         color: ['#7ebbd6', '#e9f4fa'],
//     },
// ]

export const AVATAR_IMG = {
    ['爸爸']: father,
    ['媽媽']: mother,
    ['叔叔']: uncle,
    ['奶奶']: grandmother,
    ['爺爺']: grandfather,
};