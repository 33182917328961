import React from 'react';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';

import FieldContainer from "./FieldContainer";

import { ReactComponent as SwitchOn } from 'assets/img/icon/btn_switch_on.svg';
import { ReactComponent as SwitchOff } from 'assets/img/icon/btn_switch_off.svg';

export const SwitchBtn = ({ checked, onChange }) => (
    <Box sx={{ cursor: 'pointer' }} onClick={onChange}>
        {checked ? <SwitchOn /> : <SwitchOff />}
    </Box>
);

const SwitchField = ({ label, checked, onChange }) => (
    <FieldContainer label={label}>
        <Box
            sx={{ minWidth: 180 }}
        >
            <FormControl fullWidth>
                <SwitchBtn
                    checked={checked}
                    onChange={onChange}
                />
            </FormControl>
        </Box>
    </FieldContainer>
);

export default SwitchField;