import "./App.css";
import { useState } from "react";
import { RouterProvider, useLocation } from "react-router-dom";
import { WebSocketProvider } from "./hook/WebSocketContext";
import { NotificationProvider } from "./hook/NotificationContext";
import { AuthProvider } from "./hook/AuthContext";
import { ThemeProvider } from '@mui/material/styles';
import router from "./router/index";
import theme from "./theme";

//Router
// import { getRoutes } from "./router";
const WEBSOCKET_URL = process.env.REACT_APP_WEBSOCKET_URL;

function App(props) {
  // let createRouter = createHashRouter(getRoutes());

  return (
    // <RouterProvider router={createRouter} />
    // <Suspense fallback={<SpinnerForModal />}>
    //   {createRouter && <RouterProvider router={createRouter} />}
    // </Suspense>
    <ThemeProvider theme={theme}>
      <NotificationProvider>
        <WebSocketProvider url={WEBSOCKET_URL}>
          <AuthProvider>
            <RouterProvider router={router} />
          </AuthProvider>
        </WebSocketProvider>
      </NotificationProvider>
    </ThemeProvider>
  );
}

export default App;
