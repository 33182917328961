export const iconTitleTheme = {
  marginTop: '20px',
  marginLeft: '20px'
}

export const iconTheme = {
  border:'1px solid #0A87A9',
  height: '33px',
  width: '33px',
  borderRadius: '20%',
  padding: '8px 0px 0px 8px',
  margin: '8px 0px 0px 8px'
}

export const iconThemeSelected = {
  backgroundColor:'#0A87A9',
  height: '33px',
  width: '33px',
  borderRadius: '20%',
  padding: '8px 0px 0px 8px',
  margin: '8px 0px 0px 8px'
}