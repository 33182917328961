export const smartLightData = [
  {
    id: 6,
    name: "Master Room",
    glowing: false,
    online: true,
  },
  {
    id: 7,
    name: "Kitchen",
    glowing: false,
    online: true,
  },
  {
    id: 8,
    name: "Children's Room",
    glowing: false,
    online: true,
  },
];

export const smartPlugData = [
  {
    id: 9,
    name: "Living Room",
    powering: false,
    online: true,
  },
  {
    id: 4,
    name: "Master Room",
    powering: false,
    online: true,
  },
  {
    id: 5,
    name: "Kitchen",
    powering: false,
    online: true,
  }
];
