// src/contexts/WebSocketContext.js
import React, { createContext, useContext, useEffect, useState, useRef } from 'react';
import { createWebSocketConnection } from 'utils/websocket';

const WebSocketContext = createContext(null);

export const useWebSocket = () => useContext(WebSocketContext);

export const WebSocketProvider = ({ children, url }) => {
  const [ws, setWs] = useState(null);
  const socketRef = useRef(null);

  useEffect(() => {
    if (!socketRef.current) {
      const socket = createWebSocketConnection(url, (event) => {
        console.log('WebSocket message received:', event.data);
        // Handle incoming messages here
      });
      socketRef.current = socket;
      setWs(socket);
    }

    return () => {
      if (socketRef.current) {
        socketRef.current.close();
        socketRef.current = null;
      }
    };
  }, [url]);

  return (
    <WebSocketContext.Provider value={ws}>{children}</WebSocketContext.Provider>
  );
};
