import React from 'react';
import recipientStyle from '../recipient.module.scss';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

const FieldContainer = ({ label, children }) => (
    <Grid container justifyContent="flex-start" alignItems="center" sx={{ margin: '0 0 20px 0' }} spacing={0}>
        <Grid item xs={4} >
            <label className={recipientStyle.labelField}>{label}</label>
        </Grid>
        <Grid item xs={5}>
            <Box>
                {children}
            </Box>
        </Grid>
    </Grid>
);

export default FieldContainer;