import React, { useState, useEffect, useContext } from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import Typography from '@mui/material/Typography';
import Grid from "@mui/material/Grid";
import Box from '@mui/material/Box';
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Collapse from "@mui/material/Collapse";
import dayjs from "dayjs";
import { useWebSocket } from "hook/WebSocketContext";
import { useAuth } from "../../../hook/AuthContext";
import { cloneDeep, isNil } from 'lodash';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import upper from "assets/img/icon/btn_arrow_upper.svg";
import down from "assets/img/icon/btn_arrow_down.svg";
import note from "assets/img/icon/icn_device_error.svg";
import calendar from "assets/img/icon/icn_menu_schedule.svg";

// Controller
import controller from '../controller';
import { HistorySummaryData } from "../simuData";

const CalendarIcon = () => (
  <img src={calendar} alt="Calendar" />
);

const UpperIcon = (props) => (
  <img
    src={upper}
    alt="upper"
    style={{
      width: 20,
      pointerEvents: "none",
    }}
    {...props}
  />
);

const DownIcon = (props) => (
  <img
    src={down}
    alt="down"
    style={{
      width: 20,
      pointerEvents: "none",
    }}
    {...props}
  />
);

const localStorageId = 'SUMMARY_HISTORY';
const History = (props) => {
  const { wsData, setWsData } = props;
  const ws = useWebSocket();
  const { token } = useAuth();

  let now = dayjs();
  let limitday = dayjs().subtract(365, 'day');
  let rangeday = dayjs().subtract(15, 'day');

  const [startDate, setStartDate] = useState({
    current: controller.getDateYMD(rangeday),
    max: controller.getDateYMD(now),
    min: controller.getDateYMD(limitday)
  });
  const [endDate, setEndDate] = useState({
    current: controller.getDateYMD(now),
    max: controller.getDateYMD(now),
    min: controller.getDateYMD(limitday)
  });
  const [filterDate, setFilterDate] = useState({
    startTimeStamp: parseInt(rangeday.format('X')),
    endTimeStamp: parseInt(now.format('X')),
  });

  const [historyData, setHistoryData] = useState(cloneDeep(HistorySummaryData));
  const [open, setOpen] = useState(1);
  const [historyNum, setHistoryNum] = useState({ done: 0, missed: 0 });

  const handleShowDetail = (groupId) => {
    setOpen(groupId === open ? null : groupId);
    const newData = [...historyData];
    for (let item of newData) {
      if(item.gId === groupId){
        let ifShow = item.showFullInfo
        item.showFullInfo = !ifShow;
      }
    }
    setHistoryData(newData);
  };

  function handleDateChange(date, from){
    let change = controller.getDateYMD(date);
    let newFilter = cloneDeep(filterDate);
    let newStartDateObj = cloneDeep(startDate);
    let newEndDateObj = cloneDeep(startDate);

    if(from === 'start'){
      newStartDateObj.current = change;
      setStartDate(newStartDateObj)

      let changeEnd = date.add(15, 'day').isAfter(newEndDateObj.max) ? newEndDateObj.max : date.add(15, 'day')
      newEndDateObj.current = changeEnd;
      setEndDate(newEndDateObj)

      newFilter.startTimeStamp = parseInt(dayjs(change).format('X'))
      newFilter.endTimeStamp = parseInt(dayjs(changeEnd).format('X'))+86399
      setFilterDate(newFilter)
    }else{ // click end
      newEndDateObj.current = change;
      setEndDate(newEndDateObj)

      let changeStart = date.subtract(15, 'day').isBefore(newStartDateObj.min) ? newStartDateObj.min : date.subtract(15, 'day')
      newStartDateObj.current = changeStart;
      setStartDate(newStartDateObj)

      newFilter.startTimeStamp = parseInt(dayjs(changeStart).format('X'))
      newFilter.endTimeStamp = parseInt(dayjs(change).format('X'))+86399
      setFilterDate(newFilter);
    }

    // console.log(newFilter)
    getData(newFilter)
  }

  function addNewWsData(newDataAry, type) {
    let tmpNum = { done: 0, missed: 0 }
    let updateData = []; //[...historyData];
    if (type !== 'read'){
      updateData = cloneDeep(historyData);
    }

    for (let newData of newDataAry) {
      let newDateY = dayjs(newData.timestamp*1000).format('YYYY');
      let newDateM = dayjs(newData.timestamp*1000).format('MM');
      let newDateM_Lable = dayjs(newData.timestamp*1000).format('MMM');
      let newDateD = dayjs(newData.timestamp*1000).format('DD');

      let newDateStamp = dayjs(newDateY+'-'+newDateM+'-'+newDateD).format('X');
      let findDateGroup = false;


      for (let item of updateData) {
        // find date
        if(item.year === newDateY && item.month === newDateM && item.day === newDateD){
          findDateGroup = true;
          let detailObj = {
            timestamp: newData.timestamp,
            message: newData.type,
            type: newData.type,
            noteType: (newData.type === 'notTakeMedicine' || newData.type === 'notTakeBloodPressure')? 'missed': 'done',
          }
          // add date to detail & calc avg
          if (type === 'read'){
            item.fullContent.push(detailObj);
          }else if(type === 'update'){
            item.fullContent.unshift(detailObj);
          }
          if(detailObj.noteType === 'done'){
            tmpNum.done++;
          }else{
            tmpNum.missed++;
          }
        }
        item.showFullInfo = false;
      }
      if(!findDateGroup){
        let groupObj = {
          gId: updateData.length+1,
          year: newDateY,
          month: newDateM,
          monthLabel: newDateM_Lable,
          day: newDateD,
          hour: '0', //dayjs().format('HH'),
          dateStamp: newDateStamp,
          showFullInfo: false,
          fullContent: [
            {
              timestamp: newData.timestamp,
              message: newData.type,
              type: newData.type,
              noteType: (newData.type === 'notTakeMedicine' || newData.type === 'notTakeBloodPressure')? 'missed': 'done',
            },
          ]
        }
        //add new date group
        if (type === 'read'){
          updateData.push(groupObj)
        }else if(type === 'update'){
          updateData.unshift(groupObj)
        }
        if(groupObj.fullContent.noteType === 'done'){
          tmpNum.done++;
        }else{
          tmpNum.missed++;
        }
      }

    }

    if(updateData.length > 0){
      updateData[0].showFullInfo = true;
    }

    setHistoryData(updateData);
    setHistoryNum(tmpNum);

    // console.log(updateData)
    // save data to local storage
    // localStorage.setItem(localStorageId, JSON.stringify(updateData));
  }

  const getData = async (newFilter) => {
    let filter = '';
    if(!newFilter){
      let endStamp = parseInt(dayjs().format('X'));
      let tmp_stampRange = endStamp - 86400*7;
      let tmp_startDate = controller.getDateYMD(tmp_stampRange*1000)
      let startStamp = controller.getDate0000(tmp_startDate)

      filter = {
        startTimeStamp: startStamp,
        endTimeStamp: endStamp
      }
    }else{
      filter = cloneDeep(newFilter)
    }
    const eventSendData = {
      resource: `/boxes/0/events`,
      verb: "read",
      accessToken: token,
      data: {
        filter: {
          startTime: filter.startTimeStamp,
          endTime: filter.endTimeStamp,
          category: "notTakeBloodPressure, notTakeMedicine, medicineTaken, bloodPressureTaken"
        },
      },
    };
    ws.send(JSON.stringify(eventSendData));
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    // tag = 'event_read'
    // need change tag after get data

    // if(wsData.updateTag === 'report_creat' && wsData.updateData){
    //   console.log('timeline summary get creat ws')
    //   console.log(wsData.updateData)

    //   let sortData = []
    //   sortData.push(wsData.updateData)
    //   addNewWsData(sortData, 'update');
    // }else
    if(wsData.updateTag === 'event_read' && wsData.updateData && wsData.updateData.category === 'notTakeBloodPressure, notTakeMedicine, medicineTaken, bloodPressureTaken'){
      console.log('history summary get read ws')
      console.log(wsData.updateData)

      if(wsData.updateData.reports !== null){
        let emptyData = []
        // setHealthData(emptyData)

        // save data to local storage
        // localStorage.setItem(localStorageId, JSON.stringify(emptyData));

        let tmpData = cloneDeep(wsData.updateData)
        let sortData = []
        if(tmpData.data !== null){
          sortData = tmpData.data.sort(sortTime)
        }
        console.log(sortData)
        addNewWsData(sortData, 'read');
      }

    }
  }, [wsData]);

  function sortTime(a,b){
    return b.timestamp-a.timestamp;
  }

  return (
    <>
      <Box>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h5" component="div" color="#0A87A9" fontWeight={'600'}>
              Schedule History
            </Typography>
          </Grid>
          <Grid item>
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      value={startDate.current}
                      maxDate={startDate.max}
                      minDate={startDate.min}
                      slots={{ openPickerIcon: CalendarIcon }}
                      onChange={(newValue) => {
                        handleDateChange(newValue, 'start');
                      }}
                      format="YYYY/M/D"
                      slotProps={{
                        day: {
                          sx: { "&.MuiPickersDay-root.Mui-selected": { backgroundColor: "#0A87A9", }, },
                        },
                      }}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': { borderColor: '#BEBEBE', margin: '5px 0px'},
                          '&:hover fieldset': { borderColor: '#BEBEBE', },
                          '&.Mui-focused fieldset': { borderColor: '#BEBEBE', },
                        },
                        width: "150px",
                        "& .MuiOutlinedInput-notchedOutline": { borderRadius: "12px"},
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
              <Grid item>to</Grid>
              <Grid item>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      value={endDate.current}
                      maxDate={endDate.max}
                      minDate={endDate.min}
                      slots={{ openPickerIcon: CalendarIcon }}
                      onChange={(newValue) => {
                        handleDateChange(newValue, 'end');
                      }}
                      format="YYYY/M/D"
                      slotProps={{
                        day: {
                          sx: { "&.MuiPickersDay-root.Mui-selected": { backgroundColor: "#0A87A9", }, },
                        },
                      }}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': { borderColor: '#BEBEBE', margin: '5px 0px'},
                          '&:hover fieldset': { borderColor: '#BEBEBE', },
                          '&.Mui-focused fieldset': { borderColor: '#BEBEBE', },
                        },
                        width: "150px",
                        "& .MuiOutlinedInput-notchedOutline": { borderColor: "#BEBEBE", borderRadius: "12px"},
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box style={{margin: '30px 0px'}}>
        <Grid container alignItems="center" justifyContent="center" spacing={10}>
            <Grid item>
              <Stack alignItems="center">
              <Typography variant="h7" component="div" color="#6f748f" fontWeight={'600'}>
                Task Done
              </Typography>
              <Typography variant="h4" component="div" color="#4fd1c5" fontWeight={'600'}>
                {historyNum.done}
              </Typography>
              </Stack>
            </Grid>
            <Grid item>
              <Stack alignItems="center">
              <Typography variant="h7" component="div" color="#6f748f" fontWeight={'600'}>
                Missed Schedule
              </Typography>
              <Typography variant="h4" component="div" color="#CB6B6E" fontWeight={'600'}>
                {historyNum.missed}
              </Typography>
              </Stack>
            </Grid>
            {/* <Grid item style={{marginLeft: '10px'}}>
              <Stack alignItems="center">
              <Typography variant="h7" component="div" color="#6f748f" fontWeight={'600'}>
                Snoozed
              </Typography>
              <Typography variant="h4" component="div" color="#6f748f" fontWeight={'600'}>
                12345
              </Typography>
              </Stack>
            </Grid> */}
        </Grid>
      </Box>
      <Box>
        {historyData.map((group, index) => (
          <Grid container sx={{ paddingBottom: "20px" }} key={index}>
            <Grid item xs={12} >
              <Stack direction="row" alignItems="center" spacing={3}>
                <IconButton onClick={() => handleShowDetail(group.gId)}>
                  {group.showFullInfo? <DownIcon />:<UpperIcon/> }
                </IconButton>
                <Typography sx={{ color: "#0087a8", fontWeight: 600 }}>
                  {group.monthLabel} {group.day} {group.year}
                </Typography>
              </Stack>
              <Divider />
              <Collapse in={open === group.gId}>
                {group.fullContent.map((item, id) => (
                  <div style={{ paddingLeft: "60px" }} key={id}>
                    <Typography component="div" style={{ width: "100%", lineHeight: 3, }} >
                      <div style={{ display: "flex", width: "100%" }}>
                        <span style={{ marginRight: "30px", fontWeight: "normal", }}>
                          <div style={{
                            borderRadius: '10px', marginTop: '16px',
                            width: '16px', height: '16px',
                            backgroundColor: item.noteType==='done'? '#4fd1c5': '#CB6B6E',
                          }}></div>
                        </span>
                        <span style={{ color: "#0087a8", marginRight: "30px", fontWeight: "normal", }}>
                          {dayjs(item.timestamp*1000).format('HH:mm')}
                        </span>
                        <span style={{ color: "#7d8299", fontWeight: "normal", }}>
                          {item.message}
                        </span>
                      </div>
                      <Divider />
                    </Typography>
                  </div>
                ))}
              </Collapse>
            </Grid>
          </Grid>
        ))}
      </Box>
    </>
  );
}

export default History;