import React, { createContext, useContext, useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import * as Setting from "../pages/Login/Setting";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [expiresTime, setExpiresTime] = useState(null);

  useEffect(() => {
    if (token) {
      try {
        const decoded = jwtDecode(token);
        setExpiresTime(decoded.exp);
      } catch (error) {
        console.error("Failed to decode token:", error);
      }
    } else {
      console.error("No token found in localStorage");
    }
  }, [token]);

  const clearToken = () => {
    setToken(null);
    setExpiresTime(null);
    localStorage.clear();
  };

  const refreshToken = async () => {
    try {
      const response = await fetch(`${Setting.ServerUrl}/api/refresh_token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          grant_type: 'refresh_token',
          refresh_token: localStorage.getItem('refreshToken'),
          scope: "SCOPE",
          client_id:process.env.REACT_APP_CLIENT_ID,
          client_secret:process.env.REACT_APP_CLIENT_SECRET
        })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log(data);

      const newExpiresTime = Math.floor(new Date().getTime() / 1000) + data.expires_in;
      setToken(data.access_token);
      setExpiresTime(newExpiresTime);
      localStorage.setItem('token', data.access_token);
      localStorage.setItem('refreshToken', data.refresh_token);
    } catch (error) {
      console.error("Failed to refresh token", error);
      clearToken(); // Clear tokens on failure to refresh
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (expiresTime) {
        const currentTime = Math.floor(new Date().getTime() / 1000);
        console.log('currentTime:'+ currentTime)
        console.log('expiresTime:'+ expiresTime)
        if (currentTime > expiresTime - 300) {
          refreshToken();
        }
        if (currentTime > expiresTime) {
          clearToken();
        }
      }
    }, 300000); // Check every five minutes

    return () => clearInterval(interval);
  }, [expiresTime, token]);

  return (
    <AuthContext.Provider value={{ token, expiresTime }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
