// src/utils/websocket.js
import ReconnectingWebSocket from 'reconnecting-websocket';

export const createWebSocketConnection = (url, onMessageCallback) => {
  const ws = new ReconnectingWebSocket(url);

  ws.onopen = () => {
    console.log('WebSocket connection opened');
  };

  ws.onmessage = (event) => {
    onMessageCallback(event);
  };

  ws.onclose = () => {
    console.log('WebSocket connection closed');
  };

  ws.onerror = (error) => {
    console.error('WebSocket error', error);
  };

  return ws;
};
