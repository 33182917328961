import mainStyle from './settings.module.scss';
import { PATH } from "router/config/const";
import Breadcrumb from 'components/Breadcrumb';
import React, { useState, useCallback } from 'react';
import { cloneDeep, isNil } from "lodash";
import { useOutletContext } from "react-router-dom";
import { useWebSocket } from "hook/WebSocketContext";
import { useAuth } from "hook/AuthContext";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import InputField from "./components/InputField";
import RadioField from "./components/RadioField";
import DateField from "./components/DateField";
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import userPhoto from "assets/img/testPhoto/testImage.jpeg";

// const ImageButton = styled(ButtonBase)(({ theme }) => ({
//   position: 'relative',
//   height: 200,
//   [theme.breakpoints.down('sm')]: {
//     width: '100% !important', // Overrides inline-style
//     height: 100,
//   },
//   '&:hover, &.Mui-focusVisible': {
//     zIndex: 1,
//     '& .MuiImageBackdrop-root': {
//       opacity: 0.15,
//     },
//     '& .MuiImageMarked-root': {
//       opacity: 0,
//     },
//     '& .MuiTypography-root': {
//       border: '4px solid currentColor',
//     },
//   },
// }));

// const ImageSrc = styled('span')({
//   position: 'absolute',
//   left: 0,
//   right: 0,
//   top: 0,
//   bottom: 0,
//   backgroundSize: 'cover',
//   backgroundPosition: 'center 40%',
// });

// const Image = styled('span')(({ theme }) => ({
//   position: 'absolute',
//   left: 0,
//   right: 0,
//   top: 0,
//   bottom: 0,
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center',
//   color: theme.palette.common.white,
// }));

// const ImageBackdrop = styled('span')(({ theme }) => ({
//   position: 'absolute',
//   left: 0,
//   right: 0,
//   top: 0,
//   bottom: 0,
//   backgroundColor: theme.palette.common.black,
//   opacity: 0.4,
//   transition: theme.transitions.create('opacity'),
// }));

// const ImageMarked = styled('span')(({ theme }) => ({
//   height: 3,
//   width: 18,
//   backgroundColor: theme.palette.common.white,
//   position: 'absolute',
//   bottom: -2,
//   left: 'calc(50% - 9px)',
//   transition: theme.transitions.create('opacity'),
// }));

const image = {
  url: userPhoto,
  title: 'Upload',
  width: '55%',
  radius: '50%',
};
const InitProfileSettingData = {
  photoBase64: '',
  name: '',
  gender: '',
  birthday: '',
  email: '',
  password: '',
  confirmPassword: '',
  note: '',
  role: '',
  allergies: [],
  contact_name: '',
  contact_phone: []
}

dayjs.extend(utc);
dayjs.extend(timezone);

const Settings = (props) => {
  //const { wsData, setWsData } = props;
  const [wsData, setWsData] = useOutletContext();
  const ws = useWebSocket();
  const { token } = useAuth();

  const [subAccount, setSubAccount] = useState(InitProfileSettingData);
  const [file, setFile] = useState(userPhoto);
  const [nameError, setNameError] = useState(false);
  const [mailError, setMailError] = useState(false);
  const [pwError, setPwError] = useState(false);

  function handleFile(e) {
    let file = e.target.files[0]
    console.log(e.target.files);
    setFile(URL.createObjectURL(file));
    getBase64(file);
  }
  const getBase64 = (file) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    
    reader.onload = () => {
      let accountObj = cloneDeep(subAccount);
      accountObj.photoBase64 = reader.result;
      setSubAccount(accountObj)
    };
  };

  function handleError(e, column, type){
    // ...
  }

  function handleColumn(e, column, type) {
    let accountObj = cloneDeep(subAccount);

    if(type === 'input'){
      switch (column) {
        case 'addName':
          if(isNil(e.target.value) || e.target.value === '' || e.target.value.indexOf(' ') >= 0){
            setNameError(true);
          }else{
            setNameError(false);
            accountObj.name = e.target.value
          }
          break;
        case 'addEmail':
          if(isNil(e.target.value) || e.target.value === '' || e.target.value.indexOf(' ') >= 0){
            setMailError(true);
          }else{
            accountObj.email = e.target.value
            let emailRule = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/;
            if(e.target.value.search(emailRule)!== -1){
              setMailError(false);
            }else{
              setMailError(true);
            }
          }
          break;
        case 'addPassword':
        case 'confirmPassword':
          if(isNil(e.target.value) || e.target.value === '' || e.target.value.indexOf(' ') >= 0){
            setPwError(true);
          }else{
            if(column === 'addPassword'){
              accountObj.password = e.target.value
              if(e.target.value !== accountObj.confirmPassword){
                setPwError(true);
              }else{
                setPwError(false);
              }
            }else{ // confirmPassword
              accountObj.confirmPassword = e.target.value
              if(e.target.value !== accountObj.password){
                setPwError(true);
              }else{
                setPwError(false);
              }
            }
          }
          break;
        default:
          break;
      }
    }
    setSubAccount(accountObj)
  }

  const saveData = () => {
    let empty = false;
    if(subAccount.name === ''){
      setNameError(true)
      empty = true
    }
    if(subAccount.email === ''){
      setMailError(true)
      empty = true
    }
    if(subAccount.password === '' || subAccount.confirmPassword === ''){
      setPwError(true)
      empty = true
    }
    
    if(pwError || mailError || nameError || empty){
      return;
    }else{
      console.log(subAccount)
    }
  };

  return (
    <>
      <Breadcrumb path={PATH.user_setting}></Breadcrumb>
      {/* <Box sx={{ display: 'flex', width: '100%'}}>Settings content</Box> */}
      {/* <Card style={{ margin: '20px 0px', padding: '10px'}}>
        <Box>recipient list</Box>
      </Card> */}
      <Card style={{ padding: '20px'}}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Box sx={{ marginTop:'15px', display: 'flex', justifyContent: 'center', flexWrap: 'wrap', minWidth: 300, width: '100%'}}>
              <img src={file} style={{ width: '180px', height: '180px', objectFit: 'cover', borderRadius: image.radius }}/>
            {/* <ImageButton
                focusRipple
                key={image.title}
                style={{ width: image.width, borderRadius: image.radius }}
              >
              <img src={file} style={{ width: '180px', height: '180px', objectFit: 'cover', borderRadius: image.radius }}/> */}
              {/* <ImageSrc style={{ borderRadius: image.radius,backgroundImage:`url(${photoSrc})` }} /> */}
                {/* <ImageBackdrop className="MuiImageBackdrop-root" /> */}
                {/* <Image>
                  <Typography
                    component="span"
                    variant="subtitle1"
                    color="inherit"
                    sx={{
                      position: 'relative',
                      p: 4,
                      pt: 2,
                      pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                    }}
                  >
                    {image.title}
                  </Typography>
                </Image> */}
            {/* </ImageButton> */}
            </Box>
            <Box sx={{ marginTop:'15px', display: 'flex', justifyContent: 'center', flexWrap: 'wrap', minWidth: 300, width: '100%'}}>
              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                style={{
                  fontSize: '14px',
                  fontWeight: 600,
                  borderRadius: '10px',
                  backgroundColor: '#0A87A9',
                  padding: '8px 16px',
                  '&:hover': {
                    backgroundColor: '#05647E',
                  }
                }}
              >
                Upload photo
                <input hidden
                  onChange={(e)=>{handleFile(e)}}
                  type="file" accept=".png, .jpg, .jpeg"
                />
              </Button>
            </Box>
          </Grid>
          <Grid item xs={8}>
            <Box>
              <InputField
                id={`addName`}
                label="User Name"
                error={nameError}
                placeholder="User Name"
                onChange={(e) => handleColumn(e, 'addName', 'input')}
              />
              <InputField
                id={`addPassword`}
                label="Password"
                type="Password"
                error={pwError}
                placeholder="Recipient Password"
                onChange={(e) => handleColumn(e, 'addPassword', 'input')}
              />
              <InputField
                id={`confirmPassword`}
                label="Confirm Password"
                type="Password"
                error={pwError}
                placeholder="Confirm Recipient Password"
                onChange={(e) => handleColumn(e, 'confirmPassword', 'input')}
              />
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ marginTop: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Button variant="contained"
            style={{
              fontSize: '16px',
              fontWeight: 600,
              borderRadius: '10px',
              backgroundColor: '#0A87A9',
              padding: '8px 16px',
              '&:hover': {
                backgroundColor: '#05647E',
              }
            }}
          >
            <div className={'addRecipientAccount'} 
              onClick={() => saveData()}
            >
              Save settings
            </div>
          </Button>
        </Box>
      </Card>
    </>
    
  );
};

export default Settings;