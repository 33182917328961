import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { isSilentSigninRequired, SilentSignin } from "casdoor-react-sdk";
import * as Setting from "./pages/Login/Setting";
import Login from "./pages/Login/index";
import { useSelector, useDispatch } from "react-redux";
import { userActions } from "stores/slice/user";
import { useAuth } from "hook/AuthContext";

const HomePage = () => {
  const [account, setAccount] = useState(undefined);
  const [users, setUsers] = useState(null);
  const { expiresTime } = useAuth();
  const currentTime = Math.floor(new Date().getTime() / 1000);

  const navigate = useNavigate();

  const userInfo = useSelector((state) => state.user.userInfo);
  const dispatch = useDispatch();

  const styles = {
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
    },
  };

  useEffect(() => {
    if (Setting.isLoggedIn()) {
      Setting.getUserinfo().then((res) => {
        if (res?.status === "ok") {
          setAccount({
            username: res.data.displayName,
            avatar: res.data.avatar,
          });
          // redux
          dispatch(
            userActions.setUser({
              userInfo: res.data,
            })
          );
        } else {
          /* Setting.showMessage(`getUserinfo() homepage error: ${res?.msg}`); */
          localStorage.clear();
          Setting.goToLink("/");
        }
      });
    }
  }, []);

  if (Setting.isLoggedIn()) {
    //dashboard
    if (account) {
      navigate("/dashboard");
      return null;
    } else {
      return (
        <div style={styles.container}>
          <CircularProgress />
        </div>
      );
    }
  }

  if (isSilentSigninRequired()) {
    return (
      <div
        style={{ marginTop: 200, textAlign: "center", alignItems: "center" }}
      >
        <SilentSignin
          sdk={Setting.CasdoorSdk}
          isLoggedIn={Setting.isLoggedIn}
          handleReceivedSilentSigninSuccessEvent={() => Setting.goToLink("/")}
          handleReceivedSilentSigninFailureEvent={() => {}}
        />
        <p>Logging in...</p>
      </div>
    );
  }

  return (
    <div style={{ marginTop: 200 }}>
      <Login />
    </div>
  );
};

export default HomePage;
