import { useState, useEffect } from "react";
import mainStyle from "./dashboard.module.scss";
// import { tabTheme, panelTheme } from './style';

import { useWebSocket } from "hook/WebSocketContext";
import { useAuth } from "../../hook/AuthContext";
import { useOutletContext } from "react-router-dom";
import { cloneDeep, isNil } from "lodash";
import { Dimensions } from 'react-native-web';
import { useSelector, useDispatch } from "react-redux";
import { useMediaQuery, useTheme } from "@mui/material";

import ModalContainer from 'components/ModalContainer';
import ModalforCheck from 'components/ModalforCheck';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Stack from "@mui/joy/Stack";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import Card from "@mui/material/Card";
import Typography from '@mui/material/Typography';
import CardContent from "@mui/material/CardContent";
import AiBox from "./AiBox";
import RecipientCard from "./RecipientCard";
import Smart from "./Smart";
import Calendar from "./Calendar";
import settingIcon from "assets/img/icon/icn_profile_setting.svg";
import eventIcon from "assets/img/icon/icn_profile_event.svg";
// import Footer from "ui/Footer";

const Dashboard = () => {
  // if (!userView) {
  //   navigation('/login');
  // }
  const [wsData, setWsData] = useOutletContext();
  const ws = useWebSocket();
  const { token } = useAuth();

  const userInfo = useSelector((state) => state.user.userInfo);
  const [openModal, setOpenModal] = useState(false);
  const [user, setUser] = useState({});
  
  useEffect(() => {
    if(Object.keys(userInfo).length !== 0){
      if(isNil(userInfo.lastUsedHomegroupId)){
        setOpenModal(true)
      }else{
        setUser(userInfo)
      }
    }
    // console.log(userInfo)
  }, [userInfo]);

  const theme = useTheme();
  const showLandscape = useMediaQuery(theme.breakpoints.down(1440));

  // call all device
  const getBoxData = () => {
    const boxesSendData = {
      resource: `/boxes`,
      verb: "read",
      accessToken: token,
    };
    ws.send(JSON.stringify(boxesSendData));
  };

  useEffect(() => {
    getBoxData();
  }, []);


  return (
    <>
      <Box>
        <Grid container >
          <Grid sx={{p:1}} item style={{ width: !showLandscape? '65%': '100%', marginBottom: '25px'}}>
            <Stack direction="column" justifyContent="center" spacing={2}>
              <RecipientCard
                wsData={wsData}
                setWsData={setWsData}
              ></RecipientCard>
              <Smart wsData={wsData} setWsData={setWsData}></Smart>
            </Stack>
          </Grid>
          <Grid sx={{p:1}} item style={{ width: !showLandscape? '35%': '100%', marginBottom: '25px'}}>
            <Stack direction="column" justifyContent="center" spacing={2}>
              <AiBox wsData={wsData} setWsData={setWsData}></AiBox>
              <Calendar></Calendar>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Dashboard;
