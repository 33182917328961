export const selectDate = [
  {
    id: 0,
    content: "All",
  },
  {
    id: 1,
    content: "Health tracking",
  },
  {
    id: 2,
    content: "Habit/Med. tracking",
  },
  {
    id: 3,
    content: "Violence detection",
  },
  {
    id: 4,
    content: "Fall detection & Prevention",
  },
];

export const categoryMapping = {
  All: "reportAbnormal, notTakeBloodPressure, notTakeMedicine, abuse, falldown, sleepingSickness",
  "Health tracking": "reportAbnormal,notTakeBloodPressure",
  "Habit/Med. tracking": "notTakeMedicine",
  "Violence detection": "abuse",
  "Fall detection & Prevention": "falldown, sleepingSickness",
};
