import React, { useState, useEffect } from "react";
import calendarStyle from "./calendar.module.scss";

import { useOutletContext } from "react-router-dom";
import { cloneDeep } from "lodash";
import dayjs from 'dayjs';

import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import CardContent from "@mui/material/CardContent";

import OpenInNew from "assets/img/icon/btn_external_expand.svg";
import { ReactComponent as ArrowLeftIcon } from "assets/img/icon/btn_calendar_arrow_left.svg";
import { ReactComponent as ArrowRightIcon } from "assets/img/icon/btn_calendar_arrow_right.svg";
import Father from "assets/img/elderMaleFemale/GettyImages-916097436.png";

import { MONTHS, DAYS_NAMES, TIMES_LIST, TIMING } from "./const";

const Loading = () => (
  <Stack spacing={1}>
    <Skeleton variant="text" sx={{ fontSize: '5rem' }} />
    <Box sx={{ display: 'flex', flexWrap: 'nowrap', justifyContent: 'space-around', alignItems: 'center' }}>
      {Array.from({ length: 7 }).map((_, index) => (
        <Skeleton key={index} variant="circular" width={50} height={50} />
      ))}
    </Box>
    <Skeleton variant="rounded" width="100%" height={597} />
  </Stack>
);

const CalendarList = ({ startDate, setStartDate, selectedDate, setSelectedDate, originalScheduleData, setCurrentScheduleData }) => {
  const year = startDate.getFullYear().toString();
  const calendarTitle = `${year} ${MONTHS[startDate.getMonth()]}`;

  // 根據輸入的週數 amount，更新起始日期 startDate，並將其設定為原始起始日期加上指定週數後的日期
  function changeWeeks(amount) {
    const newStartDate = new Date(startDate);
    newStartDate.setDate(newStartDate.getDate() + amount * 7);
    setStartDate(newStartDate);
  }

  // 更新目前選擇的日期
  function updateSelectedDate(newSelectedDate) {
    const findCurrentScheduleData = originalScheduleData.filter((item) => (item.period.includes(newSelectedDate)));
    setSelectedDate(newSelectedDate);
    setCurrentScheduleData(findCurrentScheduleData)
  }

  // 渲染一個七天日期的日曆
  function renderCalendarDays() {
    const renderDayList = [];
    let currentDay = new Date(startDate);

    for (let i = 0; i < 7; i++) {
      const dateString = currentDay.toDateString();
      const isSelectedDate = selectedDate === dateString;
      const containerClassName = isSelectedDate
        ? calendarStyle.selectedDayContainer
        : calendarStyle.daysContainer;
      const nameClassName = isSelectedDate
        ? calendarStyle.selectedDayName
        : calendarStyle.daysName;

      const dayName = DAYS_NAMES[currentDay.getDay()];
      const date = currentDay.getDate().toString();

      renderDayList.push(
        <div
          className={containerClassName}
          key={i}
          onClick={() => updateSelectedDate(dateString)}
        >
          <div className={nameClassName}>{dayName}</div>
          <div className={calendarStyle.daysCircle}>{date}</div>
        </div>
      );

      currentDay.setDate(currentDay.getDate() + 1);
    }

    return renderDayList;
  }

  return (
    <div>
      <div className={calendarStyle.headerContainer}>
        <span className={calendarStyle.arrowLeft} onClick={() => changeWeeks(-1)}>
          <ArrowLeftIcon />
        </span>
        <span className={calendarStyle.title}>{calendarTitle}</span>
        <span className={calendarStyle.arrowRight} onClick={() => changeWeeks(+1)}>
          <ArrowRightIcon />
        </span>
      </div>
      <div className={calendarStyle.calendarDaysContainer}>
        {renderCalendarDays()}
      </div>
    </div>
  );
};

const SchedulerList = ({ currentScheduleData }) => {
  // 整理時間表
  const matchingDataMap = TIMES_LIST.reduce((acc, time) => {
    const matchingData = currentScheduleData.filter((data) => data.dailyJobAtTimes.includes(time));
    acc[time] = matchingData;
    return acc;
  }, {});

  // 渲染時間表
  const tableRows = TIMES_LIST.map((time) => {
    const matchingData = matchingDataMap[time];
    const hasMatchingData = matchingData && matchingData.length > 0;

    return (
      <tr key={time}>
        <th style={{ whiteSpace: "nowrap", textAlign: "left", position: "relative", top: "-14px" }}>{time}</th>
        {hasMatchingData ? (
          matchingData.map((data, dataIndex) => (
            <td
              key={`${time}-${dataIndex}`}
              colSpan={
                matchingData.length === 1 ? 16 : 16 / matchingData.length
              }
              rowSpan={data.duration}
              className={calendarStyle[`stage-${data.color}`]}
            >
              <div>
                <div>
                  {data.username}
                  <span>{data.detail}</span>
                </div>
                <span>
                  <img className={calendarStyle.profileImage} src={data.picture} />
                </span>
              </div>
            </td>
          ))
        ) : (
          <td colSpan="16"></td>
        )}
      </tr>
    );
  });

  return (
    <div className={calendarStyle.tableContainer}>
      <table>
        <tbody>{tableRows}</tbody>
      </table>
    </div>
  );
};

const Calendar = () => {
  const [wsData, setWsData] = useOutletContext();

  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date().toDateString());
  const [originalScheduleData, setOriginalScheduleData] = useState([]);
  const [currentScheduleData, setCurrentScheduleData] = useState([]);

  // 取得當週星期一的日期
  function getFirstDateOfWeek(date) {
    const today = date.getDay() || 7;
    return new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() + 1 - today
    );
  }
  function convertTo12HourFormat(data) {
    const timesSet = new Set();

    data.forEach(time => {
      let [hours, minutes, seconds] = time.split(':').map(Number);
      const period = hours >= 12 ? 'pm' : 'am';

      minutes = minutes >= 30 ? 30 : 0;

      hours = hours % 12;
      hours = hours === 0 && period === 'am' ? 0 : (hours || 12);

      const formattedTime = `${hours}:${minutes.toString().padStart(2, '0')} ${period}`;

      timesSet.add(formattedTime);
    });

    return Array.from(timesSet);
  }

  function listDatesInRange(startDate, endDate) {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const dates = [];

    while (start <= end) {
      dates.push(new Date(start).toDateString());
      start.setDate(start.getDate() + 1);
    }

    return dates;
  }

  useEffect(() => {
    const currentFirstDateOfWeek = getFirstDateOfWeek(startDate);
    setStartDate(currentFirstDateOfWeek);
  }, []);

  useEffect(() => {
    if (wsData.updateTag === "allCron" && wsData.updateData) {
      const allCronData = cloneDeep(wsData);
      const readData = allCronData.updateData;
      const scheduleReadData = readData.filter(item => item.enabled === true);
      const formatScheduleData = scheduleReadData.map((item) => ({
        id: item.id,
        color: "green",
        username: item.username,
        picture: Father,
        detail: `${item.name}${item.timing ? `, ${TIMING[item.timing]}` : ""}`,
        period: listDatesInRange(dayjs.unix(item.startTime).format('YYYY/M/D'), dayjs.unix(item.endTime).format('YYYY/M/D')),
        dailyJobAtTimes: convertTo12HourFormat(item.dailyJobAtTimes),
        duration: "1",
      }))
      setOriginalScheduleData(formatScheduleData);
      const findCurrentScheduleData = formatScheduleData.filter((item) => (item.period.includes(selectedDate)))
      setCurrentScheduleData(findCurrentScheduleData);
      setLoading(false);
    }
  }, [wsData])

  const content = (
    <CardContent>
      {
        loading ? <Loading />
          : <>
            <IconButton
              style={{ position: "absolute", top: 8, right: 12 }}
              disableRipple
              sx={{
                "&:hover": {
                  cursor: "not-allowed",
                },
              }}
            >
              <img src={OpenInNew} alt="openInNew" width={"25px"} />
            </IconButton>
            <CalendarList
              startDate={startDate}
              setStartDate={setStartDate}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              originalScheduleData={originalScheduleData}
              setCurrentScheduleData={setCurrentScheduleData}
            />
            <SchedulerList currentScheduleData={currentScheduleData} />
          </>
      }
    </CardContent>
  );

  return (
    <Card
      style={{
        position: "relative",
        borderRadius: "20px",
      }}
      variant="outlined"
    >
      {content}
    </Card>
  );
};

export default Calendar;
