import React from 'react';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';

import FieldContainer from "./FieldContainer";

const TypographyField = ({ label, text, textColor }) => (
    <FieldContainer label={label}>
        <Box sx={{ minWidth: 180 }}>
            <FormControl fullWidth>
                <Typography display="block" color={textColor}>
                    {text}
                </Typography>
            </FormControl>
        </Box>
    </FieldContainer>
);

export default TypographyField;