import React, { useState, useEffect } from "react";
import healthStyle from "../health.module.scss";

import { Dimensions } from 'react-native-web';
import Carousel from 'react-spring-3d-carousel';
import { config, useSpring, animated } from "react-spring";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from '@mui/material/Typography';
import testPhoto1 from "assets/img/elderMaleFemale/GettyImages-109839766.png";
import testPhoto2 from "assets/img/elderMaleFemale/GettyImages-916097436.png";
import testPhoto3 from "assets/img/elderMaleFemale/GettyImages-1145459769.png";
import testPhoto4 from "assets/img/elderMaleFemale/GettyImages-1190895679.png";
import testPhoto5 from "assets/img/elderMaleFemale/GettyImages-1181654105.png";

const MobileVer = (prop) => {
  const user = [
    // {
    //   key: 0,
    //   content: <></>
    // },
    {
      key: 0,
      content: <img src={testPhoto1} className={healthStyle['mobilephoto']} onClick={() => setGoToSlide(0)} role="presentation" />
    },
    {
      key: 1,
      content: <img src={testPhoto2} className={healthStyle['mobilephoto']} onClick={() => setGoToSlide(1)} role="presentation" />
    },
    {
      key: 2,
      content: <img src={testPhoto3} className={healthStyle['mobilephoto']} onClick={() => setGoToSlide(2)} role="presentation" />
    },
    {
      key: 3,
      content: <img src={testPhoto4} className={healthStyle['mobilephoto']} onClick={() => setGoToSlide(3)} role="presentation" />
    },
    {
      key: 4,
      content: <img src={testPhoto5} className={healthStyle['mobilephoto']} onClick={() => setGoToSlide(4)} role="presentation" />
    }
  ];

  const detail = [
    {
      key: 0,
      content: '00 - Healthcare Mother content'
    },
    {
      key: 1,
      content: '01 - Healthcare Father content'
    },
    {
      key: 2,
      content: '02 - Healthcare Grandmother content'
    },
    {
      key: 3,
      content: '03 - Healthcare Uncel content'
    },
    {
      key: 4,
      content: '04 - Healthcare Grandfather content'
    }
  ]
  
  const content = prop.userData;

  const windowHeightContent = Dimensions.get('window').height-220;

  const [offsetRadius, setOffsetRadius] = useState(2);
  const [showArrows, setShowArrows] = useState(false);
  const [goToSlide, setGoToSlide] = useState(0);
  const [slides, setSlides] = useState(user);

  return (
    <>
      <div style={{height:"100px"}}>
        <Carousel
          slides={slides} 
          goToSlide={goToSlide}
          // goToSlideDelay={200}
          offsetRadius={offsetRadius}
          showNavigation={showArrows}
          animationConfig={config.gentle}
          />
        </div>
      <Card variant="outlined"
        style={{ minHeight: windowHeightContent+'px', marginTop: '20px',
        border: '1px solid rgba(0, 0, 0, 0.12)', borderRadius: "20px", backgroundColor: '#fff', }}>
        <CardContent>
          {detail.filter(item => item.key === goToSlide).map((element, index) => (
            <Typography sx={{ fontSize: 14 }} key={index} color="text.secondary" gutterBottom>
              {element.content}
            </Typography>
            ))
          }
        </CardContent>
      </Card>
    </>
  );
};

export default MobileVer;
