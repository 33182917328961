export const breadRoot = {
  marginLeft: '10px',
  color: '#737791',
  fontSize: '16px'
}
export const breadTarget = {
  marginLeft: '10px',
  color: '#424962',
  fontWeight: 'bold',
  fontSize: '16px'
}
export const breadLink = {
  marginLeft: '10px',
  color: '#737791',
  cursor: 'pointer',
  fontSize: '16px'
}