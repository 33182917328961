
// import "./spinner.ui.css";
// import iconLoading from "assets/img/v2/icon/icon_loading.gif";
import { useEffect, useState, memo } from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const Spinner = (props) => {
  const {
    open, setOpen,
  } = props;

  // const handleClose = () => {
  //   setOpen(false);
  // };

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};
export default memo(Spinner);