import summaryStyle from './summary.module.scss';
import { tabTheme, panelTheme } from './style';
import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import { cloneDeep, isNil } from 'lodash';
import { useWebSocket } from "hook/WebSocketContext";
import { useAuth } from "../../hook/AuthContext";
import { PATH } from "router/config/const";
import Breadcrumb from 'components/Breadcrumb';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from "@mui/material/Divider";
import TabList from '@mui/lab/TabList';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import Typography from '@mui/material/Typography';

import setting from "assets/img/icon/icn_profile_setting.svg";
import event from "assets/img/icon/icn_profile_event.svg";
import ChartSummary from "./components/ChartSummary";
import TimeLine from "./components/TimeLine";
import History from "./components/History";
import UserCard from "./components/UserCard";
// import Recipient from "./components/RecipientSidebar";
import { useMediaQuery, useTheme } from "@mui/material";
import { useNotification } from "hook/NotificationContext";
import controller from './controller';
// Controller
// import controller from './controller';

const Summary = (props) => {
  const { data } = props;
  const [wsData, setWsData] = useOutletContext();
  const ws = useWebSocket();
  const { token } = useAuth();


  const userInfo = useSelector((state) => state.user.userInfo);
  const [openModal, setOpenModal] = useState(false);
  const [user, setUser] = useState({});
  
  useEffect(() => {
    if(Object.keys(userInfo).length !== 0){
      setUser(userInfo)
      
      getWsData();

      return () => {
        localStorage.removeItem('SUMMARY_CHART');
        localStorage.removeItem('SUMMARY_DETAIL');
      };
    }
    // console.log(userInfo)
  }, [userInfo]);



  const [tabValue, setTabValue] = useState('1');

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(1280));
  const isLargeScreen = useMediaQuery(theme.breakpoints.down(1824));

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const getWsData = async () => {
    let endStamp = parseInt(dayjs().format('X'));
    let tmp_stampRange = endStamp - 86400*7;
    let tmp_startDate = controller.getDateYMD(tmp_stampRange*1000)
    let startStamp = controller.getDate0000(tmp_startDate)
    let averageRange = ['weeklyAverage','reports'];

    const chartSendData = {
      resource: `/users/0/reports`,
      verb: "read",
      accessToken: token,
      data: {
        filter: {
          startTime: startStamp,
          endTime: endStamp,
          homeGroupId: userInfo.lastUsedHomegroupId, //4
        },
        projection: averageRange
      },
    };

    ws.send(JSON.stringify(chartSendData));

  }

  // useEffect(()=> {
  //   getWsData();

  //   return () => {
  //     localStorage.removeItem('SUMMARY_CHART');
  //     localStorage.removeItem('SUMMARY_DETAIL');
  //   };
  // }, [])


  return (
    <>
      <Breadcrumb path={PATH.summary}></Breadcrumb>
      <Box sx={{ display: 'flex', width: '100%'}}>
      <Grid container style={{paddingTop: '10px', borderRadius: '10px'}}>
        <Grid item style={{width: (isSmallScreen?'100%':'300px')}}>
        {/* <Grid item xs={isSmallScreen? 12: isLargeScreen? 3:2}> */}
        {/* <Grid item xs={isSmallScreen? 12:3}> */}
        <Grid container style={{paddingRight: '25px'}}>
          <UserCard/>
        </Grid>
        </Grid>
        <Grid item style={{width: (isSmallScreen?'100%':'calc(100% - 300px)') }}>
        {/* <Grid item xs={isSmallScreen? 12: isLargeScreen? 9:10}> */}
        {/* <Grid item xs={isSmallScreen? 12:9}> */}
          <TabContext value={tabValue}>
            <Box sx={{ p: 0 }} variant="outlined"
              style={{ display: "flex", justifyContent: "space-between", width: "100%", }}>
              <Tabs value={tabValue} style={{minWidth:"50%"}}
                onChange={handleChange} aria-label="lab API tabs example"
                TabIndicatorProps={{ sx: { backgroundColor: "white", height: '1px'} }}
                sx={{
                    '& button': {fontSize: "16px", backgroundColor: '#EEF0F2', color: '#737791'},
                    '& button: active': {backgroundColor: '#FFFFFF', color: '#0087A8'},
                    '& button: focus': {backgroundColor: '#FFFFFF', color: '#0087A8'},
                    '& button: blur': {backgroundColor: '#FFFFFF', color: '#0087A8', },
                    '& .MuiButtonBase-root': {
                      '&.Mui-selected': { fontSize: "18px", fontWeight: 600, border: '1px solid ', borderColor: '#EBEBEB',borderRadius: 1, backgroundColor: '#FFFFFF', color: '#0087A8'},
                    },
                }}
              >
                <Tab label="健康資訊" value="1" style={{textTransform: "none", height:'60px', borderRadius: '20px 20px 0 0', minWidth:"45%"}}/>
                {/* <Tab label="History" value="2" style={{textTransform: "none", height:'60px', borderRadius: '0 20px 0 0', minWidth:"45%"}}/> */}
              </Tabs>
              <Box style={{minWidth:"20%"}}>
                <Grid container justifyContent="end">
                  <div className={summaryStyle['profileIcon']} style={{minWidth: '50px'}}>
                    <img src={event}/>
                  </div>
                  <div className={summaryStyle['profileIcon']} style={{minWidth: '50px'}}>
                    <img src={setting}/>
                  </div>
                </Grid>
              </Box>
            </Box>
            <TabPanel value="1" style={panelTheme}>
              <Box sx={{ p: 1 }} variant="outlined">
                <ChartSummary wsData={wsData} setWsData={setWsData} user={user}/>
              </Box>
              <Box sx={{ p: 1 }} variant="outlined" style={{marginTop: '15px'}}>
                <TimeLine wsData={wsData} setWsData={setWsData} user={user}/>
              </Box>
            </TabPanel>
            <TabPanel value="2" style={panelTheme}>
              <Box sx={{ p: 1 }} variant="outlined">
                <History wsData={wsData} setWsData={setWsData}/>
              </Box>
            </TabPanel>
          </TabContext>
        </Grid>
      </Grid>
      </Box>
    </>
  );
};

export default Summary;