import dayjs from "dayjs";

export const DetailSummaryData =[
  {
    gId: 0,
    avgSBP: 111,
    avgDBP: 76,
    avgPULSE: 92,
    year: '2024',
    month: '05', //dayjs().format('MM'),
    day: '28', //dayjs().format('DD'),
    hour: '0', //dayjs().format('HH'),
    dateStamp: 1716825600, //dayjs('2024-05-28').format('X'),
    showGroupInfo: true,
    showFullInfo: true,
    fullContent: [
      {
        timestamp: 1716900635,
        SBP: 120,
        DBP: 75,
        PULSE: 101,
        noteSBP: 'normal',
        noteDBP: 'normal',
        notePULSE: 'high',
        hasNote: true
      },
      {
        timestamp: 1716884435,
        SBP: 99,
        DBP: 80,
        PULSE: 100,
        noteSBP: 'normal',
        noteDBP: 'normal',
        notePULSE: 'normal',
        hasNote: false
      },
      {
        timestamp: 1716872435,
        SBP: 95,
        DBP: 75,
        PULSE: 81,
        noteSBP: 'normal',
        noteDBP: 'normal',
        notePULSE: 'normal',
        hasNote: false
      },
      {
        timestamp: 1716856235,
        SBP: 130,
        DBP: 80,
        PULSE: 87,
        noteSBP: 'normal',
        noteDBP: 'normal',
        notePULSE: 'normal',
        hasNote: false
      }
    ]
  },
  {
    gId: 1,
    avgSBP: 120,
    avgDBP: 80,
    avgPULSE: 80,
    year: dayjs().format('YYYY'),
    month: '05', //dayjs().format('MM'),
    day: '29', //dayjs().format('DD'),
    hour: '0', //dayjs().format('HH'),
    dateStamp: 1716912000, //dayjs('2024-05-29').format('X'),
    showGroupInfo: true,
    showFullInfo: false,
    fullContent: [
      {
        timestamp: 1716993025,
        SBP: 120,
        DBP: 80,
        PULSE: 80,
        noteSBP: 'normal',
        noteDBP: 'normal',
        notePULSE: 'normal',
        hasNote: false
      },
    ]
  },
]
 
export const ChartSummaryData =[
  {
    id: 0,
    year: dayjs().format('YYYY'),
    month: '05', //dayjs().format('MM'),
    day: '30', //dayjs().format('DD'),
    hour: '0', //dayjs().format('HH'),
    week: dayjs('2024-05-30').format('ddd'),
    dateStamp: 1716998400, //dayjs('2024-05-30').format('X'),
    label: '05/30',
    SBP: 120,
    DBP: 80,
    PULSE: 90,
    // numberOfDay: 1,
    // numberOfMonth: 1
  },
  {
    id: 1,
    year: dayjs().format('YYYY'),
    month: '05', //dayjs().format('MM'),
    day: '31', //dayjs().format('DD'),
    hour: '0', //dayjs().format('HH'),
    week: dayjs('2024-05-31').format('ddd'),
    dateStamp: 1717084800, //dayjs('2024-05-31').format('X'),
    label: '05/31',
    SBP: 115,
    DBP: 70,
    PULSE: 85,
    // numberOfDay: 1,
    // numberOfMonth: 1
  },
  {
    id: 2,
    year: dayjs().format('YYYY'),
    month: '06', //dayjs().format('MM'),
    day: '01', //dayjs().format('DD'),
    hour: '0', //dayjs().format('HH'),
    week: dayjs('2024-06-01').format('ddd'),
    dateStamp: 1717171200, //dayjs('2024-06-01').format('X'),
    label: '06/01',
    SBP: 110,
    DBP: 65,
    PULSE: 80,
    // numberOfDay: 1,
    // numberOfMonth: 1
  },
  {
    id: 3,
    year: dayjs().format('YYYY'),
    month: '06', //dayjs().format('MM'),
    day: '02', //dayjs().format('DD'),
    hour: '0', //dayjs().format('HH'),
    week: dayjs('2024-06-02').format('ddd'),
    dateStamp: 1717257600, //dayjs('2024-06-02').format('X'),
    label: '06/02',
    SBP: 130,
    DBP: 80,
    PULSE: 87,
    // numberOfDay: 1,
    // numberOfMonth: 1
  },
  {
    id: 4,
    year: dayjs().format('YYYY'),
    month: '06', //dayjs().format('MM'),
    day: '03', //dayjs().format('DD'),
    hour: '0', //dayjs().format('HH'),
    week: dayjs('2024-06-03').format('ddd'),
    dateStamp: 1717344000, //dayjs('2024-06-03').format('X'),
    label: '06/03',
    SBP: 125,
    DBP: 75,
    PULSE: 81,
    // numberOfDay: 1,
    // numberOfMonth: 1
  },
  {
    id: 5,
    year: dayjs().format('YYYY'),
    month: '06', //dayjs().format('MM'),
    day: '04', //dayjs().format('DD'),
    hour: '0', //dayjs().format('HH'),
    week: dayjs('2024-06-04').format('ddd'),
    dateStamp: 1717430400, //dayjs('2024-06-04').format('X'),
    label: '06/04',
    SBP: 135,
    DBP: 85,
    PULSE: 99,
    // numberOfDay: 1,
    // numberOfMonth: 1
  },
  {
    id: 6,
    year: dayjs().format('YYYY'),
    month: '06', //dayjs().format('MM'),
    day: '05', //dayjs().format('DD'),
    hour: '0', //dayjs().format('HH'),
    week: dayjs('2024-06-05').format('ddd'),
    dateStamp: 1717516800, //dayjs('2024-06-05').format('X'),
    label: '06/05',
    SBP: 120,
    DBP: 75,
    PULSE: 88,
    // numberOfDay: 1,
    // numberOfMonth: 1
  }
]