import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// Service
// import AuthService from "../../services/Auth.service";
// import AuthUserService from "../../services/Auth.User.service";

const initialState = {
  // token: null,
  // tokenAliveTime: null,
  // recentLogins: null,
  userInfo: {},
};

// userInfo: {
//   CasdoorId: '',
//   CreatedAt: '',
//   Crons: '',
//   DeletedAt: '',
//   Email: '',
//   Id: '',
//   Medicines: '',
//   Name: '',
//   Reports: '',
//   Trait: {
//     Allergies: '',
//     Birthday: '',
//     Contacts: '',
//     CreatedAt: '',
//     DeletedAt: '',
//     Gender: '',
//     Id: '',
//     Note: '',
//     Portrait: '',
//     UpdatedAt: '',
//     UserId: '',
//   },
//   UpdatedAt: '',
//   isHomeGroupBound: '',
// },

// Local storeage
export const fetchUser = createAsyncThunk(
  "counter/fetchUser",
  async () => {
    // ...
  }
);

// Create slice
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    // clean(state) {
    //   state.token = null;
    //   state.tokenAliveTime = null;
    //   state.user = null;
    // },
    setUser: (state, action) => {
      state.userInfo = action.payload.userInfo;
    }
  },
  extraReducers: (builder) => {
    // fulfilled, pending, rejected
    builder.addCase(fetchUser.fulfilled, (state, action) => {
      state.userInfo = action.payload.userInfo;
    })
  },
});

// Actions & Reducer
// export const { increment, decrement } = counterSlice.actions;
export const userActions = userSlice.actions;
export default userSlice;
