import mainStyle from "../dashboard.module.scss";
import smartStyle from "./smart.module.scss";
import React, { useEffect, useState, useRef } from "react";
import { useWebSocket } from "hook/WebSocketContext";
import { useAuth } from "../../../hook/AuthContext";
import { cloneDeep } from "lodash";
import ReconnectingWebSocket from "reconnecting-websocket";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Skeleton from "@mui/material/Skeleton";
// images
import { ReactComponent as Removed } from "assets/img/icon/icn_cell_removed.svg";
import { ReactComponent as Light } from "assets/img/icon/icn_light_bulb.svg";
import { ReactComponent as Plug } from "assets/img/icon/icn_plug.svg";
import { ReactComponent as ErrorLight } from "assets/img/icon/icn_light_Bulb_error.svg";
import { ReactComponent as ErrorPlug } from "assets/img/icon/icn_plug_error.svg";
// components
import SmartCard from "./components/SmartCard";
import { smartLightData, smartPlugData } from "./const";
import { renderSkeletonListItems } from "./components/SkeletonItem";
import { CustomSwitch } from "./components/SwitchIcon";

const Smart = (props) => {
  const { wsData, setWsData } = props;
  const ws = useWebSocket();
  const { token } = useAuth();
  const [itemLoading, setItemLoading] = useState({});
  const [lightsData, setLightsData] = useState([]);
  const [plugsData, setPlugsData] = useState([]);

  // Update light states
  const updateLightState = (id, glowing) => {
    console.log(`Updating light ${id} to glowing: ${glowing}`);
    setLightsData((prevLights) =>
      prevLights.map((light) =>
        light.id === id ? { ...light, glowing: glowing } : light
      )
    );
    setItemLoading((prevLoading) => ({ ...prevLoading, [id]: false }));
  };

  // Update plug states
  const updatePlugState = (id, powering) => {
    console.log(`Updating plug ${id} to powering: ${powering}`);
    setPlugsData((prevPlugs) =>
      prevPlugs.map((plug) =>
        plug.id === id ? { ...plug, powering: powering } : plug
      )
    );
    setItemLoading((prevLoading) => ({ ...prevLoading, [id]: false }));
  };

  // Control lights
  const handleLightToggle = (id) => {
    const light = lightsData.find((light) => light.id === id);
    if (!light) return;

    const newState = !light.glowing;
    /*     updateLightState(id, newState); */

    const lightsSendData = {
      resource: `/boxes/0/lights/${id}`,
      verb: "update",
      accessToken: token,
      data: {
        name: light.name.trim(),
        glowing: newState,
      },
    };

    ws.send(JSON.stringify(lightsSendData));
    setItemLoading((prevLoading) => ({ ...prevLoading, [id]: true }));
  };

  // Control plugs
  const handlePlugToggle = (id) => {
    const plug = plugsData.find((plug) => plug.id === id);
    if (!plug) return;

    const newState = !plug.powering;
    /*  updatePlugState(id, newState); */

    const plugsSendData = {
      resource: `/boxes/0/plugs/${id}`,
      verb: "update",
      accessToken: token,
      data: {
        name: plug.name.trim(),
        powering: newState,
      },
    };
    ws.send(JSON.stringify(plugsSendData));
    setItemLoading((prevLoading) => ({ ...prevLoading, [id]: true }));
  };

  useEffect(() => {
    if (wsData.updateTag === "light_update" && wsData.updateData) {
      const lightId = parseInt(wsData.updateData.resource.split("/").pop());
      updateLightState(lightId, wsData.updateData.data.glowing);
    } else if (wsData.updateTag === "plug_update" && wsData.updateData) {
      console.log("plug update ws");
      const plugId = parseInt(wsData.updateData.resource.split("/").pop());
      updatePlugState(plugId, wsData.updateData.data.powering);
    }

    //fake + backend
    else if (wsData.updateTag === "box_read" && wsData.updateData) {
      console.log("Initializing data from lights");
      const initLightData = wsData.updateData;
      if (initLightData && initLightData.length > 0) {
        const { lights, plugs } = initLightData[0];
        const combinedLightsData = [
          ...lights,
          ...smartLightData.filter(
            (fakeLight) => !lights.some((light) => light.id === fakeLight.id)
          ),
        ];
        combinedLightsData.sort((a, b) => a.id - b.id);

        const combinedPlugsData = [
          ...plugs,
          ...smartPlugData.filter(
            (fakePlug) => !plugs.some((plug) => plug.id === fakePlug.id)
          ),
        ];
        combinedPlugsData.sort((a, b) => a.id - b.id);

        setLightsData(combinedLightsData);
        setPlugsData(combinedPlugsData);
      }
    }
    // backend
    /*    else if (wsData.updateTag === "box_read" && wsData.updateData) {
      console.log("Initializing data from lights");
      const initLightData = wsData.updateData;
      if (initLightData && initLightData.length > 0) {
        const { lights, plugs } = initLightData[0];
        const sortLights = lights.sort((a, b) => a.id - b.id);
        const sortPlugs = plugs.sort((a, b) => a.id - b.id);
        setLightsData(sortLights);

        setPlugsData(sortPlugs);
      }
    } */
  }, [wsData]);

  return (
    <>
      <Grid container>
        {/* smart light */}
        <Grid item xs={6} style={{ paddingRight: "6px" }}>
          <SmartCard title="Smart Light">
            <List className={smartStyle.listContainer}>
              {lightsData.length === 0
                ? renderSkeletonListItems(3)
                : lightsData.map((item, index) => (
                    <div key={item.id}>
                      <ListItem
                        style={{ marginTop: "10px", marginBottom: "10px" }}
                      >
                        <ListItemIcon>
                          <Light />
                        </ListItemIcon>
                        <ListItemText
                          id={item.name.trim()}
                          primary={item.name.trim()}
                          primaryTypographyProps={{
                            style: {
                              color: "#007da3",
                              fontWeight: "normal",
                              fontSize: "16px",
                            },
                          }}
                        />
                        {itemLoading[item.id] ? (
                          <Skeleton
                            variant="rectangular"
                            width={40}
                            height={24}
                          />
                        ) : (
                          <CustomSwitch
                            checked={item.glowing}
                            onChange={() => handleLightToggle(item.id)}
                            disabled={index >= 3}
                          />
                        )}
                      </ListItem>
                      {index < lightsData.length - 1 && <Divider />}
                    </div>
                  ))}
            </List>
          </SmartCard>
        </Grid>

        {/* smart plug */}
        <Grid item xs={6} style={{ paddingLeft: "6px" }}>
          <SmartCard title="Smart Plug">
            <List className={smartStyle.listContainer}>
              {plugsData.length === 0
                ? renderSkeletonListItems(3)
                : plugsData.map((item, index) => (
                    <div key={item.id}>
                      <ListItem
                        style={{ marginTop: "10px", marginBottom: "10px" }}
                      >
                        <ListItemIcon>
                          <Plug />
                        </ListItemIcon>
                        <ListItemText
                          id={item.name.trim()}
                          primary={item.name.trim()}
                          primaryTypographyProps={{
                            style: {
                              color: "#007da3",
                              fontWeight: "500",
                              fontSize: "16px",
                            },
                          }}
                        />
                        {itemLoading[item.id] ? (
                          <Skeleton
                            variant="rectangular"
                            width={40}
                            height={24}
                          />
                        ) : (
                          <CustomSwitch
                            checked={item.powering}
                            onChange={() => handlePlugToggle(item.id)}
                            disabled={index >= 1}
                          />
                        )}
                      </ListItem>
                      {index < plugsData.length - 1 && <Divider />}
                    </div>
                  ))}
            </List>
          </SmartCard>
        </Grid>
      </Grid>
    </>
  );
};

export default Smart;
