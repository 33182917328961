import mainStyle from "../dashboard.module.scss";
import recipientStyle from "./recipient.module.scss";
import dayjs from "dayjs";
import { cloneDeep, isNil } from "lodash";
import { tabTheme, newOpenTheme, panelTheme } from "./style";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useWebSocket } from "hook/WebSocketContext";
import { useAuth } from "../../../hook/AuthContext";
import Box from "@mui/material/Box";
// import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
// import TabList from '@mui/lab/TabList';
import TabPanel from "@mui/lab/TabPanel";
import newOpen from "assets/img/icon/btn_external_expand.svg";
import Grid from "@mui/material/Grid";
import Carousel from "./components/Carousel";
import UserCard from "./components/UserCard";
import ChartSummary from "./components/ChartSummary";
import TodayList from "./components/TodayList";
import wellNote from "assets/img/icon/icn_profile_excellent.svg";
import errorNote from "assets/img/icon/icn_profile_error.svg";
import userPhoto1 from "assets/img/elderMaleFemale/GettyImages-916097436.png";
import userPhoto2 from "assets/img/elderMaleFemale/GettyImages-109839766.png";

const userData = [
  {
    id: 0,
    identity: "Father",
    age: "65",
    gender: "Male",
    content: "Recipient card 1",
    note: true,
    noteSrc: errorNote,
    photoSrc: userPhoto1,
  },
  {
    id: 1,
    identity: "Mother",
    age: "60",
    gender: "Female",
    content: "Recipient card 2",
    note: false,
    noteSrc: wellNote,
    photoSrc: userPhoto2,
  },
];

const RecipientCard = (props) => {
  const { wsData, setWsData } = props;
  const ws = useWebSocket();
  const { token } = useAuth();

  const [currentRcPage, setCurrentRcPage] = useState(0);
  const [currentLrPage, setCurrentLrPage] = useState(0);

  const [tabValue, setTabValue] = useState("1");
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const navigate = useNavigate();
  const handleClick = (page) => {
    let path = "/" + page;
    navigate(path);
  };

  const getChartData = () => {
    let endStamp = parseInt(dayjs().format("X"));
    let startStamp = endStamp - 86400 * 7;
    let averageRange = ["weeklyAverage"];
    const chartSendData = {
      resource: `/users/0/reports`,
      verb: "read",
      accessToken: token,
      data: {
        filter: {
          startTime: startStamp,
          endTime: endStamp,
        },
        projection: averageRange,
      },
    };
    ws.send(JSON.stringify(chartSendData));
  };

  const getCronData = () => {
    const requestAllCron = {
      resource: "/users/0/crons",
      verb: "read",
      accessToken: token,
    };
    ws.send(JSON.stringify(requestAllCron));
  };

  useEffect(() => {
    const timeoutChart = setTimeout(() => {
      getChartData();
    }, 550);
    const timeoutCron = setTimeout(() => {
      getCronData();
    }, 850);

    return () => {
      clearTimeout(timeoutChart);
      clearTimeout(timeoutCron);
    };
  }, []);

  return (
    <>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={tabValue}>
          <Tabs
            value={tabValue}
            style={tabTheme}
            onChange={handleChange}
            aria-label="lab API tabs example"
            TabIndicatorProps={{
              sx: { backgroundColor: "white", height: "1px" },
            }}
            sx={{
              "& button": {
                fontSize: "16px",
                backgroundColor: "#EEF0F2",
                color: "#737791",
              },
              "& button: active": {
                backgroundColor: "#FFFFFF",
                color: "#0087A8",
              },
              "& button: focus": {
                backgroundColor: "#FFFFFF",
                color: "#0087A8",
              },
              "& button: blur": {
                backgroundColor: "#FFFFFF",
                color: "#0087A8",
              },
              "& .MuiButtonBase-root": {
                "&.Mui-selected": {
                  fontSize: "18px",
                  fontWeight: 600,
                  border: "1px solid ",
                  borderColor: "#EBEBEB",
                  borderRadius: 1,
                  backgroundColor: "#FFFFFF",
                  color: "#0087A8",
                },
              },
            }}
          >
            <Tab
              label="Healthcare"
              value="1"
              style={{
                textTransform: "none",
                height: "60px",
                borderRadius: "20px 0 0 0",
                minWidth: "20%",
              }}
            />
            <Tab
              label="Liveview"
              value="2"
              style={{
                textTransform: "none",
                height: "60px",
                borderRadius: "0 20px 0 0",
                minWidth: "20%",
              }}
              disabled
            />
          </Tabs>
          <TabPanel value="1" style={panelTheme}>
            <IconButton
              style={newOpenTheme}
              onClick={() => handleClick("summary")}
            >
              <img src={newOpen} alt="openInNew" width={"25px"} />
            </IconButton>
            <Box sx={{ p: 1 }} variant="outlined">
              <div style={{ marginTop: "5px" }}>
                <Grid container spacing={2}>
                  <Grid item xs={2}>
                    <UserCard userData={userData[currentRcPage]} />
                  </Grid>
                  <Grid item xs={5}>
                    <ChartSummary wsData={wsData} setWsData={setWsData} />
                  </Grid>
                  <Grid item xs={5}>
                    <TodayList wsData={wsData} setWsData={setWsData} />
                  </Grid>
                </Grid>
              </div>
              {/* <RecipientContent data={userData} page={currentRcPage}/> */}
            </Box>
            <Box sx={{ p: 0 }} variant="outlined">
              <Carousel
                currentPage={currentRcPage}
                setCurrentPage={setCurrentRcPage}
                numPages={userData.length}
              />
            </Box>
          </TabPanel>
          <TabPanel value="2" style={panelTheme}>
            <IconButton style={newOpenTheme}>
              <img src={newOpen} alt="openInNew" width={"25px"} />
            </IconButton>
            <Box sx={{ p: 2 }} variant="outlined">
              <div style={{ marginTop: "5px" }}>
                <Typography variant="h5" component="div" color="#0A87A9">
                  Live room
                </Typography>
              </div>
            </Box>
            <Box sx={{ p: 0 }} variant="outlined">
              <Carousel
                currentPage={currentLrPage}
                setCurrentPage={setCurrentLrPage}
                numPages={1}
              />
            </Box>
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
};

export default RecipientCard;
