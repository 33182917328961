import { ReactComponent as SwitchOn } from "assets/img/icon/btn_switch_on.svg";
import { ReactComponent as SwitchOff } from "assets/img/icon/btn_switch_off.svg";

export const CustomSwitch = ({ checked, onChange, disabled }) => (
  <div
    style={{ display: 'inline-block', cursor: disabled ? 'not-allowed' : 'pointer' }}
    onClick={disabled ? null : onChange}
  >
    {checked ? <SwitchOn /> : <SwitchOff />}
  </div>
);
