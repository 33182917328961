import React from 'react';
import addGroupStyle from '../group.module.scss';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';

import FieldContainer from "./FieldContainer";

export const TextArea = ({ id, placeholder, value, maxLength, onChange, helperText }) => (
    <div className={addGroupStyle.textareaContainer}>
        <textarea
            className={addGroupStyle.textarea}
            id={id}
            placeholder={placeholder}
            value={value}
            maxLength={maxLength}
            onChange={onChange}
        />
        {helperText && <p className={addGroupStyle.textareaHelperText}>{helperText}</p>}
    </div>
);

const TextAreaField = ({ label, id, placeholder, value, maxLength, onChange, helperText }) => (
    <FieldContainer label={label}>
        <Box sx={{ minWidth: 180 }} noValidate autoComplete="off">
            <FormControl fullWidth>
                <TextArea
                    id={id}
                    placeholder={placeholder}
                    value={value}
                    maxLength={maxLength}
                    onChange={onChange}
                    helperText={helperText}
                />
            </FormControl>
        </Box>
    </FieldContainer>
);

export default TextAreaField;
