import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// Initial state
const initialState = {
  count: null,
};

// Local storeage
export const fetchCount = createAsyncThunk(
  "counter/fetchCount",
  async () => {
    // ...
  }
  // async (_, thunkAPI) => {
  //   const response = {
  //     data:{
  //       count: 1
  //     }
  //   }
  //   return response.data;
  // }
);

// Create slice
const counterSlice = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    setCounter: (state, action) => {
      state.count = action.payload.count;
    }
  },
  // extraReducers: {
  //   [fetchCount.fulfilled]: (state, action) => {
  //     state.count = action.payload;
  //   },
  // },
  extraReducers: (builder) => {
    // fulfilled, pending, rejected
    builder.addCase(fetchCount.fulfilled, (state, action) => {
      state.count = action.payload.count;
    })
  },
  
});

// Actions & Reducer
// export const { increment, decrement } = counterSlice.actions;
export const counterActions = counterSlice.actions;

export default counterSlice;
