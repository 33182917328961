import React, { useState, useCallback } from 'react';

import recipientStyle from './recipient.module.scss';

import dayjs from 'dayjs';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';

import InputField, { Input } from "./components/InputField";
import SwitchField from "./components/SwitchField";
import SelectField, { SelectInput } from "./components/SelectField";
import TypographyField from "./components/TypographyField";
import AddTimeField from "./components/AddTimeField";
import DatepickerField from "./components/DatepickerField";
import AlertDialog from "./components/AlertDialog"

import MedicineEditIcon from "assets/img/icon/icn_profile_edit.svg";
import { ReactComponent as MedicineGroup } from 'assets/img/icon/icn_medicine_group.svg';
import { ReactComponent as MedicineOne } from 'assets/img/icon/icn_medicine_1.svg';
import { ReactComponent as MedicineTwo } from 'assets/img/icon/icn_medicine_2.svg';
import { ReactComponent as MedicineThree } from 'assets/img/icon/icn_medicine_3.svg';
import { ReactComponent as RemovedBtn } from 'assets/img/icon/btn_removed.svg';
import { ReactComponent as RemovedTableBtn } from 'assets/img/icon/btn_table_removed.svg';

import { DosageData, QuantityData, DoseData, NewMedication } from "./const"

const TimeSetting = ({ ws, medicationScheduleData, setMedicationScheduleData, medicationScheduleAlertDialog, setMedicationScheduleAlertDialog,
  isValidateField, setIsValidateField, token }) => {

  const [timePickerAddTime, setTimePickerAddTime] = useState(null);

  function validateField(value, fieldName) {
    if (fieldName === 'dailyJobInterval') {
      const numValue = Number(value);
      const validateNumber = Number.isInteger(numValue) && numValue >= 1 && numValue <= 7;
      return validateNumber;
    }
    if (fieldName === 'snooze') {
      const numValue = Number(value);
      const validateNumber = Number.isInteger(numValue) && numValue >= 1 && numValue <= 30;
      return validateNumber;
    }
    return true;
  };

  const handleOnChange = useCallback((event, fieldName, type) => {
    const newValue = (() => {
      switch (type) {
        case 'input':
        case 'select':
          if (fieldName === 'dailyJobInterval' || fieldName === 'snooze') {
            const intValue = parseInt(event.target.value);
            return isNaN(intValue) ? event.target.value : intValue;
          }
          return event.target.value;
        case 'inputClear':
          return '';
        case 'switch':
          return !event;
        case 'datepick':
          if (fieldName === 'endTime') {
            const endOfDay = dayjs(event).endOf('day');
            return dayjs(endOfDay).unix()
          }
          return dayjs(event).unix();
        default:
          return event;
      }
    })();

    let newHelperText = '';

    if (type === 'input' && fieldName === 'dailyJobInterval') {
      const isValid = validateField(newValue, fieldName);
      if (!isValid) {
        newHelperText = "Numbers one to seven"
      }
    }
    if (type === 'input' && fieldName === 'snooze') {
      const isValid = validateField(newValue, fieldName);
      if (!isValid) {
        newHelperText = "Numbers one to thirty"
      }
    }

    setIsValidateField(prevState => {
      return prevState.map((item) => {
        if (item.id !== medicationScheduleData.id) {
          return item;
        }

        return {
          ...item,
          [fieldName]: newHelperText,
        };
      });
    });

    setMedicationScheduleData(prevState => {
      return prevState.map((item) => {
        if (item.id !== medicationScheduleData.id) {
          return item;
        }

        return {
          ...item,
          [fieldName]: newValue,
        };
      });
    });
  }, [medicationScheduleData.id]);

  function handleAddTimeTimePickChange(newValue) {
    setTimePickerAddTime(newValue);
  }

  const handleAddTimeIconClick = useCallback((action, inputRemovedTime) => {
    setMedicationScheduleData(prevState => {
      return prevState.map((item) => {
        if (item.id !== medicationScheduleData.id) {
          return item;
        }
        const newTimeValue = timePickerAddTime ? `${dayjs(timePickerAddTime).format('HH')}:${dayjs(timePickerAddTime).format('mm')}:00` : '';
        let newHelperText = '';

        if (newTimeValue && item.dailyJobAtTimes.includes(newTimeValue)) {
          newHelperText = 'The reminder time has been repeated';
          setIsValidateField(prevState => {
            return prevState.map((item) => {
              if (item.id !== medicationScheduleData.id) {
                return item;
              }

              return {
                ...item,
                addTime: newHelperText,
              };
            });
          });
          return item;
        }

        let updatedTime = [...item.dailyJobAtTimes];
        if (action === 'add' && newTimeValue) {
          updatedTime.push(newTimeValue);
          updatedTime.sort((a, b) => {
            const timeA = dayjs(a, 'HH:mm');
            const timeB = dayjs(b, 'HH:mm');
            return timeA.isBefore(timeB) ? -1 : 1;
          });
        }

        if (action === 'remove' && inputRemovedTime) {
          updatedTime = updatedTime.filter(time => time !== inputRemovedTime);
        }

        setIsValidateField(prevState => {
          return prevState.map((item) => {
            if (item.id !== medicationScheduleData.id) {
              return item;
            }

            return {
              ...item,
              addTime: '',
              patterns: '',
            };
          });
        });

        return {
          ...item,
          dailyJobAtTimes: updatedTime,
        };
      });
    });

    if (action === 'add') {
      setTimePickerAddTime(null);
    }
  }, [medicationScheduleData.id, timePickerAddTime, setIsValidateField, setTimePickerAddTime]);

  function handleAlertDialogClick(state, id) {
    setMedicationScheduleAlertDialog({ state, id })
  }

  function handleRemovedTableClick() {
    const requestDeleteOneCron = {
      resource: `/users/0/crons/${medicationScheduleAlertDialog.id}`,
      verb: "delete",
      accessToken: token,
    }
    ws.send(JSON.stringify(requestDeleteOneCron));
  }

  return (
    <Grid container justifyContent="space-between" alignItems="flex-start" spacing={2} >
      <Grid item xs={2}>
        <Box display="flex" justifyContent="center" alignItems="center" height="100%" position="relative" left="-7%">
          <MedicineGroup className={recipientStyle.mainIcon} />
        </Box>
      </Grid>
      <Grid item xs={5}>
        <InputField
          id={`group-name-${medicationScheduleData.id}`}
          label="Group Name"
          placeholder="Group Name"
          value={medicationScheduleData.name}
          onChange={(event) => handleOnChange(event, 'name', 'input')}
          iconClick={(event) => handleOnChange(event, 'name', 'inputClear')}
          helperText={isValidateField.name}
        />
        <SwitchField
          label="Alarm"
          checked={medicationScheduleData.enabled}
          onChange={() => handleOnChange(medicationScheduleData.enabled, 'enabled', 'switch')}
        />
        <SelectField
          id={`dosage-${medicationScheduleData.id}`}
          label="Dosage"
          placeholder="Please choose"
          value={medicationScheduleData.timing}
          onChange={(event) => handleOnChange(event, 'timing', 'select')}
          data={DosageData}
          helperText={isValidateField.timing}
        />
        <TypographyField
          label="Reminder"
          textColor="#D3302F"
          text={isValidateField.patterns}
        />
        <AddTimeField
          timeList={medicationScheduleData.dailyJobAtTimes}
          removedIconClick={handleAddTimeIconClick}
          timeListId={`medication-schedule-add-time-Field-${medicationScheduleData.id}`}
          timePickerValue={timePickerAddTime}
          timePickerOnChange={handleAddTimeTimePickChange}
          addIconClick={() => handleAddTimeIconClick('add')}
          timePickerHelperText={isValidateField.addTime}
        />
      </Grid>
      <Divider orientation="vertical" variant="middle" flexItem sx={{ margin: '20px 20px 0 20px' }} />
      <Grid item xs={3}>
        <DatepickerField
          label="Start"
          formatDate="YYYY/M/D"
          value={dayjs.unix(medicationScheduleData.startTime).format('YYYY/M/D')}
          onChange={(event) => handleOnChange(event, 'startTime', 'datepick')}
          helperText={isValidateField.startTime}
        />
        <InputField
          id={`medication-schedule-every-${medicationScheduleData.id}`}
          label="Every"
          placeholder="one to seven days"
          value={medicationScheduleData.dailyJobInterval}
          maxLength={1}
          onChange={(event) => handleOnChange(event, 'dailyJobInterval', 'input')}
          iconClick={(event) => handleOnChange(event, 'dailyJobInterval', 'inputClear')}
          helperText={isValidateField.dailyJobInterval}
        />
        <DatepickerField
          label="Until"
          formatDate="YYYY/M/D"
          value={dayjs.unix(medicationScheduleData.endTime).format('YYYY/M/D')}
          onChange={(event) => handleOnChange(event, 'endTime', 'datepick')}
          helperText={isValidateField.endTime}
        />
        <InputField
          id={`medication-schedule-snooze-${medicationScheduleData.id}`}
          label="Snooze"
          placeholder="one to thirty min"
          value={medicationScheduleData.snooze}
          maxLength={2}
          onChange={(event) => handleOnChange(event, 'snooze', 'input')}
          iconClick={(event) => handleOnChange(event, 'snooze', 'inputClear')}
          helperText={isValidateField.snooze}
        />
      </Grid>
      <Grid item xs={1}>
        {medicationScheduleData.id && (
          <>
            <Box position="relative" top="-8px" sx={{ minWidth: 170, margin: "0 10px" }}>
              <IconButton aria-label="remove-table-icon" onClick={() => handleAlertDialogClick(true, medicationScheduleData.id)}>
                <RemovedTableBtn />
              </IconButton>
            </Box>
            {medicationScheduleData.id === medicationScheduleAlertDialog.id && (
              <AlertDialog
                opneState={medicationScheduleAlertDialog.state}
                onClose={() => handleAlertDialogClick(false, null)}
                dialogTitle="Alert"
                dialogContent="Whether to delete reminder messages ?"
                actionButton={
                  <>
                    <Button onClick={() => handleAlertDialogClick(false, null)}>Disagree</Button>
                    <Button onClick={handleRemovedTableClick} autoFocus>Agree</Button>
                  </>
                }
              />
            )}
          </>
        )}
      </Grid>
    </Grid>
  )
};

const MedicationSetting = ({ medicationScheduleId, medicationScheduleData, setMedicationScheduleData, isValidateField, setIsValidateField }) => {
  const handleOnChange = useCallback((event, fieldName, type, medicineIndex) => {
    const newValue = (() => {
      switch (type) {
        case 'input':
        case 'select':
          return event.target.value;
        case 'inputClear':
          return '';
        default:
          return event;
      }
    })();

    setMedicationScheduleData(prevState => {
      return prevState.map(item => {
        if (item.id !== medicationScheduleId) return item;

        return {
          ...item,
          medicines: item.medicines.map((med, index) => {
            if (index !== medicineIndex) return med;
            return {
              ...med,
              [fieldName]: newValue
            };
          })
        };
      });
    });

    setIsValidateField(prevState => {
      return prevState.map(item => {
        if (item.id !== medicationScheduleId) return item;

        return {
          ...item,
          medicines: item.medicines.map((med, index) => {
            if (index !== medicineIndex) return med;
            return {
              ...med,
              [fieldName]: ''
            };
          })
        };
      });
    });
  }, [medicationScheduleId, setMedicationScheduleData, setIsValidateField]);

  function handleRemovedClick(medicineIndex) {
    setMedicationScheduleData(prevState => {
      const newState = prevState.map((item, i) => {
        if (item.id !== medicationScheduleId) return item;
        return {
          ...item,
          medicines: item.medicines.filter((_, j) => j !== medicineIndex)
        };
      });
      return newState;
    });

    setIsValidateField(prevState => {
      const newState = prevState.map((item, i) => {
        if (item.id !== medicationScheduleId) return item;
        return {
          ...item,
          medicines: item.medicines.filter((_, j) => j !== medicineIndex)
        };
      });
      return newState;
    });
  }

  function handleAddMedicineClick() {
    setMedicationScheduleData(prevState => {
      const newState = prevState.map((item, i) => {
        if (item.id !== medicationScheduleId) return item;
        return {
          ...item,
          medicines: [...item.medicines, NewMedication]
        };
      });
      return newState;
    });

    setIsValidateField(prevState => {
      const newState = prevState.map((item, i) => {
        if (item.id !== medicationScheduleId) return item;
        return {
          ...item,
          medicines: [...item.medicines, NewMedication]
        };
      });
      return newState;
    });
  }

  return (
    <>
      {
        medicationScheduleData?.map((medicine, medicineIndex) => (
          <React.Fragment key={`medicine-${medicationScheduleId}-${medicineIndex}`}>
            <Grid container justifyContent="center" alignItems="center" spacing={2}>
              <Grid item xs={2}>
                <Box display="flex" justifyContent="center" alignItems="center" height="100%" position="relative" left="-7%">
                  <div className={recipientStyle.medicineIconContainer}>
                    {
                      medicineIndex % 3 === 0 ? <MedicineOne className={recipientStyle.medicineIcon} />
                        : medicineIndex % 3 === 1 ? <MedicineTwo className={recipientStyle.medicineIcon} />
                          : <MedicineThree className={recipientStyle.medicineIcon} />
                    }
                    <div className={recipientStyle.medicineIconHover}>
                      <img src={MedicineEditIcon} className={recipientStyle.medicineEditIcon} />
                    </div>
                  </div>
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box sx={{ minWidth: 170 }} noValidate autoComplete="off">
                  <FormControl fullWidth>
                    <Input
                      id={`drug-name-${medicationScheduleId}-${medicineIndex}`}
                      value={medicine.name}
                      placeholder="Drug Name"
                      onChange={(event) => handleOnChange(event, 'name', 'input', medicineIndex)}
                      iconClick={(event) => handleOnChange(event, 'name', 'inputClear', medicineIndex)}
                      helperText={isValidateField.medicines[medicineIndex]?.name}
                    />
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box sx={{ minWidth: 170 }}>
                  <FormControl fullWidth>
                    <SelectInput
                      id={`quantity-${medicationScheduleId}-${medicineIndex}`}
                      value={medicine.quantity}
                      onChange={(event) => handleOnChange(event, 'quantity', 'select', medicineIndex)}
                      data={QuantityData}
                      placeholder="Please choose"
                      helperText={isValidateField.medicines[medicineIndex]?.quantity}
                    />
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={3}>
                <Box sx={{ minWidth: 170 }}>
                  <FormControl fullWidth>
                    <SelectInput
                      id={`dose-${medicationScheduleId}-${medicineIndex}`}
                      value={medicine.shape}
                      onChange={(event) => handleOnChange(event, 'shape', 'select', medicineIndex)}
                      data={DoseData}
                      placeholder="Please choose"
                      helperText={isValidateField.medicines[medicineIndex]?.shape}
                    />
                  </FormControl>
                </Box>
              </Grid>
              <Grid item xs={1}>
                <Box sx={{ minWidth: 170, margin: "0 10px" }}>
                  <IconButton aria-label="removed-icon" onClick={() => handleRemovedClick(medicineIndex)}>
                    <RemovedBtn />
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
            <Divider sx={{ margin: '20px 0' }} />
          </React.Fragment>
        ))
      }
      <Box sx={{ marginBottom: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Button
          sx={{
            fontSize: '16px',
            fontWeight: 600,
            borderRadius: '10px',
            backgroundColor: '#0A87A9',
            padding: '8px 16px',
            '&:hover': {
              backgroundColor: '#05647E',
            }
          }}
          variant="contained"
          onClick={handleAddMedicineClick}
        >
          <div className={recipientStyle.customAddIcon} />
          Add Medicine
        </Button>
      </Box>
    </>
  )
};

const MedicationSchedule = ({ ws, data, setData, alertDialog, setAlertDialog, isValidateField, setIsValidateField, token }) => (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <Box>
        <TimeSetting
          ws={ws}
          medicationScheduleData={data}
          setMedicationScheduleData={setData}
          medicationScheduleAlertDialog={alertDialog}
          setMedicationScheduleAlertDialog={setAlertDialog}
          isValidateField={isValidateField}
          setIsValidateField={setIsValidateField}
          token={token}
        />
      </Box>
      <Divider sx={{ margin: '20px 0' }} />
    </Grid>
    <Grid item xs={12}>
      <Box>
        <MedicationSetting
          medicationScheduleId={data.id}
          medicationScheduleData={data.medicines}
          setMedicationScheduleData={setData}
          isValidateField={isValidateField}
          setIsValidateField={setIsValidateField}
        />
      </Box>
    </Grid>
  </Grid>
)

export default MedicationSchedule;