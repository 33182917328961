import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import mainStyle from '../../dashboard.module.scss';
import recipientStyle from '../recipient.module.scss';
import { useMediaQuery, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
// import userPhoto from "assets/img/testPhoto/testImage.jpeg";
import setting from "assets/img/icon/icn_profile_setting.svg";
import event from "assets/img/icon/icn_profile_event.svg";

const UserCard = (prop) => {
  const content = prop.userData;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(1600));

  useEffect(() => {
  }, [isSmallScreen])

  const navigate = useNavigate();
  const handleClick = (page) => {
    let path = '/'+ page
    navigate(path);
  };

  return (
    <>
      <Grid container sx={{p: 1}}>
        {/* alignItems="center" justifyContent="space-evenly" */}
        {/* <div className={recipientStyle.profileImage} 
        style={{ width: (isSmallScreen? '60px': '80px'), height: (isSmallScreen? '60px': '80px'), backgroundImage: `url(${userPhoto})`}}>
        </div> */}
        <div className={recipientStyle['usercardPhoto']}>
          <div className={recipientStyle['profileImage']} 
            style={{ width: '60px', height: '60px',
            borderColor: (content.note? '#CB6B6E': '#4FD1C5'), backgroundImage: `url(${content.photoSrc})`}}> 
            {/* `url(${userPhoto})` */}
          </div>
          <img src={content.noteSrc} className={recipientStyle['profileNote']} />
        </div>
      </Grid>
      <Grid container sx={{p: 1}}>
        <Typography variant="h5" component="div" color="#0A87A9" style={{fontWeight:"bold"}}>
          {content.identity}
        </Typography>
      </Grid>
      <Grid container sx={{p: 1}}>
      {/* alignItems="center" justifyContent="space-around"  */}
        <Typography variant={isSmallScreen? 'h7':'h6'} component="div" color="#737791" >
          Age {content.age}
        </Typography>
        {/* <span style={{marginLeft: '5px', color: 'rgb(111, 116, 143)'}}>Age 65</span> */}
        {/* <Divider orientation="vertical" variant="middle" flexItem style={{borderWidth: '1px'}}/> */}
        {/* <Typography component="div" style={{margin: '0px 10px'}} color="#BAD4DD" >|</Typography> */}
        <Divider orientation="vertical"
          style={{ margin: "5px 10px 0px 10px", height: isSmallScreen? '16px':'22px', color: "#BAD4DD"}}
        />
        <Typography variant={isSmallScreen? 'h7':'h6'} component="div" color="#737791" >
          {content.gender}
        </Typography>
      </Grid>
      <Grid container sx={{p: 1}}>
      {/* alignItems="center" justifyContent="space-evenly"  */}
        <div className={recipientStyle['profileIcon']} style={{minWidth: '35px'}} onClick={() => handleClick('event')}>
          <img src={event}/>
        </div>
        <div className={recipientStyle['profileIcon']} style={{minWidth: '35px', marginLeft:'15px'}} onClick={() => handleClick('recipient_setting')}>
          <img src={setting}/>
        </div>
      </Grid>
    </>
  );
};

export default UserCard;
