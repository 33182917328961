import React, { useState, useEffect, useRef, useCallback } from "react";
import healthStyle from "../health.module.scss";
import 'react-alice-carousel/lib/alice-carousel.css';
import "../carousel.css";
import { cloneDeep } from "lodash";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useWebSocket } from "hook/WebSocketContext";
import dayjs from 'dayjs';
import { useAuth } from "hook/AuthContext";
import { useMediaQuery, useTheme } from "@mui/material";
// import AliceCarousel from 'react-alice-carousel';
import CardContent from "@mui/material/CardContent";
import TabContext from '@mui/lab/TabContext';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Button from '@mui/material/Button';
import { styled } from '@mui/system';
import { TabScrollButton } from '@mui/material';
import Typography from '@mui/material/Typography';
import fullViewIcon from "assets/img/icon/icn_profile_page_expand.svg";
import liveIcon from "assets/img/icon/icn_profile_liveview.svg";
import settingIcon from "assets/img/icon/icn_profile_setting.svg";
import wellNote from "assets/img/icon/icn_normal.svg";
import errorNote from "assets/img/icon/icn_abnormal.svg";

import pressure_alert from "assets/img/icon/icn_bp_abnormal.svg";
import pressure_normal from "assets/img/icon/icn_bp_normal.svg";
import medicine_alert from "assets/img/icon/icn_medicine_abnormal.svg";
import medicine_normal from "assets/img/icon/icn_medicine_normal.svg";
import heart_alert from "assets/img/icon/icn_heartrate_abnormal.svg";
import heart_normal from "assets/img/icon/icn_heartrate_normal.svg";
import device_alert from "assets/img/icon/icn_immobility_abnormal.svg";
import device_normal from "assets/img/icon/icn_immobility_normal.svg";
import sugar_alert from "assets/img/icon/icn_bs_abnormal.svg";
import sugar_normal from "assets/img/icon/icn_bs_normal.svg";
import sleep_alert from "assets/img/icon/icn_sleep_time_abnormal.svg";
import sleep_normal from "assets/img/icon/icn_sleep_time_normal.svg";


import newOpen from "assets/img/icon/btn_external_expand.svg";

const MyTabScrollButton = styled(TabScrollButton)({
  '&.Mui-disabled': {
    opacity: 0.3,
  },
  width: '25px',
  // height: '25px',
  // borderRadius: 10, 
  // backgroundColor: '#FFFFFF', 
  // top: '15px'
});

const TabContentVer = (props) => {
  const { 
    wsData, setWsData, user,
    hideCalendar, setHideCalendar, setOpenSpinner,
    groupData, setGroupData
  } = props;
  const ws = useWebSocket();
  const { token } = useAuth();
  // let userInfo = useSelector((state) => state.user.userInfo);
  // console.log(user)
  // console.log(userInfo)

  const theme = useTheme();
  const isMiniBlock = useMediaQuery(theme.breakpoints.down(800));
  const [blockWidth, setBlockWidth] = useState(260);
  const [timeTagWidth, setTimeTagWidth] = useState(600);

  useEffect(() => {
    if(isMiniBlock){ 
      setBlockWidth(240);
      setTimeTagWidth(560);
    }else{ 
      setBlockWidth(260);
      setTimeTagWidth(600);
    }
  }, [isMiniBlock]);
  // console.log(userInfo)
  // let userInfo = cloneDeep(useSelector((state) => state.user.userInfo));
  // userInfo.LastUsedHomegroupId = 4

  const highSBP = 120, lowSBP = 90, highDBP = 80, lowDBP = 60, highPluse = 100, lowPluse = 60;

  const [userStatus, setUserStatus] = useState([]);
  const [userData, setUserData] = useState([]);
  const handleChangeView = () => {
    let val = cloneDeep(hideCalendar);
    // setHideCalendar(!val);
  };

  const [subTabValue, setSubTabValue] = useState('');
  const handleChangeSubTab = (event, newValue) => {
    setSubTabValue(newValue);
  };

  const [contentKey, setContentKey] = useState('');
  const handleChangeContent = (id) => {
    setContentKey(id);
    setSummaryTag(0)
    getHealthData(id, 0) // summaryTag
  }

  const [summaryTag, setSummaryTag] = useState(0);
  const handleChangeTag = (tag) => {
    setSummaryTag(tag);
    getHealthData(contentKey, tag)
  }

  const navigate = useNavigate();
  const handleClickPage = (page) => {
    let path = "/" + page;
    navigate(path);
  }

  const getDateYMD = (date) => {
    //get timestamp of 00:00
    let changeY = dayjs(date).format('YYYY');
    let changeM = dayjs(date).format('MM');
    let changeD = dayjs(date).format('DD');
    let changeDate = changeY+'-'+changeM+'-'+changeD;
    let change = dayjs(changeDate);
    return change;
  }
  
  const getDate0000 = (date) => {
    //get 00:00 of timestamp
    let changeY = dayjs(date).format('YYYY');
    let changeM = dayjs(date).format('MM');
    let changeD = dayjs(date).format('DD');
    let changeDate = changeY+'-'+changeM+'-'+changeD;
    let stamp00 = parseInt(dayjs(changeDate).format('X'))
    return stamp00; 
  }

  const sendRequest = (uid, start, end, avg) =>{
    const chartSendData = {
      resource: `/users/${uid}/reports`,
      verb: 'read',
      accessToken: token,
      data: {
        filter: {
          homeGroupId: user.lastUsedHomegroupId,
          startTime: start,
          endTime: end
        },
        projection: avg
      }
    };
    setOpenSpinner(true)
    ws.send(JSON.stringify(chartSendData));
  }

  const getHealthData = (uid, tag) => {
    let endStamp = parseInt(dayjs().format('X'));
   
    let tmp_stampRange = endStamp - 86400*30;
    if(tag === 0){
      tmp_stampRange = endStamp - 86400;
    }else if(tag === 1){
      tmp_stampRange = endStamp - 86400*7;
    }

    let tmp_startDate = getDateYMD(tmp_stampRange*1000)
    let startStamp = getDate0000(tmp_startDate)
    let averageRange = [];
    if(tag === 1){
      averageRange = ['weeklyAverage']
    }else if(tag === 2){
      averageRange = ['monthlyAverage']
    }else{
      startStamp = tmp_stampRange
      averageRange = ['latestData', 'checkAbnormalities']
    }

    var user = groupData.find(function (element) {
      return element.key === uid;
    });

    sendRequest(user.userId, startStamp, endStamp, averageRange);
  }

  const makeAvgData = async (data, report) => {
    let updateGroup = cloneDeep(userData)
    let tmpSBP = 0, tmpDBP = 0, tmpRate = 0, tmpTime = '--',
      warnPressure = false, warnPluse = false;

    if(data !== null && data.length > 0){
      let numOfData = data.length;
      let end = data[0].timestamp;
      let start = data[numOfData-1].timestamp;
      if(numOfData > 1){
        tmpTime = dayjs(start*1000).format('YYYY/MM/DD') +' ~ '+ dayjs(end*1000).format('YYYY/MM/DD')
      }else{
        tmpTime = dayjs(start*1000).format('YYYY/MM/DD HH:mm')
      }
      
      data.forEach((subdata, index) => {
        tmpSBP += subdata.systolic
        tmpDBP += subdata.diastolic
        tmpRate += subdata.heartRate
      })
      tmpSBP = Math.round(tmpSBP/numOfData);
      tmpDBP = Math.round(tmpDBP/numOfData);
      tmpRate = Math.round(tmpRate/numOfData);
      if(tmpRate > highPluse || tmpRate < lowPluse){
        warnPluse = true
      }
      if(tmpSBP > highSBP || tmpSBP < lowSBP || tmpDBP > highDBP || tmpDBP < lowDBP){
        warnPressure = true
      }
    }else{
      tmpSBP = '--'
      tmpDBP = '--'
      tmpRate = '--'
      warnPluse = null
      warnPressure = null
    }

    updateGroup.forEach((subgroup, index) => {
      if(subgroup.key === contentKey){
        subgroup.healthData.pressure.sbp = tmpSBP;
        subgroup.healthData.pressure.dbp = tmpDBP;
        subgroup.healthData.pressure.time = tmpTime;
        subgroup.healthData.pressure.warn = warnPressure;
        subgroup.healthData.pluse.rate = tmpRate;
        subgroup.healthData.pluse.time = tmpTime;
        subgroup.healthData.pluse.warn = warnPluse;

        if(report){
          if(report.diastolic === 'normal' && report.systolic === 'normal'){
            subgroup.status24Hr.pressure = 'normal'
          }else if(report.diastolic === 'noData' && report.systolic === 'noData'){
            subgroup.status24Hr.pressure = 'noData'
          }else{
            subgroup.status24Hr.pressure = 'abnormal'
          }
          if(report.heartRate === 'normal'){
            subgroup.status24Hr.heart = 'normal'
          }else if(report.diastolic === 'noData'){
            subgroup.status24Hr.heart = 'noData'
          }else{
            subgroup.status24Hr.heart = 'abnormal'
          }
          if(subgroup.status24Hr.pressure === 'abnormal' || subgroup.status24Hr.medicine === 'abnormal' || subgroup.status24Hr.heart === 'abnormal' || subgroup.status24Hr.behavior === 'abnormal'){
            subgroup.status24Hr.total = 'abnormal'
            subgroup.note = true
          }else if(subgroup.status24Hr.pressure === 'noData' && subgroup.status24Hr.medicine === 'noData' && subgroup.status24Hr.heart === 'noData' && subgroup.status24Hr.behavior === 'noData'){
            subgroup.status24Hr.total = 'noData'
            subgroup.note = false
          }else{
            subgroup.status24Hr.total = 'normal'
            subgroup.note = true
          }
        }        

      }
    })

    // console.log(updateGroup)
    setUserData(updateGroup)
    setOpenSpinner(false)
  }

  const updateAvgData = async (data) => {
    let updateGroup = cloneDeep(userData)
    let tmpSBP = 0, tmpDBP = 0, tmpRate = 0, tmpTime = '--',
      warnPressure = false, warnPluse = false;

    tmpTime = dayjs(data.timestamp*1000).format('YYYY/MM/DD HH:mm')
    tmpSBP = data.systolic
    tmpDBP = data.diastolic
    tmpRate = data.heartRate

    if(tmpRate > highPluse || tmpRate < highPluse){
      warnPluse = true
    }
    if(tmpSBP > highSBP || tmpSBP < lowSBP || tmpDBP > highDBP || tmpDBP < lowDBP){
      warnPressure = true
    }

    updateGroup.forEach((subgroup, index) => {
      if(subgroup.key === contentKey){
        subgroup.healthData.pressure.sbp = tmpSBP;
        subgroup.healthData.pressure.dbp = tmpDBP;
        subgroup.healthData.pressure.time = tmpTime;
        subgroup.healthData.pressure.warn = warnPressure;
        subgroup.healthData.pluse.rate = tmpRate;
        subgroup.healthData.pluse.time = tmpTime;
        subgroup.healthData.pluse.warn = warnPluse;

        if(data.heartRate === 'normal'){
          if(subgroup.status24Hr.heart === 'noData'){
            subgroup.status24Hr.heart = 'normal'
          }
        }else{
          subgroup.status24Hr.heart = 'abnormal'
        }
        if(data.diastolic === 'normal' && data.systolic === 'normal'){
          if(subgroup.status24Hr.pressure === 'noData'){
            subgroup.status24Hr.pressure = 'normal'
          }
        }else{
          subgroup.status24Hr.pressure = 'abnormal'
        }   

      }
    })

    // console.log(updateGroup)
    setUserData(updateGroup)
    setOpenSpinner(false)

  }

  useEffect(() => {
    // console.log(wsData)
    if (wsData.updateData && wsData.updateTag === 'report_creat') {
      console.log('Dashboard Tab get creat ws');
      console.log(wsData.updateData);
      if(summaryTag !== 0 ){
        getHealthData(contentKey, summaryTag)
      }else{
        setOpenSpinner(true)
        updateAvgData(wsData.updateData)
      }
    } else if (wsData.updateData && wsData.updateTag === 'report_read') {
      console.log('Dashboard Tab get read ws');
      console.log(wsData.updateData);
      if(summaryTag === 1){
        makeAvgData(wsData.updateData.weeklyAverage)
      }else if(summaryTag === 2){
        makeAvgData(wsData.updateData.monthlyAverage)
      }else{
        let tmpData = null;
        if(wsData.updateData.latestData.timestamp !== null){
          tmpData = [wsData.updateData.latestData]
        }
        makeAvgData(tmpData, wsData.updateData.abnormalReports)
      }
    }
  }, [wsData]);

  useEffect(() => {
    if(groupData.length > 0){
      console.log('Dashboard Tab find user summary data')
      setUserData(cloneDeep(groupData))

      setSubTabValue(groupData[0].key)
      setContentKey(groupData[0].key)
      setSummaryTag(0)

      getHealthData(groupData[0].key, 0) // for summary
    }
  }, [groupData]);

  return (
    <>
      {userData && 
        <TabContext value={subTabValue} >
          <Box sx={{ p: 0 }} variant="outlined"
            style={{ display: "flex", justifyContent: "space-between", width: "100%", }}>
            <Tabs value={subTabValue} style={{minWidth: 'calc(100% - 150px)'}}
              onChange={handleChangeSubTab} aria-label="lab API tabs example"
              TabIndicatorProps={{ sx: { backgroundColor: "white", height: '1px'} }}
              scrollButtons="auto" variant="scrollable" //maxWidth: '50%'
              ScrollButtonComponent={MyTabScrollButton}
              sx={{
                  // '& .MuiTabs-scrollButtons':{overflow: 'hidden'},
                  // '& .MuiTabs-scrollButtons.Mui-disabled':{ opacity: 0.3, width: 0},
                  '& button': {fontSize: "16px", backgroundColor: '#EEF0F2', color: '#737791'},
                  '& button: active': {backgroundColor: '#FFFFFF', color: '#0087A8'},
                  '& button: focus': {backgroundColor: '#FFFFFF', color: '#0087A8'},
                  '& button: blur': {backgroundColor: '#FFFFFF', color: '#0087A8', },
                  '& .MuiButtonBase-root': {
                    '&.Mui-selected': { fontSize: "18px", fontWeight: 600, borderColor: '#EBEBEB',borderRadius: 1, backgroundColor: '#FFFFFF', color: '#0087A8'},
                  },
              }}
            >
              {userData.map((item, index) => (
                <Tab label={item.name} value={item.key} key={index} onClick={()=>{handleChangeContent(item.key)}} 
                style={{textTransform: "none", textOverflow: 'ellipsis', display: 'block', 
                height:'58px', minWidth:"20%", maxWidth:"25%", boxShadow: '-15px 0px 0px -14px rgba(115, 119, 145, 0.25)',
                borderRadius: userData.length===1? '20px 20px 0 0': index===0?'20px 0 0 0': index===userData.length-1?'0 20px 0 0':'0 0 0 0', }}/>
              ))}
            </Tabs>
            <Box style={{minWidth:"150px"}}>
              <Grid container justifyContent="end">
                <div className={healthStyle['menuIcon']} onClick={() => handleChangeView()}>
                  <img src={fullViewIcon}/>
                </div>
                <div className={healthStyle['menuIcon']} >
                  <img src={liveIcon}/>
                </div>
                <div className={healthStyle['menuIcon']} >
                  <img src={settingIcon}/>
                </div>
              </Grid>
            </Box>
          </Box>
          {/* <TabPanel value="1" style={panelTheme}><Box sx={{ p: 1 }} variant="outlined">123</Box></TabPanel> */}
        </TabContext>
      }
      {userData && 
        <CardContent style={{padding:'16px 0px 0px 16px',backgroundColor: '#ffffff', borderRadius:'0 20px 20px 20px', 
          boxShadow: 'rgba(0,0,0,0.08) 0 8px 16px 0', height: 'calc(100% - 75px)'}}>
          {/* <TabContent></TabContent> border: '1px solid rgba(0, 0, 0, 0.12)', */}
          {userData.filter(item => item.key === contentKey).map((element, index) => (
            <div className="item" data-value="1" key={index} style={{height:'100%', width: '100%'}}>
              <Grid container style={{minHeight: 'calc(30% - 70px)',}}>
                <div className={healthStyle['eventMarquee']}>過去24小時</div>
                <Grid item style={{width: '140px', marginTop: '5px'}}>
                  <div className={healthStyle['userBox']}>
                    <div className={healthStyle['userImage']} 
                      style={{
                        borderColor:(element.status24Hr.total==='abnormal'? '#F89898': element.status24Hr.total==='normal'?'#94DEB4': '#FFFFFF'), backgroundImage: `url(${element.photo})`}}>
                    </div>
                    {element.note && 
                      <img src={element.status24Hr.total==='abnormal'? errorNote: element.status24Hr.total==='normal'? wellNote:''}
                        className={healthStyle['userNote']} />
                    }
                  </div>
                  <div style={{display: 'flex', justifyContent: 'center', marginTop: '10px'}}>
                    <Typography style={{ fontSize: 24, fontWeight: 'bold', color: '#737791' }} gutterBottom>
                      {element.name}
                      {/* {element.email}{element.role}-{element.userId} */}
                    </Typography>
                  </div>
                </Grid>
                <Grid item style={{width: 'calc(100% - 140px)',display: 'inline-flex', flexWrap: 'wrap'}}> 
                  <div style={{margin: '5px 15px 0px 15px'}}
                    className={` ${healthStyle['eventContainer']} ${element.status24Hr.pressure==='abnormal'? healthStyle['eventAlert']: element.status24Hr.pressure==='normal'? healthStyle['eventWell']:healthStyle['eventNull']}`}>
                    <div className={healthStyle['eventInfo']}>
                      <div className={healthStyle['eventImg']} 
                        style={{ backgroundImage: element.status24Hr.pressure==='abnormal'? `url(${pressure_alert})`: `url(${pressure_normal})`}}>
                      </div>
                      {/* <img src={errorNote} className={healthStyle['eventNote']} /> */}
                      <Grid container style={{marginLeft: '15px'}}>
                        <Grid item xs={12} className={healthStyle['eventTitle']}>血壓狀況</Grid>
                        <Grid item xs={12} className={healthStyle['eventContext']}>{element.status24Hr.pressure==='abnormal'?'異常': element.status24Hr.pressure==='normal'?'正常':'無資料'}</Grid>
                      </Grid>
                    </div>
                  </div>
                  <div style={{margin: '5px 15px 0px 15px'}}
                    className={` ${healthStyle['eventContainer']} ${element.status24Hr.medicine==='abnormal'? healthStyle['eventAlert']: element.status24Hr.medicine==='normal'? healthStyle['eventWell']:healthStyle['eventNull']} `}>
                    <div className={healthStyle['eventInfo']}>
                      <div className={healthStyle['eventImg']} 
                        style={{ backgroundImage: element.status24Hr.medicine==='abnormal'? `url(${medicine_alert})`:`url(${medicine_normal})`}}>
                      </div>
                      {/* <img src={errorNote} className={healthStyle['eventNote']} /> */}
                      <Grid container style={{marginLeft: '15px'}}>
                        <Grid item xs={12} className={healthStyle['eventTitle']}>用藥狀況</Grid>
                        <Grid item xs={12} className={healthStyle['eventContext']}>{element.status24Hr.medicine==='abnormal'?'異常': element.status24Hr.medicine==='normal'?'正常':'無資料'}</Grid>
                      </Grid>
                    </div>
                  </div>
                  <div className={` ${healthStyle['eventContainer']} ${element.status24Hr.heart==='abnormal'? healthStyle['eventAlert']: element.status24Hr.heart==='normal'? healthStyle['eventWell']:healthStyle['eventNull']} `}
                    style={{margin: '20px 15px 20px 15px'}}>
                    <div className={healthStyle['eventInfo']}>
                      <div className={healthStyle['eventImg']} 
                        style={{ backgroundImage: element.status24Hr.heart==='abnormal'? `url(${heart_alert})`: `url(${heart_normal})`}}>
                      </div>
                      {/* <img src={errorNote} className={healthStyle['eventNote']} /> */}
                      <Grid container style={{marginLeft: '15px'}}>
                        <Grid item xs={12} className={healthStyle['eventTitle']}>心率狀況</Grid>
                        <Grid item xs={12} className={healthStyle['eventContext']}>{element.status24Hr.heart==='abnormal'?'異常': element.status24Hr.heart==='normal'?'正常':'無資料'}</Grid>
                      </Grid>
                    </div>
                  </div>
                  <div className={` ${healthStyle['eventContainer']} ${element.status24Hr.behavior==='abnormal'? healthStyle['eventAlert']: element.status24Hr.behavior==='normal'? healthStyle['eventWell']:healthStyle['eventNull']} `}
                    style={{margin: '20px 15px 20px 15px'}}>
                    <div className={healthStyle['eventInfo']}>
                      <div className={healthStyle['eventImg']} 
                        style={{ backgroundImage: element.status24Hr.behavior==='abnormal'? `url(${device_alert})`:`url(${device_normal})`}}>
                      </div>
                      {/* <img src={errorNote} className={healthStyle['eventNote']} /> */}
                      <Grid container style={{marginLeft: '15px'}}>
                        <Grid item xs={12} className={healthStyle['eventTitle']}>行為狀況</Grid>
                        <Grid item xs={12} className={healthStyle['eventContext']}>{element.status24Hr.behavior==='abnormal'?'異常': element.status24Hr.behavior==='normal'?'正常':'無資料'}</Grid>
                      </Grid>
                    </div>
                  </div>
                </Grid>
              </Grid>
              <div style={{paddingRight: '16px'}}>
                <hr style={{border: '1px solid #EBEBEB'}}/>
              </div>
              {/* <Grid container style={{padding: '12px 100px', justifyContent: 'flex-start', width: '100%'}}>
                <Grid item style={{ width: '75%'}}>
                  <Grid container>
                    <Grid item>
                      <Box className={` ${healthStyle['summaryTagStyle']} ${summaryTag ===0? healthStyle['tagSelected'] : healthStyle['tagUnselected']} `}
                          onClick={() => handleChangeTag(0)}>
                        <Typography className={healthStyle['summaryTagText']}>上一次</Typography> 
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box className={` ${healthStyle['summaryTagStyle']} ${summaryTag ===1? healthStyle['tagSelected'] : healthStyle['tagUnselected']} `}
                          onClick={() => handleChangeTag(1)}>
                        <Typography className={healthStyle['summaryTagText']}>最近7天</Typography> 
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box className={` ${healthStyle['summaryTagStyle']} ${summaryTag ===2? healthStyle['tagSelected'] : healthStyle['tagUnselected']} `}
                          onClick={() => handleChangeTag(2)}>
                        <Typography className={healthStyle['summaryTagText']}>最近30天</Typography> 
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item style={{ width: '25%'}}>
                  <Grid container justifyContent="end">
                    <Box className={`${healthStyle['tagOpenPage']} `} onClick={() => handleClickPage("summary")}>
                      <Grid item style={{marginRight: '5px'}}>
                        <img src={newOpen} alt="openInNew"/>
                      </Grid>
                      <Grid item>
                        <Typography>更多資訊</Typography> 
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Grid> */}
              
              <Grid container style={{minHeight: 'calc(70% - 100px)'}}> 
                <Grid item style={{justifyContent: 'center',display: 'inline-flex', flexWrap: 'wrap', width: '100%', paddingBottom: '6px'}}> 
                  <div style={{minWidth: timeTagWidth+'px'}} className={` ${healthStyle['summaryTimeStyle']}`}>
                    <Grid container>
                      <Grid item style={{width: "70%", position:'relative', left: '-15px'}}><Grid container>
                        <Grid item>
                          <Box className={` ${healthStyle['summaryTagStyle']} ${summaryTag ===0? healthStyle['tagSelected'] : healthStyle['tagUnselected']} `}
                                onClick={() => handleChangeTag(0)}>
                              <Typography className={healthStyle['summaryTagText']}>上一次</Typography> 
                            </Box>
                        </Grid>
                        <Grid item>
                          <Box className={` ${healthStyle['summaryTagStyle']} ${summaryTag ===1? healthStyle['tagSelected'] : healthStyle['tagUnselected']} `}
                              onClick={() => handleChangeTag(1)}>
                            <Typography className={healthStyle['summaryTagText']}>最近7天</Typography> 
                          </Box>
                        </Grid>
                        <Grid item>
                          <Box className={` ${healthStyle['summaryTagStyle']} ${summaryTag ===2? healthStyle['tagSelected'] : healthStyle['tagUnselected']} `}
                              onClick={() => handleChangeTag(2)}>
                            <Typography className={healthStyle['summaryTagText']}>最近30天</Typography> 
                          </Box>
                        </Grid>
                      </Grid></Grid>
                      <Grid item style={{width: "30%", position:'relative', right: '-5px'}}><Grid container justifyContent="end">
                        <Box className={`${healthStyle['tagOpenPage']} `} onClick={() => handleClickPage("summary")}>
                          <Grid item style={{marginRight: '5px'}}>
                            <img src={newOpen} alt="openInNew"/>
                          </Grid>
                          <Grid item>
                            <Typography>更多資訊</Typography> 
                          </Grid>
                        </Box>
                      </Grid></Grid>
                    </Grid>
                  </div>
                  {/* <div className={` ${healthStyle['summaryTimeStyleR']}`}>
                    <Grid container justifyContent="end">
                      <Box className={`${healthStyle['tagOpenPage']} `} onClick={() => handleClickPage("summary")}>
                        <Grid item style={{marginRight: '5px'}}>
                          <img src={newOpen} alt="openInNew"/>
                        </Grid>
                        <Grid item>
                          <Typography>更多資訊</Typography> 
                        </Grid>
                      </Box>
                    </Grid>
                  </div> */}
                  <div style={{minWidth: blockWidth+'px'}}
                    className={` ${healthStyle['summaryBlockStyle']} ${element.healthData.pressure.warn===true? healthStyle['summaryAlert']: element.healthData.pressure.warn===false? healthStyle['summaryWell']:healthStyle['summaryNull']} `}>
                    <div>
                      <Grid container style={{height: '60px', padding: '5px', justifyContent: 'space-between'}}>
                        <Grid item >
                          <Grid item xs={12} className={healthStyle['eventTitle']}>血壓</Grid>
                          <Grid item xs={12} className={healthStyle['eventTime']}>{element.healthData.pressure.time}</Grid>
                        </Grid>
                        <Grid item>
                          <div className={healthStyle['eventImg']} 
                            style={{ backgroundImage: element.healthData.pressure.warn? `url(${pressure_alert})`: `url(${pressure_normal})`}}>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                    <div className={healthStyle['summaryDataBox']}>
                      <Grid container>
                        <Grid item>
                          <div className={healthStyle['summaryDataTitle']}>收縮</div>
                          <div className={lowSBP<=element.healthData.pressure.sbp && element.healthData.pressure.sbp<=highSBP? healthStyle['summaryDataWell']:healthStyle['summaryDataAlert']}>{element.healthData.pressure.sbp}</div>
                        </Grid>
                        <Grid item style={{marginLeft:'50px'}}>
                          <div className={healthStyle['summaryDataTitle']}>舒張</div>
                          <div className={lowDBP<=element.healthData.pressure.dbp && element.healthData.pressure.dbp<=highDBP? healthStyle['summaryDataWell']:healthStyle['summaryDataAlert']}>{element.healthData.pressure.dbp}</div>
                        </Grid>
                      </Grid>
                    </div>
                    <div className={healthStyle['summaryDataUnit']}>
                      <div className={healthStyle['unitText']}>mmHg</div>
                    </div>
                  </div>
                  <div style={{minWidth: blockWidth+'px'}}
                    className={` ${healthStyle['summaryBlockStyle']} ${element.healthData.pluse.warn===true? healthStyle['summaryAlert']: element.healthData.pluse.warn===false?healthStyle['summaryWell']:healthStyle['summaryNull']} `}>
                    <div>
                      <Grid container style={{height: '60px', padding: '5px', justifyContent: 'space-between'}}>
                        <Grid item >
                          <Grid item xs={12} className={healthStyle['eventTitle']}>心率</Grid>
                          <Grid item xs={12} className={healthStyle['eventTime']}>{element.healthData.pluse.time}</Grid>
                        </Grid>
                        <Grid item>
                          <div className={healthStyle['eventImg']}
                            style={{ backgroundImage: element.healthData.pluse.warn? `url(${heart_alert})`: `url(${heart_normal})`}}>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                    <div className={healthStyle['summaryDataBox']}>
                      <Grid container>
                        <Grid item>
                          <div className={healthStyle['summaryDataTitle']}></div>
                          <div className={lowPluse<=element.healthData.pluse.rate && element.healthData.pluse.rate<=highPluse? healthStyle['summaryDataWell']:healthStyle['summaryDataAlert']}>{element.healthData.pluse.rate}</div>
                        </Grid>
                      </Grid>
                    </div>
                    <div className={healthStyle['summaryDataUnit']}>
                      <div className={healthStyle['unitText']}>bpm</div>
                    </div>
                  </div>

                  <div style={{minWidth: blockWidth+'px', marginTop: '5px'}}
                    className={` ${healthStyle['summaryBlockStyle']} ${healthStyle['summaryWell']} `} >
                    <div>
                      <Grid container style={{height: '60px', padding: '5px', justifyContent: 'space-between'}}>
                        <Grid item >
                          <Grid item xs={12} className={healthStyle['eventTitle']}>血糖</Grid>
                          <Grid item xs={12} className={healthStyle['eventTime']}>2024/08/03 17:00</Grid>
                        </Grid>
                        <Grid item>
                          <div className={healthStyle['eventImg']} 
                            style={{ backgroundImage: `url(${sugar_normal})`}}>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                    <div className={healthStyle['summaryDataBox']}>
                      <Grid container>
                        <Grid item>
                          <div className={healthStyle['summaryDataTitle']}>飯前</div>
                          <div className={healthStyle['summaryDataWell']}>{element.healthData.sugar.before}</div>
                        </Grid>
                        <Grid item style={{marginLeft:'50px'}}>
                          <div className={healthStyle['summaryDataTitle']}>飯後</div>
                          <div className={healthStyle['summaryDataWell']}>{element.healthData.sugar.after}</div>
                        </Grid>
                      </Grid>
                    </div>
                    <div className={healthStyle['summaryDataUnit']}>
                      <div className={healthStyle['unitText']}>mg/dL</div>
                    </div>
                  </div>

                  <div style={{minWidth: blockWidth+'px', marginTop: '5px'}}
                    className={` ${healthStyle['summaryBlockStyle']} ${healthStyle['summaryWell']} `}>
                    <div>
                      <Grid container style={{height: '60px', padding: '5px', justifyContent: 'space-between'}}>
                        <Grid item >
                          <Grid item xs={12} className={healthStyle['eventTitle']}>睡眠時間</Grid>
                          <Grid item xs={12} className={healthStyle['eventTime']}>2024/08/07 08:05</Grid>
                        </Grid>
                        <Grid item>
                          <div className={healthStyle['eventImg']} 
                            style={{ backgroundImage: `url(${sleep_normal})`}}>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                    <div className={healthStyle['summaryDataBox']}>
                      <Grid container>
                        <Grid item>
                          <div className={healthStyle['summaryDataTitle']}></div>
                          <div className={healthStyle['summaryDataWell']}>{element.healthData.sleep.hr}</div>
                        </Grid>
                      </Grid>
                    </div>
                    <div className={healthStyle['summaryDataUnit']}>
                      <div className={healthStyle['unitText']}>Hr</div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
            ))
          }
        </CardContent>
      }
    </>
  );
};

export default TabContentVer;
