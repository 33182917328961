import "./avatarGroup.component.scss";

const AvatarGroup = ({ avatarContainerMargin, avatarWidthAndHeight, data, length, condition }) => (
  <div style={{ margin: avatarContainerMargin }} className="avatarGroup">
    {
      Array.isArray(data)
        ? data.map((name, index) => (
          index <= length && <img
            style={{ width: avatarWidthAndHeight, height: avatarWidthAndHeight, marginLeft: index === 0 ? 0 : '-15px' }}
            className="avatar"
            key={index}
            src={condition[name]}
            alt={name}
          />
        ))
        : <img
          style={{ width: avatarWidthAndHeight, height: avatarWidthAndHeight }}
          className="avatar"
          src={condition[data]}
          alt={data}
        />
    }
    {data.length > length && <span className="avatar more">+{length}</span>}
  </div>
);

export default AvatarGroup;