import React, { useRef } from 'react';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import FieldContainer from "./FieldContainer";

import { ReactComponent as InputClearBtn } from 'assets/img/icon/btn_input_clear.svg';

export const Input = ({ id, placeholder, value, maxLength, onChange, onKeyDown, iconClick, helperText, inputRef, verificationCodeState }) => (
  <TextField
    sx={{
      '& .MuiOutlinedInput-root': {
        height: '40px',
        borderRadius: '10px',
        margin: verificationCodeState ? '0 20px' : 'none',
        '& fieldset': {
          borderColor: '#EEF0F2',
        }
      },
    }}
    InputProps={{
      endAdornment: !verificationCodeState && value ? (<InputAdornment position="end">
        <IconButton aria-label="clear-input" onClick={iconClick}>
          <InputClearBtn />
        </IconButton>
      </InputAdornment>) : null,
    }}
    inputProps={{ maxLength: maxLength, style: { textAlign: verificationCodeState ? 'center' : 'none' } }}
    id={id}
    variant="outlined"
    value={value}
    placeholder={placeholder}
    onChange={onChange}
    onKeyDown={onKeyDown}
    error={verificationCodeState ? helperText : !!helperText}
    helperText={verificationCodeState ? '' : helperText}
    inputRef={inputRef}
  />
);

export const VerificationCodeInputField = ({ label, value, setValue, helperText }) => {
  const inputsRef = useRef([]);

  function handleChange(e, index) {
    const newValues = [...value];
    newValues[index] = e.target.value;

    setValue(newValues);

    if (e.target.value && index < newValues.length - 1) {
      inputsRef.current[index + 1].focus();
    }
  };

  function handleKeyDown(e, index) {
    if (e.key === 'Backspace') {
      if (!value[index] && index > 0) {
        inputsRef.current[index - 1].focus();
        e.preventDefault();
      }
    }

    if (e.key === 'ArrowLeft' && index > 0) {
      inputsRef.current[index - 1].focus();
      e.preventDefault();
    }

    if (e.key === 'ArrowRight' && index < value.length - 1) {
      inputsRef.current[index + 1].focus();
      e.preventDefault();
    }
  }

  return (
    <FieldContainer label={label}>
      <Box sx={{ minWidth: 180 }} noValidate autoComplete="off" display="flex" justifyContent="space-between">
        {value.map((value, index) => (
          <FormControl key={index}>
            <Input
              id={`input-${index}`}
              type="number"
              value={value}
              onChange={(e) => handleChange(e, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              maxLength="1"
              helperText={helperText}
              inputRef={(element) => inputsRef.current[index] = element}
              verificationCodeState
            />
          </FormControl>
        ))}
      </Box>
      <Typography sx={{ fontSize: '0.75rem', color: '#d32f2f', margin: '3px 14px 0', }}>{helperText}</Typography>
    </FieldContainer>
  )
}


const InputField = ({ label, id, placeholder, value, maxLength, onChange, iconClick, helperText }) => (
  <FieldContainer label={label}>
    <Box sx={{ minWidth: 180 }} noValidate autoComplete="off">
      <FormControl fullWidth>
        <Input
          id={id}
          placeholder={placeholder}
          value={value}
          maxLength={maxLength}
          onChange={onChange}
          iconClick={iconClick}
          helperText={helperText}
        />
      </FormControl>
    </Box>
  </FieldContainer>
);

export default InputField;