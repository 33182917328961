import mainStyle from "./event.module.scss";
import { useState, useEffect, React, useRef } from "react";
import { useOutletContext } from "react-router-dom";
import { useWebSocket } from "hook/WebSocketContext";
import { useAuth } from "../../hook/AuthContext";
import { cloneDeep } from "lodash";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Skeleton from "@mui/material/Skeleton";

import { ReactComponent as Upper } from "assets/img/icon/btn_arrow_upper.svg";
import { ReactComponent as Down } from "assets/img/icon/btn_arrow_down.svg";

import dayjs from "dayjs";

import { PATH } from "router/config/const";
import Breadcrumb from "components/Breadcrumb";
import { useNotification } from "../../hook/NotificationContext";
import { selectDate, categoryMapping } from "./const";
import { printDatesInRange } from "./controller";
import CustomDatePicker from "./components/CustomDatePicker";
import CustomSelect from "./components/CustomSelect";

const Event = () => {
  const [wsData, setWsData] = useOutletContext();
  const {
    unreadCount,
    setUnReadCount,
    handleMessage,
    generateMessages,
    eventMessages,
    setEventMessages,
    eventData,
    setEventData,
  } = useNotification();

  const [list, setList] = useState(0);
  const messagesToUpdateRef = useRef([]);
  const ws = useWebSocket();
  const { token } = useAuth();
  const [startDate, setStartDate] = useState(dayjs().subtract(15, "day"));
  const startOfStartDate = startDate.startOf("day");
  const startDateTimestamp = startOfStartDate.unix();

  const [endDate, setEndDate] = useState(dayjs());
  const endOfEndDate = endDate.endOf("day");
  const endDateTimestamp = endOfEndDate.unix();

  const currentDate = dayjs();
  const [open, setOpen] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectOpen, setSelectOpen] = useState(false);
  const [category, setCategory] = useState(
    "reportAbnormal, notTakeBloodPressure, notTakeMedicine, abuse, falldown, sleepingSickness"
  );
  const previousYearSameDate = currentDate.subtract(365, "day");

  const dates = printDatesInRange(startDate, endDate);
  dates.sort((a, b) => new Date(b) - new Date(a));

  const handleChange = (event) => {
    const selectedCategory = selectDate.find(
      (item) => item.id === event.target.value
    ).content;

    const categories = categoryMapping[selectedCategory] || "";

    setCategory(categories);
    updateEvent();
    setList(event.target.value);
  };

  const handleStartDateChange = (newValue) => {
    const adjustedStartDate = newValue.isBefore(previousYearSameDate)
      ? previousYearSameDate
      : newValue;

    setStartDate(adjustedStartDate);
    const newEndDate = adjustedStartDate.add(15, "day").isAfter(currentDate)
      ? currentDate
      : adjustedStartDate.add(15, "day");
    setEndDate(newEndDate);
  };

  const handleEndDateChange = (newValue) => {
    const adjustedEndDate = newValue.isBefore(previousYearSameDate)
      ? previousYearSameDate
      : newValue;

    setEndDate(adjustedEndDate);
    const newStartDate = adjustedEndDate
      .subtract(15, "day")
      .isBefore(previousYearSameDate)
      ? previousYearSameDate
      : adjustedEndDate.subtract(15, "day");
    setStartDate(newStartDate);
  };

  const toggleCollapse = (index) => {
    if (open === index) {
      setOpen(null);
      updateEvent();
    } else {
      setOpen(index);
      if (open !== null) {
        updateEvent();
      }
    }
  };

  const handleOpen = () => {
    setSelectOpen(true);
  };

  const handleClose = () => {
    setSelectOpen(false);
  };

  useEffect(() => {
    const todayIndex = dates.findIndex(
      (date) => date === currentDate.format("MMMM DD YYYY")
    );
    setOpen(todayIndex);
  }, []);

  const updateEvent = () => {
    const messagesToUpdate = messagesToUpdateRef.current;

    const eventUpdateData = {
      resource: `/boxes/0/events`,
      verb: "update",
      accessToken: token,
      data: messagesToUpdate,
    };

    ws.send(JSON.stringify(eventUpdateData));
    localStorage.setItem("ackCount", 0);
    const localAckCount = parseInt(localStorage.getItem("ackCount"), 10);
    setUnReadCount(localAckCount);
  };

  //exit event page
  useEffect(() => {
    const currentDate = dates[open];
    const newMessagesToUpdate = (eventMessages.data || [])
      .filter(
        (message) =>
          dayjs.unix(message.timestamp).format("MMMM DD YYYY") ===
            currentDate && message.ack === false
      )
      .map((message) => ({
        id: message.id,
        ack: true,
      }));

    messagesToUpdateRef.current = newMessagesToUpdate;
  }, [dates, eventMessages, open]);

  useEffect(() => {
    return () => {
      updateEvent();
    };
  }, []);

  //init event data
  const getData = async () => {
    const eventSendData = {
      resource: `/boxes/0/events`,
      verb: "read",
      accessToken: token,
      data: {
        filter: {
          startTime: startDateTimestamp,
          endTime: endDateTimestamp,
          category: category,
        },
      },
    };
    ws.send(JSON.stringify(eventSendData));
  };

  useEffect(() => {
    getData();
  }, [category, startDateTimestamp, endDateTimestamp]);

  useEffect(() => {
    if (wsData.updateTag === "event_read" && wsData.updateData) {
      console.log("event read ws", wsData.updateData);

      const filteredData = wsData.updateData.data
        ? wsData.updateData.data.filter(
            (event) =>
              event.type !== "takeMedicine" &&
              event.type !== "takeBloodPressure"
          )
        : [];

      const filteredWsData = {
        ...wsData.updateData,
        data: filteredData,
      };

      setEventData(filteredWsData);
      setIsLoading(false);
      const initMessages = generateMessages(filteredWsData);
      setEventMessages(initMessages);
    } else if (wsData.updateTag === "event_update" && wsData.updateData) {
      console.log("event update ws", wsData.updateData);
      const updatedEventMessages = (eventMessages.data || []).map((event) => {
        const update = wsData.updateData.find(
          (update) => update.id === event.id
        );
        if (update) {
          return {
            ...event,
            ack: update.ack,
          };
        }
        return event;
      });

      setEventMessages({ ...eventMessages, data: updatedEventMessages });
    } else if (wsData.updateTag === "abnormalEvent" && wsData.updateData) {
      console.log("abnormalEvent update ws", wsData.updateData);
      addNewData(cloneDeep(wsData.updateData));
    }
  }, [wsData]);

  // console.log(eventMessages);

  const addNewData = (newData) => {
    const updatedData = cloneDeep(eventData.data || []);
    const message = handleMessage(newData);
    const newEntry = {
      ...newData,
      message,
    };

    updatedData.push(newEntry);
    setEventData({ ...eventData, data: updatedData });
    const updatedMessages = generateMessages({
      ...eventData,
      data: updatedData,
    });
    setEventMessages(updatedMessages);
  };

  return (
    <>
      <Breadcrumb path={PATH.event}></Breadcrumb>
      <Box sx={{ display: "flex", width: "100%" }}>
        <Card style={{ borderRadius: "20px", width: "100%", height: "100%" }}>
          <CardContent>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <Grid item>
                <Typography
                  style={{
                    fontSize: "large",
                    color: "#058aab",
                    fontWeight: "600",
                  }}
                >
                  Event & Alert History
                </Typography>
              </Grid>
              <Grid item>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item>
                    <CustomDatePicker
                      value={startDate}
                      onChange={handleStartDateChange}
                      maxDate={currentDate}
                      minDate={previousYearSameDate}
                    />
                  </Grid>
                  <Grid item>to</Grid>
                  <Grid item>
                    <CustomDatePicker
                      value={endDate}
                      onChange={handleEndDateChange}
                      maxDate={currentDate}
                      minDate={previousYearSameDate}
                    />
                  </Grid>
                  <Grid item>
                    <CustomSelect
                      value={list}
                      onChange={handleChange}
                      onOpen={handleOpen}
                      onClose={handleClose}
                      selectOpen={selectOpen}
                      options={selectDate}
                      label="Select scene type"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container spacing={3} sx={{ paddingTop: "20px" }}>
              {isLoading
                ? Array.from({ length: 5 }).map((_, index) => (
                    <Grid item xs={12} key={index}>
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <Skeleton
                          variant="rectangular"
                          width={40}
                          height={24}
                        />
                        <Skeleton variant="text" width="100%" />
                      </Stack>
                    </Grid>
                  ))
                : dates.map((date, index) => (
                    <Grid item xs={12} key={index}>
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <IconButton
                          edge="start"
                          aria-label={`toggle-details-${date}`}
                          onClick={() => toggleCollapse(index)}
                          sx={{ width: 40, color: "#0087a8" }}
                        >
                          {open === index ? <Down /> : <Upper />}
                        </IconButton>
                        <Typography sx={{ color: "#0087a8", fontWeight: 600 }}>
                          {date}
                        </Typography>
                      </Stack>
                      {index < dates.length - 1 && <Divider />}
                      <Collapse in={open === index}>
                        <div style={{ paddingLeft: "55px" }}>
                          <Typography
                            component="div"
                            style={{
                              width: "100%",
                              lineHeight: 3,
                            }}
                          >
                            {(eventMessages.data || [])
                              .filter(
                                (item) =>
                                  dayjs
                                    .unix(item.timestamp)
                                    .format("MMMM DD YYYY") === date
                              )
                              .sort(
                                (a, b) =>
                                  dayjs.unix(b.timestamp) -
                                  dayjs.unix(a.timestamp)
                              )
                              .map((item, idx) => {
                                const message = item.message;
                                const isNew = !item.ack;
                                return (
                                  <div
                                    key={idx}
                                    style={{
                                      display: "flex",
                                      flexDirection: "column",
                                      width: "100%",
                                    }}
                                  >
                                    <div
                                      style={{ display: "flex", width: "100%" }}
                                    >
                                      <span
                                        style={{
                                          color: "#0087a8",
                                          marginRight: "30px",
                                          fontWeight: isNew ? "bold" : "normal",
                                        }}
                                      >
                                        {dayjs
                                          .unix(item.timestamp)
                                          .format("HH:mm")}
                                      </span>
                                      <span
                                        style={{
                                          color: "#7d8299",
                                          fontWeight: isNew ? "bold" : "normal",
                                        }}
                                      >
                                        {message}
                                      </span>
                                    </div>
                                    <Divider />
                                  </div>
                                );
                              })}
                            {(eventMessages.data || []).some(
                              (item) =>
                                dayjs
                                  .unix(item.timestamp)
                                  .format("MMMM DD YYYY") === date
                            ) ? null : (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  width: "100%",
                                }}
                              >
                                <div style={{ display: "flex", width: "100%" }}>
                                  <span
                                    style={{
                                      color: "#7d8299",
                                      fontWeight: "normal",
                                    }}
                                  >
                                    No data.
                                  </span>
                                </div>
                                <Divider />
                              </div>
                            )}
                          </Typography>
                        </div>
                      </Collapse>
                    </Grid>
                  ))}
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default Event;
