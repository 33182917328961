export const INIT_CREATE_HOME_GROUP_DATA = {
  portrait: '',
  name: '',
  address: '',
  pageCode: Array(4).fill(""),
  description: '',
  creatorId: ''
}

export const INIT_CREATE_HOME_GROUP_FIELDS_VALID = {
  portrait: '',
  name: '',
  address: '',
  pageCode: '',
  description: '',
  creatorId: ''
}

export const CHECK_CREATE_HOME_GROUP_FIELDS = [
  { field: 'name', message: 'This field is required.' },
  { field: 'address', message: 'This field is required.' },
  { field: 'pageCode', message: 'This field is required.' }
];