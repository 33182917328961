import React, { useState, useEffect } from "react";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
// images
import blueDot from "assets/img/icon/btn_pagecontrol_dots_blue.svg";
import grayDot from "assets/img/icon/btn_pagecontrol_dots_grey.svg";

const Carousel = ({ currentPage, setCurrentPage, numPages }) => {
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <Grid
      container
      justifyContent="center"
      style={{ position: "relative" }}
      spacing={3}
    >
      {[...Array(numPages)].map((_, index) => (
        <Grid item key={index}>
          <img
            src={currentPage === index ? blueDot : grayDot}
            alt={currentPage === index ? "Blue Dot" : "Gray Dot"}
            style={{ cursor: "pointer" }}
            onClick={() => handlePageChange(index)}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default Carousel;
