import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Skeleton from '@mui/material/Skeleton';

export const renderSkeletonListItems = (count) => {
  return Array.from(new Array(count)).map((_, index) => (
    <div key={index}>
      <ListItem style={{ marginTop: "10px", marginBottom: "10px" }}>
        <ListItemIcon>
          <Skeleton variant="circular" width={40} height={40} />
        </ListItemIcon>
        <ListItemText
          primary={<Skeleton width="60%" />}
          secondary={<Skeleton width="40%" />}
        />
        <Skeleton variant="rectangular" width={40} height={24} />
      </ListItem>
      {index < count - 1 && <Divider />}
    </div>
  ));
};
