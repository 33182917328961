import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as OpenInNew } from "assets/img/icon/btn_external_expand.svg";

const SmartCard = ({ children, title }) => {
  return (
    <Card
      className="card"
      variant="outlined"
      style={{ position: "relative", borderRadius: "20px" }}
    >
      <Typography
        variant="h5"
        component="div"
        color="#0A87A9"
        style={{
          padding: "25px 0 0 25px",
          fontWeight: 600,
          lineHeight: "1.11",
        }}
      >
        {title}
      </Typography>
      <CardContent
        style={{
          /*  display: "flex", */
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          height: "320px",
        }}
      >
        {children}
      </CardContent>
      <IconButton
        disableRipple
        sx={{
          "&:hover": {
            cursor: "not-allowed",
          },
        }}
        style={{ position: "absolute", top: 8, right: 12 }}
      >
        <OpenInNew />
      </IconButton>
    </Card>
  );
};

export default SmartCard;
