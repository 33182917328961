import React, { useState, useEffect } from "react";
import healthStyle from "./health.module.scss";
import { useSelector, useDispatch } from "react-redux";
import { cloneDeep, isNil } from "lodash";
import { useOutletContext } from "react-router-dom";
import { useWebSocket } from "hook/WebSocketContext";
import { useAuth } from "../../../hook/AuthContext";
import {Dimensions} from 'react-native-web';
import Spinner from 'components/Spinner';
import MobileVer from "./components/MobileVer";
import TabContentVer from "./components/TabContentVer";
import SliderVer from "./components/SliderVer";

import defaultPhoto from "assets/img/icon/icn_nav_user.svg";

const Healthcare = (props) => {
  const { user, wsData, setWsData, isMobilePPI, 
    hideCalendar, setHideCalendar
  } = props;
  const ws = useWebSocket();
  const { token } = useAuth();
  const [loading, setLoading] = useState(true);
  const [openSpinner, setOpenSpinner] = useState(false);
  const [groupData, setGroupData] = useState([]);

  const makeGroupData = async (data) => {
    // console.log('makeGroupData')
    // console.log(data)
    let tmpGroupAry = [];//cloneDeep(groupData);
    let count = 0;
    data.members.forEach((subdata, index) => {
      let tmpObj = {
        key: subdata.userId,
        homeId: data.homeGroupId, 
        userId: subdata.userId,
        name: subdata.name,
        photo: subdata.portrait,
        role: subdata.role,
        email: subdata.email,
        note: false,
        noteSrc: '',
        status24Hr: {
          total: 'noData',
          pressure: 'noData',
          medicine: 'noData',
          heart: 'noData',
          behavior: 'noData',
        },
        healthData: {
          pressure:{ time:'--', sbp: '--', dbp:'--', warn: false},
          pluse:{ time:'--', rate:'--', warn: false},
          sugar:{ time:'', before: 100, after: 90, warn: false },
          sleep:{ time:'', hr: 7.5, warn: false },
        }
      }
      if(tmpObj.photo === ''){
        tmpObj.photo = defaultPhoto;
      }
      tmpGroupAry.push(tmpObj);
      count++;
    })

    let sortGroup = tmpGroupAry.sort(sortId)
    setGroupData(sortGroup);
    setOpenSpinner(false);
  }

  function sortId(a,b){
    return a.userId-b.userId;
  }

  const getData = async () => {
    const groupSendData = {
      resource: `/homegroups/${user.lastUsedHomegroupId}/members/Elder`,
      verb: 'read',
      accessToken: token,
    };
    setOpenSpinner(true)
    ws.send(JSON.stringify(groupSendData));
  };

  useEffect(() => {
    if(Object.keys(user).length !== 0){
      console.log('Healthcare get userInfo')
      console.log(user)
      getData();
    }
  }, [user]);

  useEffect(() => {
    if (wsData.updateTag === 'homegroups_read' && wsData.updateData) {
      console.log('dashboard homegroup get ws');
      console.log(wsData.updateData);
      makeGroupData(cloneDeep(wsData.updateData));

    }else{}
  }, [wsData]);
  

  return (
    <>
      {/* {showTabScreen && <>
        <SliderVer />
      </>} */}

      { isMobilePPI && <>
        <MobileVer groupData={groupData} setGroupData={setGroupData} />
      </>}
      {!isMobilePPI && <>
        {!hideCalendar && 
          <TabContentVer wsData={wsData} setWsData={setWsData} user={user}
            hideCalendar={hideCalendar} setHideCalendar={setHideCalendar}
            setOpenSpinner={setOpenSpinner} groupData={groupData} setGroupData={setGroupData}
          />
        }
        {hideCalendar && 
          <SliderVer wsData={wsData} setWsData={setWsData} user={user}
            hideCalendar={hideCalendar} setHideCalendar={setHideCalendar}
            groupData={groupData} setGroupData={setGroupData}
          />
        }
      </>}
      <Spinner open={openSpinner}/>       
    </>
  );
};

export default Healthcare;
