import React from 'react';
import recipientStyle from '../recipient.module.scss';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';

import { ReactComponent as RemovedBtn } from 'assets/img/icon/btn_removed.svg';
import { ReactComponent as AddBtn } from 'assets/img/icon/btn_add.svg';

import { TimePickerInput } from "./TimePickerField";


const AddTimeField = ({ timeList, removedIconClick, timeListId, timePickerValue, timePickerOnChange, addIconClick, timePickerHelperText }) => (
    <>
        <Grid container justifyContent="flex-start" alignItems="center" sx={{ margin: '20px 0' }} spacing={2}>
            {
                timeList?.map((item, index) => {
                    const [hours, minutes] = item.split(':');
                    return (
                        <Grid item xs={3} key={`${timeListId}-${index}`} style={{ paddingLeft: '0' }}>
                            <label className={recipientStyle.labelField}>
                                {hours}:{minutes}
                            </label>
                            <IconButton aria-label="removed-icon" onClick={() => removedIconClick('remove', item)}>
                                <RemovedBtn />
                            </IconButton>
                        </Grid>
                    )
                })
            }
        </Grid>
        <Grid container justifyContent="flex-start" alignItems="center" sx={{ margin: '20px 0' }} spacing={2}>
            <Grid item xs={6} style={{ paddingLeft: '0' }}>
                <Box sx={{ minWidth: 180 }}>
                    <FormControl fullWidth>
                        <TimePickerInput
                            value={timePickerValue}
                            onChange={timePickerOnChange}
                            views={['hours', 'minutes']}
                            format="HH:mm"
                            isAMPM={false}
                            helperText={timePickerHelperText}
                        />
                    </FormControl>
                </Box>
            </Grid>
            <Grid item xs={6}>
                <Box sx={{ minWidth: 180 }}>
                    <IconButton aria-label="add-icon" onClick={addIconClick}>
                        <AddBtn />
                    </IconButton>
                </Box>
            </Grid>
        </Grid>
    </>
);

export default AddTimeField;