import React from 'react';

import recipientStyle from './recipient.module.scss';

import { useMediaQuery, useTheme } from "@mui/material";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

import excellentState from "assets/img/icon/icn_profile_excellent.svg";
import errorState from "assets/img/icon/icn_profile_error.svg";

import { UserData } from "./const"

const RecipientSidebar = ({ ws, addScheduleData, generateCronExpression, checkIfFieldIsRequired, token }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(1280));

  function handleAddScheduleClick() {
    const newPatterns = generateCronExpression(addScheduleData[0])
    addScheduleData[0].patterns = newPatterns

    const isValid = checkIfFieldIsRequired(addScheduleData[0])
    // user api 還沒開發先寫假資料
    addScheduleData[0].username = "Father"

    const requestCreateOneCron = {
      resource: "/users/0/crons",
      verb: "create",
      accessToken: token,
      data: addScheduleData[0],
    }

    if (!isValid) {
      ws.send(JSON.stringify(requestCreateOneCron));
    }
  }

  return (
    <>
      {
        UserData.map((item, index) => (
          <Box sx={{ display: 'flex', width: '100%' }} key={`user-card-${index}`}>
            <Card
              sx={{
                marginBottom: 5,
                marginRight: isSmallScreen ? 5 : 0,
                borderRadius: '20px',
                borderColor: item.state ? '#C2D8E1' : '#EFF1F3',
                backgroundColor: item.state ? '#F5F8FF' : '#FFFFFF',
                width: '100%',
                maxWidth: '300px',
              }}
              variant="outlined"
            >
              <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Grid container spacing={8} sx={{ alignItems: 'center' }}>
                    <Grid item xs={4}>
                      <div className={recipientStyle.profileImage} style={{ borderColor: item.state ? '#51D1C6' : '#CA6A6D', backgroundImage: `url(${item.picture})` }}>
                        <span className={recipientStyle.profileState} style={{ backgroundImage: `url(${item.state ? excellentState : errorState})` }} />
                      </div>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography sx={{ color: '#0A87A9', fontWeight: 600, lineHeight: 1.11, fontSize: '18px' }} component="div" variant="h6">
                        {item.name}
                      </Typography>
                      <Typography sx={{ whiteSpace: 'nowrap', color: '#737791', lineHeight: 1.25, fontSize: '16px' }} component="div">
                        {item.personalInfo}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                {
                  item.state ?
                    null
                    : <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 2 }}>
                      <Stack direction="row" spacing={2}>
                        <Button
                          sx={{
                            backgroundColor: '#0A87A9',
                            borderRadius: '10px',
                            color: '#FFFFFF',
                            lineHeight: 1.25,
                            fontSize: '16px',
                            fontWeight: 600,
                            margin: '20px 10px 0 5px',
                            padding: '10px 16px',
                            '&:hover': {
                              backgroundColor: '#05647E',
                            }
                          }} variant="contained" onClick={handleAddScheduleClick}>Add Schedule</Button>
                      </Stack>
                    </Box>
                }
              </CardContent>
            </Card>
          </Box>
        ))}
    </>
  );
};

export default RecipientSidebar;