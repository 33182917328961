import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { ReactComponent as SelectDropdownDownBtn } from "assets/img/icon/btn_arrow_dropdown_down.svg";
import { ReactComponent as SelectDropdownUpperBtn } from "assets/img/icon/btn_arrow_dropdown_upper.svg";
const grayColor = "#BEBEBE";

const CustomSelect = ({
  value,
  onChange,
  onOpen,
  onClose,
  selectOpen,
  options,
  label,
  iconOpenComponent = SelectDropdownUpperBtn,
  iconCloseComponent = SelectDropdownDownBtn,
}) => {
  return (
    <FormControl style={{ minWidth: 300, marginTop: "7px" }}>
      <InputLabel id="custom-select-label">{label}</InputLabel>
      <Select
        value={value}
        onChange={onChange}
        onOpen={onOpen}
        onClose={onClose}
        label={label}
        inputProps={{ "aria-label": "Without label" }}
        IconComponent={selectOpen ? iconOpenComponent : iconCloseComponent}
        MenuProps={{
          sx: {
            "&& .Mui-selected": {},
            "&& .MuiMenuItem-root": {
              color: "#737791",
              "&:hover": {
                backgroundColor: "#d9e9f8",
              },
            },
            "&& .MuiMenuItem-root:hover": {
              backgroundColor: "#f7fafc",
            },
          },
          PaperProps: {
            sx: {
              borderRadius: "10px",
              boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.08)",
              "& .MuiMenuItem-root:first-of-type": {
                marginTop: "-8px",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
              },
              "& .MuiMenuItem-root:last-of-type": {
                marginBottom: "-8px",
                borderBottomLeftRadius: "10px",
                borderBottomRightRadius: "10px",
              },
            },
          },
        }}
        sx={{
          "& .MuiSelect-select": { color: "#737791" },
          "& .MuiSelect-icon": {
            top: "50%",
            transform: "translateY(-50%)",
          },
          "&.MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: grayColor,
              margin: "5px 0px",
            },
            "&:hover fieldset": { borderColor: grayColor },
            "&.Mui-focused fieldset": {
              borderColor: grayColor,
            },
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: grayColor,
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: grayColor,
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderRadius: "12px",
          },
        }}
      >
        {options.map((item, index) => (
          <MenuItem value={item.id} key={index}>
            {item.content}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomSelect;
