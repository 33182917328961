import React, { useState } from 'react';

const InviteUser = () => {

  return (
    <>
      this is a invite user page.
    </>
  );
};

export default InviteUser;