import { cloneDeep } from 'lodash';
import dayjs from 'dayjs';

const getDateYMD = (date) => {
  //get timestamp of 00:00
  let changeY = dayjs(date).format('YYYY');
  let changeM = dayjs(date).format('MM');
  let changeD = dayjs(date).format('DD');
  let changeDate = changeY+'-'+changeM+'-'+changeD;

  let change = dayjs(changeDate);
  return change;
}

const getDate0000 = (date) => {
  //get timestamp of 00:00
  let changeY = dayjs(date).format('YYYY');
  let changeM = dayjs(date).format('MM');
  let changeD = dayjs(date).format('DD');
  let changeDate = changeY+'-'+changeM+'-'+changeD;

  let stamp00 = parseInt(dayjs(changeDate).format('X'))
  return stamp00;
        
}

export default {
  getDateYMD,
  getDate0000
};
