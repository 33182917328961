import React, { createContext, useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { cloneDeep } from "lodash";
import dayjs from "dayjs";

const NotificationContext = createContext();

export const useNotification = () => useContext(NotificationContext);

export const NotificationProvider = ({ children }) => {
  const [eventMessages, setEventMessages] = useState([]);
  const [eventData, setEventData] = useState([]);
  const [messages, setMessages] = useState([]);
  const [unreadCount, setUnReadCount] = useState(0);
  const [newNotificationIds, setNewNotificationIds] = useState([]);

  const handleMessage = (data) => {
    let message = "";

    const { type, data: inputData } = data;
    const { username, cronName, systolic, diastolic, heartRate } = inputData;

    if (type === "reportAbnormal") {
      const combinations = [
        {
          systolic: "high",
          diastolic: "high",
          heartRate: "high",
          message: `${username}’s blood pressure is higher than usual and pulse is higher than usual. Professional medical intervention is needed to assess the condition and provide appropriate care.`,
        },
        {
          systolic: "high",
          diastolic: "high",
          heartRate: "low",
          message: `${username}’s blood pressure is higher than usual and pulse is lower than usual. Professional medical intervention is needed to assess the condition and provide appropriate care.`,
        },
        {
          systolic: "high",
          diastolic: "high",
          heartRate: "normal",
          message: `${username}’s blood pressure is higher than usual. Regular check and monitoring is required.`,
        },
        {
          systolic: "high",
          diastolic: "low",
          heartRate: "high",
          message: `${username}’s blood pressure is higher than usual and pulse is higher than usual. Professional medical intervention is needed to assess the condition and provide appropriate care.`,
        },
        {
          systolic: "high",
          diastolic: "low",
          heartRate: "low",
          message: `${username}’s blood pressure is higher than usual and pulse is lower than usual. Professional medical intervention is needed to assess the condition and provide appropriate care.`,
        },
        {
          systolic: "high",
          diastolic: "low",
          heartRate: "normal",
          message: `${username}’s blood pressure is higher than usual. Regular check and monitoring is required.`,
        },
        {
          systolic: "high",
          diastolic: "normal",
          heartRate: "high",
          message: `${username}’s blood pressure is higher than usual and pulse is higher than usual. Professional medical intervention is needed to assess the condition and provide appropriate care.`,
        },
        {
          systolic: "high",
          diastolic: "normal",
          heartRate: "low",
          message: `${username}’s blood pressure is higher than usual and pulse is lower than usual. Professional medical intervention is needed to assess the condition and provide appropriate care.`,
        },
        {
          systolic: "high",
          diastolic: "normal",
          heartRate: "normal",
          message: `${username}’s blood pressure is higher than usual. Regular check and monitoring is required.`,
        },
        {
          systolic: "low",
          diastolic: "high",
          heartRate: "high",
          message: `${username}’s blood pressure is higher than usual and pulse is higher than usual. Professional medical intervention is needed to assess the condition and provide appropriate care.`,
        },
        {
          systolic: "low",
          diastolic: "high",
          heartRate: "low",
          message: `${username}’s blood pressure is higher than usual and pulse is lower than usual. Professional medical intervention is needed to assess the condition and provide appropriate care.`,
        },
        {
          systolic: "low",
          diastolic: "high",
          heartRate: "normal",
          message: `${username}’s blood pressure is higher than usual. Regular check and monitoring is required.`,
        },
        {
          systolic: "low",
          diastolic: "low",
          heartRate: "high",
          message: `${username}’s blood pressure is lower than usual and pulse is higher than usual. Professional medical intervention is needed to assess the condition and provide appropriate care.`,
        },
        {
          systolic: "low",
          diastolic: "low",
          heartRate: "low",
          message: `${username}’s blood pressure is lower than usual and pulse is lower than usual. Professional medical intervention is needed to assess the condition and provide appropriate care.`,
        },
        {
          systolic: "low",
          diastolic: "low",
          heartRate: "normal",
          message: `${username}’s blood pressure is lower than usual. Regular check and monitoring is required.`,
        },
        {
          systolic: "low",
          diastolic: "normal",
          heartRate: "high",
          message: `${username}’s blood pressure is lower than usual and pulse is higher than usual. Professional medical intervention is needed to assess the condition and provide appropriate care.`,
        },
        {
          systolic: "low",
          diastolic: "normal",
          heartRate: "low",
          message: `${username}’s blood pressure is lower than usual and pulse is lower than usual. Professional medical intervention is needed to assess the condition and provide appropriate care.`,
        },
        {
          systolic: "low",
          diastolic: "normal",
          heartRate: "normal",
          message: `${username}’s blood pressure is lower than usual. Regular check and monitoring is required.`,
        },
        {
          systolic: "normal",
          diastolic: "high",
          heartRate: "high",
          message: `${username}’s blood pressure is higher than usual and pulse is higher than usual. Professional medical intervention is needed to assess the condition and provide appropriate care.`,
        },
        {
          systolic: "normal",
          diastolic: "high",
          heartRate: "low",
          message: `${username}’s blood pressure is higher than usual and pulse is lower than usual. Professional medical intervention is needed to assess the condition and provide appropriate care.`,
        },
        {
          systolic: "normal",
          diastolic: "high",
          heartRate: "normal",
          message: `${username}’s blood pressure is higher than usual. Regular check and monitoring is required.`,
        },
        {
          systolic: "normal",
          diastolic: "low",
          heartRate: "high",
          message: `${username}’s blood pressure is lower than usual and pulse is higher than usual. Professional medical intervention is needed to assess the condition and provide appropriate care.`,
        },
        {
          systolic: "normal",
          diastolic: "low",
          heartRate: "low",
          message: `${username}’s blood pressure is lower than usual and pulse is lower than usual. Professional medical intervention is needed to assess the condition and provide appropriate care.`,
        },
        {
          systolic: "normal",
          diastolic: "low",
          heartRate: "normal",
          message: `${username}’s blood pressure is lower than usual. Regular check and monitoring is required.`,
        },
        {
          systolic: "normal",
          diastolic: "normal",
          heartRate: "high",
          message: `${username}’s pulse is higher than usual. Regular check and monitoring is required.`,
        },
        {
          systolic: "normal",
          diastolic: "normal",
          heartRate: "low",
          message: `${username}’s pulse is lower than usual. Regular check and monitoring is required.`,
        },
        {
          systolic: "normal",
          diastolic: "normal",
          heartRate: "normal",
          message: `${username}’s blood pressure and pulse are within normal range. No specific action needed.`,
        },
        {
          systolic: "high",
          diastolic: "normal",
          heartRate: "normal",
          message: `${username}’s blood pressure is higher than usual. Regular check and monitoring is required.`,
        },
      ];

      message =
        combinations.find(
          (combo) =>
            (combo.systolic === systolic || !combo.systolic) &&
            (combo.diastolic === diastolic || !combo.diastolic) &&
            (combo.heartRate === heartRate || !combo.heartRate)
        )?.message || "No specific message available.";
    } else if (type === "notTakeMedicine") {
      message = `${username} missed ${cronName} medicines today. An in-person checkup might be required.`;
    } else if (type === "notTakeBloodPressure") {
      message = `${username} missed blood pressure & pulse measurements. An in-person checkup might be required.`;
    } else if (type === "abuse") {
      message = `violence is detected on camera (X). The event recording is saved and an in-person check-up reminder is released.`;
    } else if (type === "falldown") {
      message = `${username} might have fallen. The event recording is saved and an in-person check-up reminder is released.`;
    } else if (type === "sleepingSickness") {
      message = `Camera (x) hasn't detected ${username} movement for a while. The event recording is saved and an in-person check-up reminder is released.`;
    } else {
      message = "No specific message available.";
    }

    return message;
  };

  const generateMessages = (initData) => {
    if (initData.data === null) {
      return [];
    }
    // notifyCreate
    if (initData.data) {
      return {
        ...initData,
        data: initData.data.map((entry) => ({
          ...entry,
          message: handleMessage(entry),
        })),
      };
    }

    // notifyRead
    return initData.map((entry) => ({
      ...entry,
      message: handleMessage(entry),
    }));
  };

  return (
    <NotificationContext.Provider
      value={{
        unreadCount,
        setUnReadCount,
        messages,
        handleMessage,
        generateMessages,
        newNotificationIds,
        setNewNotificationIds,
        eventMessages,
        setEventMessages,
        eventData,
        setEventData,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
