import { configureStore } from "@reduxjs/toolkit";

// import ui from "./slice/ui";
// import lang from "./slice/lang";
import user from "./slice/user";
// import counter from "./slice/counter";

const store = configureStore({
  reducer: {
    // ui: ui.reducer,
    // lang: lang.reducer,
    // counter: counter.reducer,
    user: user.reducer,
  },
});

// Export store
export default store;