import mainStyle from './recipient.module.scss';
import { PATH } from "router/config/const";
import Breadcrumb from 'components/Breadcrumb';
import React, { useState, useCallback } from 'react';
import { cloneDeep, isNil } from "lodash";
import { useOutletContext } from "react-router-dom";
import { useWebSocket } from "hook/WebSocketContext";
import { useAuth } from "hook/AuthContext";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import Box from '@mui/material/Box';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import InputField from "./components/InputField";
import RadioField from "./components/RadioField";
import DateField from "./components/DateField";
import AreaField from "./components/AreaField";
import Button from '@mui/material/Button';
import userPhoto from "assets/img/testPhoto/testImage.jpeg";

const initImage = {
  url: userPhoto,
  title: 'Upload',
  width: '55%',
  radius: '50%',
};
const InitProfileSettingData = {
  photoBase64: '',
  name: '',
  gender: '',
  birthday: '',
  email: '',
  password: '',
  confirmPassword: '',
  note: '',
  role: '',
  allergies: [],
  contact_name: '',
  contact_phone: []
}

dayjs.extend(utc);
dayjs.extend(timezone);

const AddRecipient = (props) => {
  
  //const { wsData, setWsData } = props;
  const [wsData, setWsData] = useOutletContext();
  const ws = useWebSocket();
  const { token } = useAuth();

  const [subAccount, setSubAccount] = useState(InitProfileSettingData);
  const [file, setFile] = useState(userPhoto);
  const [nameError, setNameError] = useState(false);
  const [mailError, setMailError] = useState(false);
  const [pwError, setPwError] = useState(false);

  function handleFile(e) {
    let file = e.target.files[0]
    // console.log(e.target.files);
    setFile(URL.createObjectURL(file));
    getBase64(file);
  }
  const getBase64 = (file) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    
    reader.onload = () => {
      let accountObj = cloneDeep(subAccount);
      accountObj.photoBase64 = reader.result;
      setSubAccount(accountObj)
    };
  };

  function handleError(e, column, type){
    // ...
  }

  function handleColumn(e, column, type) {
    let accountObj = cloneDeep(subAccount);
    if(type === 'input'){
      switch (column) {
        case 'addName':
          if(isNil(e.target.value) || e.target.value === '' || e.target.value.indexOf(' ') >= 0){
            setNameError(true);
          }else{
            setNameError(false);
            accountObj.name = e.target.value
          }
          break;
        case 'addEmail':
          if(isNil(e.target.value) || e.target.value === '' || e.target.value.indexOf(' ') >= 0){
            setMailError(true);
          }else{
            accountObj.email = e.target.value
            let emailRule = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/;
            if(e.target.value.search(emailRule)!== -1){
              setMailError(false);
            }else{
              setMailError(true);
            }
          }
          break;
        case 'addPassword':
        case 'confirmPassword':
          if(isNil(e.target.value) || e.target.value === '' || e.target.value.indexOf(' ') >= 0){
            setPwError(true);
          }else{
            if(column === 'addPassword'){
              accountObj.password = e.target.value
              if(e.target.value !== accountObj.confirmPassword){
                setPwError(true);
              }else{
                setPwError(false);
              }
            }else{ // confirmPassword
              accountObj.confirmPassword = e.target.value
              if(e.target.value !== accountObj.password){
                setPwError(true);
              }else{
                setPwError(false);
              }
            }
          }
          break;
        // case 'addNote':
        //   accountObj.note = e.target.value
        //   break;
        default:
          break;
      }
    }else if(type === 'gender'){
      accountObj.gender = e.target.value
    }else if(type === 'date'){
      let year = dayjs(e).format('YYYY');
      let month = dayjs(e).format('MM');
      let day = dayjs(e).format('DD');
      accountObj.birthday = year+'/'+month+'/'+day;
    }
    setSubAccount(accountObj)
  }

  const saveData = () => {
    let checkEmpty = false;
    let accountObj = cloneDeep(subAccount);

    if(accountObj.name === ''){
      setNameError(true)
      checkEmpty = true
    }
    if(accountObj.email === ''){
      setMailError(true)
      checkEmpty = true
    }
    if(accountObj.password === '' || accountObj.confirmPassword === ''){
      setPwError(true)
      checkEmpty = true
    }
    
    if(pwError || mailError || nameError || checkEmpty){
      return;
    }else{
      //qq("#addNote").val();
      accountObj.role = document.getElementById('addRole').value;
      accountObj.contact_name = document.getElementById('addContactName').value;

      let tmpNote = document.getElementById('addNote').value.replaceAll(/\n/g, '');
      accountObj.note = tmpNote;
      let tmpAllergies = document.getElementById('addAllergies').value.replaceAll(/\n| /g, '')
      tmpAllergies = tmpAllergies.split(',');
      accountObj.allergies = tmpAllergies;
      let tmpPhone = document.getElementById('addContactPhone').value.replaceAll(/ /g, '');
      tmpPhone = tmpPhone.split(',');
      accountObj.contact_phone = tmpPhone;
      
      setSubAccount(accountObj);
      createSubAccount(accountObj);
    }
  };

  const createSubAccount = (obj) => {

    const subAccount = {
      resource: `/users`,
      verb: "create",
      accessToken: token,
      data: {
        name: obj.name,
        role: obj.role,
        email: obj.email,
        password: obj.password,
        guardianId: 0,
        trait: {
          birthday: obj.birthday,
          gender: obj.gender,
          allergies: obj.allergies,
          contacts: {
            name: obj.contact_name,
            phones: obj.contact_phone
          },
          note: obj.note,
          portrait: obj.photoBase64
        }
      },
    };
    // console.log(subAccount)
    ws.send(JSON.stringify(subAccount));
  }
  
  return (
    <>
      <Breadcrumb path={PATH.add_recipient}></Breadcrumb>
      <Card style={{ padding: '20px'}}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Box sx={{ marginTop:'15px', display: 'flex', justifyContent: 'center', flexWrap: 'wrap', minWidth: 300, width: '100%'}}>
              <img src={file} style={{ width: '180px', height: '180px', objectFit: 'cover', borderRadius: initImage.radius }}/>
            </Box>
            <Box sx={{ marginTop:'15px', display: 'flex', justifyContent: 'center', flexWrap: 'wrap', minWidth: 300, width: '100%'}}>
              <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                style={{
                  fontSize: '14px',
                  fontWeight: 600,
                  borderRadius: '10px',
                  backgroundColor: '#0A87A9',
                  padding: '8px 16px',
                  '&:hover': {
                    backgroundColor: '#05647E',
                  }
                }}
              >
                Upload photo
                <input hidden
                  onChange={(e)=>{handleFile(e)}}
                  type="file" accept=".png, .jpg, .jpeg"
                />
              </Button>
            </Box>
          </Grid>
          <Grid item xs={8}>
            <Box>
              <InputField
                id={`addName`}
                label="Recipient Name"
                error={nameError}
                placeholder="Recipient Name"
                onChange={(e) => handleColumn(e, 'addName', 'input')}
              />
              <InputField
                id={`addEmail`}
                label="Email"
                error={mailError}
                placeholder="Account Email"
                onChange={(e) => handleColumn(e, 'addEmail', 'input')}
              />
              <InputField
                id={`addPassword`}
                label="Password"
                type="Password"
                error={pwError}
                placeholder="Account Password"
                onChange={(e) => handleColumn(e, 'addPassword', 'input')}
              />
              <InputField
                id={`confirmPassword`}
                label="Confirm Password"
                type="Password"
                error={pwError}
                placeholder="Confirm Password"
                onChange={(e) => handleColumn(e, 'confirmPassword', 'input')}
              />
              <InputField
                id={`addRole`}
                label="Account Role"
                placeholder="Account Role"
                onChange={(e) => handleColumn(e, 'addRole', 'input')}
              />
              <InputField
                id={`addContactName`}
                label="Contact Name"
                placeholder="Contact Name"
                onChange={(e) => handleColumn(e, 'addContactName', 'input')}
              />
              <InputField
                id={`addContactPhone`}
                label="Contact Phone"
                placeholder="Contact Phone"
                onChange={(e) => handleColumn(e, 'addContactPhone', 'input')}
              />
              <RadioField 
                id={`addGender`}
                label="Gender"
                defaultVal={'Male'}
                value={['Male', 'Female', 'Other']}
                onChange={(e) => handleColumn(e, 'addGender', 'gender')}
              />
              <DateField
                id={`addBirthday`}
                label="Birthday"
                onChange={(e) => handleColumn(e, 'addBirthday', 'date')}
              />
              <AreaField
                id={`addAllergies`}
                label="Allergies Note"
                placeholder="Allergies Note"
                onChange={(e) => handleColumn(e, 'addAllergies', 'input')}
              />
              <AreaField
                id={`addNote`}
                label="Special Note"
                placeholder="Special Note"
                onChange={(e) => handleColumn(e, 'addNote', 'input')}
              />
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ marginTop: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Button variant="contained"
            style={{
              fontSize: '16px',
              fontWeight: 600,
              borderRadius: '10px',
              backgroundColor: '#0A87A9',
              padding: '8px 16px',
              '&:hover': {
                backgroundColor: '#05647E',
              }
            }}
          >
            <div className={'addRecipientAccount'} 
              onClick={() => saveData()}
            >
              Add Recipient
            </div>
          </Button>
        </Box>
      </Card>
    </>
    
  );
};

export default AddRecipient;