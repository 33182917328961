import React, { useState, useEffect, useRef } from "react";
import healthStyle from "../health.module.scss";
import 'react-alice-carousel/lib/alice-carousel.css';
import "../carousel.css";
import { cloneDeep } from "lodash";
import { Dimensions } from 'react-native-web';
import AliceCarousel from 'react-alice-carousel';
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from '@mui/material/Typography';

import pressure_alert from "assets/img/icon/icn_heartrate_abnormal.svg";
import medicine_alert from "assets/img/icon/icn_medicine_group.svg";
import heart_normal from "assets/img/icon/icn_heartrate_normal.svg";
import device_normal from "assets/img/icon/icn_plug.svg";
import newOpen from "assets/img/icon/btn_external_expand.svg";

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import eventIcon from "assets/img/icon/icn_profile_event.svg";
import settingIcon from "assets/img/icon/icn_profile_setting.svg";

const SliderVer = (props) => {
  const { hideCalendar, setHideCalendar, groupData} = props;
  // const sliderItemNum = {
  //   0: { items: 1 },
  //   1: { items: 2 },
  // };
  // const [defaultIndex, setDefaultIndex] = useState(0);
  const [nowIndex, setNowIndex] = useState(0);

  const [sliderData, serSliderData] = useState(cloneDeep(groupData));
  const smallScreen = Dimensions.get('window').height>1023? false: true;

  const [summaryTag, setSummaryTag] = useState(0);
  const handleChangeTag = (id) => {
    setSummaryTag(id);
  }

  const makeSliderData = () =>{
    let defaultData = cloneDeep(groupData)
    defaultData.forEach((item, index) => {
      item.selected = false;
    });
    
    // console.log(defaultData)
    if(defaultData.length >2){
      defaultData[0].selected = true;
      defaultData[1].selected = true;
    }else if(defaultData.length ===1){
      defaultData[0].selected = true;
    }

    // console.log(defaultData)
    serSliderData(defaultData);
  }

  useEffect(() => {
    makeSliderData();
  }, []);

  
  
  // const sliderItems = [
  //   <div className="item" data-value="0" >
  //     <img src={testPhoto1} className={healthStyle['sliderphoto']} role="presentation" />
  //     <Typography sx={{ fontSize: 24 }} color="text.secondary" gutterBottom>
  //       Mother321
  //     </Typography>
  //   </div>,
  //   <div className="item" data-value="1" >
  //     <img src={testPhoto2} className={healthStyle['sliderphoto']} role="presentation" />
  //     <Typography sx={{ fontSize: 24 }} color="text.secondary" gutterBottom>
  //       Father321
  //     </Typography>
  //   </div>,
  //   <div className="item" data-value="2" >
  //     <img src={testPhoto3} className={healthStyle['sliderphoto']} role="presentation" />
  //     <Typography sx={{ fontSize: 24 }} color="text.secondary" gutterBottom>
  //       Grandmother321
  //     </Typography>
  //   </div>,
  // ];
  // const [sliderItemsAry, setSliderItemsAry] = useState(sliderItems);

  // const sliderNextBtn = ({ isDisabled }) => {
  //   return <ArrowForwardIosIcon 
  //     style={{ color: isDisabled? 'gainsboro': '', cursor: isDisabled? '':'pointer',
  //     }} 
  //     onclick={{setNowPosition}}
  //     />
  // };
  // const sliderPrevBtn = ({ isDisabled }) => {
  //   return <ArrowBackIosIcon 
  //     style={{ color: isDisabled? 'gainsboro': '', cursor: isDisabled? '':'pointer',
  //     }} onclick={() => setNowPosition('prev')}/>
  // };

  // useEffect(() => {
  //   console.log(nowIndex)
  // }, [nowIndex]);
  
  // const carousel = useRef();

  // const setNowPosition = (e, type) => {
  //   let newIndex = cloneDeep(nowIndex)
  //   if(type == 'next'){
  //     if(nowIndex !== sliderItems.length-2){
  //       newIndex++;
  //       carousel?.current?.slideNext(e);
  //       setNowIndex(newIndex);
  //     }
  //   }else{
  //     if(nowIndex !== 0){
  //       newIndex--;
  //       carousel?.current?.slidePrev(e);
  //       setNowIndex(newIndex);
  //     }
  //   }
  // };

  const handleChangeView = () => {
    let val = cloneDeep(hideCalendar);
    setHideCalendar(!val);
  };

  useEffect(() => {
    if(groupData.length > 0){
      console.log('Here find user summary data')
    }
    
  }, [groupData]);

  return (
    <>
      <Box style={{minWidth:"20%", height: '58px'}}>
        <Grid container justifyContent="end">
          <div className={healthStyle['menuIcon']} onClick={() => handleChangeView()}>
            <img src={settingIcon}/>
          </div>
          <div className={healthStyle['menuIcon']} >
            <img src={eventIcon}/>
          </div>
          <div className={healthStyle['menuIcon']} >
            <img src={eventIcon}/>
          </div>
        </Grid>
      </Box>
      <CardContent style={{backgroundColor: '#ffffff', height: 'calc(100% - 95px)',
        border: '1px solid rgba(0, 0, 0, 0.12)', borderRadius:'20px', padding: '16px 0px'
        }}>
        <Grid container style={{height: '100%'}}>
          {sliderData.filter(item => item.selected === true).map((element, index) => (
            <Grid item xs={6} style={{borderRight: index%2===0? '1px solid #EBEBEB':''}}>
              <Grid container style={{minHeight: 'calc(30% - 70px)',}}>
                <div className={healthStyle['eventMarquee']}>Past 24 hours</div>
                <Grid item style={{width: '140px'}}>
                  <div className={healthStyle['userBox']}>
                    <div className={healthStyle['userImage']} 
                      style={{
                        borderColor:(element.note? '#F89898': '#94DEB4'), backgroundImage: `url(${element.photo})`}}>
                    </div>
                    <img src={element.noteSrc} className={healthStyle['userNote']} />
                  </div>
                  <div style={{display: 'flex', justifyContent: 'center'}}>
                    <Typography sx={{ fontSize: 24 }} color="text.secondary" gutterBottom>
                      {element.name}
                      {/* {element.email}{element.role}-{element.userId} */}
                    </Typography>
                  </div>
                </Grid>
                <Grid item style={{width: 'calc(100% - 140px)',display: 'inline-flex', flexWrap: 'wrap'}}> 
                  <div style={{margin: '5px 15px'}}
                    className={` ${healthStyle['eventContainer']} ${healthStyle['eventAlert']} `}>
                    <div className={healthStyle['eventInfo']}>
                      <div className={healthStyle['eventImg']} 
                        style={{ backgroundImage: `url(${pressure_alert})`}}>
                      </div>
                      {/* <img src={errorNote} className={healthStyle['eventNote']} /> */}
                      <Grid container style={{marginLeft: '15px'}}>
                        <Grid item xs={12} className={healthStyle['eventTitle']}>Blood Pressure</Grid>
                        <Grid item xs={12} className={healthStyle['eventContext']}>Averagely Abnormal</Grid>
                      </Grid>
                    </div>
                  </div>
                  <div style={{margin: '5px 15px'}}
                    className={` ${healthStyle['eventContainer']} ${healthStyle['eventAlert']} `}>
                    <div className={healthStyle['eventInfo']}>
                      <div className={healthStyle['eventImg']} 
                        style={{ backgroundImage: `url(${medicine_alert})`}}>
                      </div>
                      {/* <img src={errorNote} className={healthStyle['eventNote']} /> */}
                      <Grid container style={{marginLeft: '15px'}}>
                        <Grid item xs={12} className={healthStyle['eventTitle']}>Medication Schedule</Grid>
                        <Grid item xs={12} className={healthStyle['eventContext']}>Missed</Grid>
                      </Grid>
                    </div>
                  </div>
                  <div className={` ${healthStyle['eventContainer']} ${healthStyle['eventWell']} `}
                    style={{margin: '20px 15px'}}>
                    <div className={healthStyle['eventInfo']}>
                      <div className={healthStyle['eventImg']} 
                        style={{ backgroundImage: `url(${heart_normal})`}}>
                      </div>
                      {/* <img src={errorNote} className={healthStyle['eventNote']} /> */}
                      <Grid container style={{marginLeft: '15px'}}>
                        <Grid item xs={12} className={healthStyle['eventTitle']}>Heart rate</Grid>
                        <Grid item xs={12} className={healthStyle['eventContext']}>Averagely Normal</Grid>
                      </Grid>
                    </div>
                  </div>
                  <div className={` ${healthStyle['eventContainer']} ${healthStyle['eventWell']} `}
                    style={{margin: '20px 15px'}}>
                    <div className={healthStyle['eventInfo']}>
                      <div className={healthStyle['eventImg']} 
                        style={{ backgroundImage: `url(${device_normal})`}}>
                      </div>
                      {/* <img src={errorNote} className={healthStyle['eventNote']} /> */}
                      <Grid container style={{marginLeft: '15px'}}>
                        <Grid item xs={12} className={healthStyle['eventTitle']}>Immobility alert</Grid>
                        <Grid item xs={12} className={healthStyle['eventContext']}>detction</Grid>
                      </Grid>
                    </div>
                  </div>
                </Grid>
              </Grid>
              <div style={{paddingRight: '8px', paddingLeft: '8px'}}>
                  <hr style={{border: '1px solid #EBEBEB'}}/>
              </div>
              <Grid container style={{padding: '12px 50px', justifyContent: 'flex-start', width: '100%'}}>
                <Grid item style={{ width: '75%'}}>
                  <Grid container>
                    <Grid item>
                      <Box className={` ${healthStyle['summaryTagStyle']} ${summaryTag ===0? healthStyle['tagSelected'] : healthStyle['tagUnselected']} `}
                          onClick={() => handleChangeTag(0)}>
                        <Typography className={healthStyle['summaryTagText']}>Last time</Typography> 
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box className={` ${healthStyle['summaryTagStyle']} ${summaryTag ===1? healthStyle['tagSelected'] : healthStyle['tagUnselected']} `}
                          onClick={() => handleChangeTag(1)}>
                        <Typography className={healthStyle['summaryTagText']}>7 day average</Typography> 
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box className={` ${healthStyle['summaryTagStyle']} ${summaryTag ===2? healthStyle['tagSelected'] : healthStyle['tagUnselected']} `}
                          onClick={() => handleChangeTag(2)}>
                        <Typography className={healthStyle['summaryTagText']}>30 day average</Typography> 
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item style={{ width: '25%'}}>
                  <Grid container justifyContent="end">
                    <Box className={`${healthStyle['tagOpenPage']} `}>
                      <Grid item style={{marginRight: '5px'}}>
                        <img src={newOpen} alt="openInNew"/>
                      </Grid>
                      <Grid item>
                        <Typography>Detail</Typography> 
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container style={{minHeight: 'calc(70% - 100px)'}}>  {/* padding: '0px 100px',  */}
                <Grid item style={{justifyContent: 'center',display: 'inline-flex', flexWrap: 'wrap', width: '100%', paddingBottom: '6px'}}> 
                  <div className={` ${healthStyle['summaryBlockStyle']} ${healthStyle['summaryAlert']} `}>
                    <div>
                      <Grid container style={{height: '60px', padding: '5px', justifyContent: 'space-between'}}>
                        <Grid item >
                          <Grid item xs={12} className={healthStyle['eventTitle']}>Blood Pressure</Grid>
                          <Grid item xs={12} className={healthStyle['eventTime']}>2024/08/07 21:00</Grid>
                        </Grid>
                        <Grid item>
                          <div className={healthStyle['eventImg']} 
                            style={{ backgroundImage: `url(${pressure_alert})`}}>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                    <div className={healthStyle['summaryDataBox']}>
                      <Grid container>
                        <Grid item>
                          <div className={healthStyle['summaryDataTitle']}>SBP</div>
                          <div className={healthStyle['summaryDataAlert']}>160</div>
                        </Grid>
                        <Grid item style={{marginLeft:'50px'}}>
                          <div className={healthStyle['summaryDataTitle']}>DBP</div>
                          <div className={healthStyle['summaryDataWell']}>90</div>
                        </Grid>
                      </Grid>
                    </div>
                    <div className={healthStyle['summaryDataUnit']}>
                      <div className={healthStyle['unitText']}>mmHg</div>
                    </div>
                  </div>

                  <div className={` ${healthStyle['summaryBlockStyle']} ${healthStyle['summaryAlert']} `}>
                    <div>
                      <Grid container style={{height: '60px', padding: '5px', justifyContent: 'space-between'}}>
                        <Grid item >
                          <Grid item xs={12} className={healthStyle['eventTitle']}>Pluse rate</Grid>
                          <Grid item xs={12} className={healthStyle['eventTime']}>2024/08/01 21:00</Grid>
                        </Grid>
                        <Grid item>
                          <div className={healthStyle['eventImg']} 
                            style={{ backgroundImage: `url(${pressure_alert})`}}>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                    <div className={healthStyle['summaryDataBox']}>
                      <Grid container>
                        <Grid item>
                          <div className={healthStyle['summaryDataTitle']}></div>
                          <div className={healthStyle['summaryDataAlert']}>160</div>
                        </Grid>
                      </Grid>
                    </div>
                    <div className={healthStyle['summaryDataUnit']}>
                      <div className={healthStyle['unitText']}>bpm</div>
                    </div>
                  </div>

                  <div className={` ${healthStyle['summaryBlockStyle']} ${healthStyle['summaryWell']} `} style={{marginTop: '5px'}}>
                    <div>
                      <Grid container style={{height: '60px', padding: '5px', justifyContent: 'space-between'}}>
                        <Grid item >
                          <Grid item xs={12} className={healthStyle['eventTitle']}>Blood sugar</Grid>
                          <Grid item xs={12} className={healthStyle['eventTime']}>2024/08/07 21:00</Grid>
                        </Grid>
                        <Grid item>
                          <div className={healthStyle['eventImg']} 
                            style={{ backgroundImage: `url(${pressure_alert})`}}>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                    <div className={healthStyle['summaryDataBox']}>
                      <Grid container>
                        <Grid item>
                          <div className={healthStyle['summaryDataTitle']}>Before meal</div>
                          <div className={healthStyle['summaryDataWell']}>140</div>
                        </Grid>
                        <Grid item style={{marginLeft:'50px'}}>
                          <div className={healthStyle['summaryDataTitle']}>After meal</div>
                          <div className={healthStyle['summaryDataWell']}>90</div>
                        </Grid>
                      </Grid>
                    </div>
                    <div className={healthStyle['summaryDataUnit']}>
                      <div className={healthStyle['unitText']}>mg/dL</div>
                    </div>
                  </div>

                  <div className={` ${healthStyle['summaryBlockStyle']} ${healthStyle['summaryWell']} `} style={{marginTop: '5px'}}>
                    <div>
                      <Grid container style={{height: '60px', padding: '5px', justifyContent: 'space-between'}}>
                        <Grid item >
                          <Grid item xs={12} className={healthStyle['eventTitle']}>Sleeping time</Grid>
                          <Grid item xs={12} className={healthStyle['eventTime']}>2024/08/07 08:05</Grid>
                        </Grid>
                        <Grid item>
                          <div className={healthStyle['eventImg']} 
                            style={{ backgroundImage: `url(${pressure_alert})`}}>
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                    <div className={healthStyle['summaryDataBox']}>
                      <Grid container>
                        <Grid item>
                          <div className={healthStyle['summaryDataTitle']}></div>
                          <div className={healthStyle['summaryDataWell']}>7.5</div>
                        </Grid>
                      </Grid>
                    </div>
                    <div className={healthStyle['summaryDataUnit']}>
                      <div className={healthStyle['unitText']}>Hr</div>
                    </div>
                  </div>
                </Grid>
              </Grid>

            </Grid>


          ))}

        </Grid>
        {/* <div style={{padding: '10px 30px'}}>
          <AliceCarousel disableButtonsControls //mouseTracking
            controlsStrategy="alternate" key="carousel" ref={carousel}
            activeIndex={defaultIndex} items={sliderItemsAry} responsive={sliderItemNum} 
            // renderPrevButton={sliderPrevBtn} renderNextButton={sliderNextBtn}
          />
        </div> */}
        {/* <div key="btns" className="b-refs-buttons">
          <ArrowBackIosIcon 
            style={{ color: 'gainsboro', cursor: 'pointer', backgroundColor: '#ffffff',
            border: '1px solid #EBEBEB', borderRadius: '20px',
              // color: nowIndex===0? 'gainsboro': '', cursor: 'pointer',
            position: "absolute", left:80, top: 500 }}
            // onClick={(e) => setNowPosition(e, 'prev')} 
            />
          <ArrowForwardIosIcon 
            style={{ color: 'gainsboro', cursor: 'pointer', backgroundColor: '#ffffff',
            border: '1px solid #EBEBEB', borderRadius: '20px',
            position: "absolute", right: 15, top: 500 }} 
            />
        </div> */}
      </CardContent>
    </>
  );
};

export default SliderVer;
