import mainStyle from './mobile.module.scss';
import { iconTheme, iconTitleTheme, iconThemeSelected } from './style';

import { useEffect, useState } from "react";
// import { useRoute } from '@react-navigation/native';
import { useNavigate, useLocation, redirect } from 'react-router-dom';
import { PATH } from "router/config/const";
import * as Setting from  "../../pages/Login/Setting"
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Icon from '@mui/material/Icon';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
// icon
import logo from "assets/img/icon/logo.svg";
import close from "assets/img/icon/icn_nav_close.svg";
import memu from "assets/img/icon/icn_nav_menu.svg";
import dashboard from "assets/img/icon/icn_menu_dashboard.svg";
import dashboard_Select from "assets/img/icon/icn_menu_dashboard_selected.svg";
import summary from "assets/img/icon/icn_menu_caregiver.svg";
import summary_Select from "assets/img/icon/icn_menu_caregiver_selected.svg";
import recipient from "assets/img/icon/icn_menu_recipient_setting.svg";
import recipient_Select from "assets/img/icon/icn_menu_recipient_setting_selected.svg";
import device from "assets/img/icon/icn_menu_devices.svg";
import device_Select from "assets/img/icon/icn_menu_devices_selected.svg";
import calendar from "assets/img/icon/icn_menu_schedule.svg";
import calendar_Select from "assets/img/icon/icn_menu_schedule_selected.svg";
import event from "assets/img/icon/icn_menu_event_alert.svg";
import event_Select from "assets/img/icon/icn_menu_event_alert_selected.svg";
import setting from "assets/img/icon/icn_menu_user.svg";
import setting_Select from "assets/img/icon/icn_menu_user_selected.svg";
import question from "assets/img/icon/icn_menu_qa.svg";
import question_Select from "assets/img/icon/icn_menu_qa_selected.svg";
import policy from "assets/img/icon/icn_menu_privacy.svg";
import policy_Select from "assets/img/icon/icn_menu_privacy_selected.svg";
import logout from "assets/img/icon/icn_menu_log_out.svg";
import logout_Select from "assets/img/icon/icn_menu_log_out_selected.svg";


// page utils
const Mobilemenu = () => {
  const mainBarList = [
    {id: '01', src: dashboard, selectSrc: dashboard_Select, params: "dashboard", description: 'Dashboard',permission: true},
    {id: '02', src: summary, selectSrc: summary_Select, params: "summary", description: 'Healthcare Summary',permission: true},
    {id: '04', src: recipient, selectSrc: recipient_Select, params: "recipient_setting", description: 'Recipient Setting',permission: true},
    {id: '04', src: device, selectSrc: device_Select, params: "devices", description: 'Devices', permission: false},
    {id: '05', src: calendar, selectSrc: calendar_Select, params: "calendar", description: 'Calendar', permission: false},
    {id: '06', src: event, selectSrc: event_Select, params: "event", description: 'Event & Alert',permission: true},
  ]

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  const location = useLocation();

  let pathParam = location.pathname.split('/')[1];
  if(pathParam === ''){
    pathParam = 'dashboard';
  }

  const DrawerList = (
    <Box sx={{p: 1}}role="presentation" style={{display: 'flex'}} onClick={toggleDrawer(false)}>
      {/* <List> */}
        <Grid container>
        
        {mainBarList.map((list, index) => (
          <Grid item>
          <div style={pathParam=== list.params? iconThemeSelected: iconTheme}
              onClick={() => {}}>
            <img src={pathParam=== list.params? list.selectSrc: list.src}/>
          </div>
          </Grid>
          // <ListItem key={index} disablePadding className={mainStyle['listBox']}>
            // <Grid container sx={{p: 1}} >
            //   <div style={pathParam=== list.params? iconThemeSelected: iconTheme}
            //       onClick={() => {}}>
            //     <img src={pathParam=== list.params? list.selectSrc: list.src}/>
            //   </div>
            // </Grid>
          // </ListItem>
        ))}
        </Grid>
      {/* </List> */}
    </Box>
  );

  return (
    <>
      <div className={mainStyle['sideBox']}>
        <Box>
          <nav aria-label="main mailbox folders" >
          <Grid container spacing={2} >
            <Grid item xs={3}>
              <div style={iconTitleTheme} >
                <Icon onClick={toggleDrawer(true)} >
                  <img src={memu}/>
                </Icon>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div style={iconTitleTheme} >
                <img src={logo} style={{left: '50%'}}/>
              </div>
            </Grid>
          </Grid>
            
          </nav>
        </Box>
        <Drawer anchor={'top'} open={open} onClose={toggleDrawer(false)}>
          {DrawerList}
        </Drawer>
      </div>
    </>
  );
};

export default Mobilemenu;