import React, { useState, useEffect } from "react";
import summaryStyle from '../summary.module.scss';
import { cloneDeep, isNil } from 'lodash';
import { useMediaQuery, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import wellNote from "assets/img/icon/icn_normal.svg";
import errorNote from "assets/img/icon/icn_abnormal.svg";
import userPhoto1 from "assets/img/elderMaleFemale/GettyImages-916097436.png";
import userPhoto2 from "assets/img/elderMaleFemale/GettyImages-109839766.png";

// import AuthOrganizaionService from 'services/Auth.Organization.service';abnormal
const testData = [
  { id: 0, identity: '爸爸', age: '65', gender:'男', content: 'Recipient card 1', note: true, noteSrc: errorNote, showSummary: true, photoSrc: userPhoto1 },
  // { id: 1, identity: 'Mother', age: '60', gender:'Female', content: 'Recipient card 2', note: false, noteSrc: wellNote, showSummary: false, photoSrc: userPhoto2 }
]

const UserCard = (prop) => {

  const [userData, setUserData] = useState(cloneDeep(testData));

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(1280));

  function handleShowUserData(uid) {
    if(uid === 1) return;

    const newData = [...userData];

    for (let item of newData) {
      item.showSummary = false
      if(item.id === uid){
        item.showSummary = true;
      }
    }
    setUserData(newData);


  }
  
  return (
    <>
      {/* <Grid container alignItems="center" justifyContent="space-evenly" sx={{p: 1}}> */}
      {userData.map((user, index) => (
        <div onClick={() =>handleShowUserData(user.id)} className={summaryStyle['usercardContainer']}
          key={index} style={{ minWidth:'280px', marginRight: (isSmallScreen? '20px': '5px'),
          background: (user.showSummary? 'white': ''), border: (user.showSummary? '1px solid #EBEBEB': '1px solid #b8d3db')}}>
          <div className={summaryStyle['usercardBox']}>
            <div className={summaryStyle['usercardPhoto']}>
              <div className={summaryStyle['profileImage']} 
                style={{ width: '60px', height: '60px',
                  borderColor:(user.note? '#F89898': '#94DEB4'), backgroundImage: `url(${user.photoSrc})`}}>
              </div>
              <img src={user.note? user.noteSrc: ''} className={summaryStyle['profileNote']} />
            </div>
            
            <div className={summaryStyle['usercardInfo']}>
              <div>
                <Typography variant="h5" component="div" color="#0A87A9" style={{fontWeight:"bold", marginBottom: '10px'}}>
                  {user.identity}
                </Typography>
                <div style={{display: 'flex'}}>
                  <Typography variant='h7' component="div" color="#737791" >
                    {user.age} 歲
                  </Typography>
                  <Typography component="div" color="#BAD4DD" style={{margin: '0px 5px'}}>|</Typography>
                  <Typography variant='h7' component="div" color="#737791" >
                    {user.gender}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default UserCard;
