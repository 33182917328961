export const panelTheme = {
  padding: '20px 20px 10px 20px', 
  position: "relative",
  border: '1px solid', 
  borderRadius: '0px 20px 20px 20px', 
  borderColor: 'rgba(0, 0, 0, 0.09)', 
  backgroundColor:'white'
}

export const tabTheme = {
  // position: "absolute", 
  // zIndex: '1000'
  // height: '100px',
}
export const newOpenTheme = { position: "absolute", top: 8, right: 12 }