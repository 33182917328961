import React from 'react';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';

import FieldContainer from "./FieldContainer";

import { ReactComponent as InputClearBtn } from 'assets/img/icon/btn_input_clear.svg';

export const Input = ({ id, placeholder, value, maxLength, onChange, iconClick, helperText }) => (
    <TextField
        sx={{
            '& .MuiOutlinedInput-root': {
                height: '40px',
                borderRadius: '10px',
                '& fieldset': {
                    borderColor: '#EEF0F2',
                }
            },
        }}
        InputProps={{
            endAdornment: value ? (<InputAdornment position="end">
                <IconButton aria-label="clear-input" onClick={iconClick}>
                    <InputClearBtn />
                </IconButton>
            </InputAdornment>) : null,
        }}
        inputProps={{ maxLength: maxLength }}
        id={id}
        variant="outlined"
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        error={!!helperText}
        helperText={helperText}
    />
);


const InputField = ({ label, id, placeholder, value, maxLength, onChange, iconClick, helperText }) => (
    <FieldContainer label={label}>
        <Box sx={{ minWidth: 180 }} noValidate autoComplete="off">
            <FormControl fullWidth>
                <Input
                    id={id}
                    placeholder={placeholder}
                    value={value}
                    maxLength={maxLength}
                    onChange={onChange}
                    iconClick={iconClick}
                    helperText={helperText}
                />
            </FormControl>
        </Box>
    </FieldContainer>
);

export default InputField;