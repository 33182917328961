import React, { useState, useCallback } from 'react';

import recipientStyle from './recipient.module.scss';

import dayjs from 'dayjs';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';

import InputField from "./components/InputField";
import SwitchField from "./components/SwitchField";
import TypographyField from "./components/TypographyField";
import AddTimeField from "./components/AddTimeField";
import DatepickerField from "./components/DatepickerField";
import AlertDialog from "./components/AlertDialog"

import { ReactComponent as HeartbeatIcon } from 'assets/img/icon/icn_heartbeat.svg';
import { ReactComponent as RemovedTableBtn } from 'assets/img/icon/btn_table_removed.svg';

const TimeSetting = ({ ws, healthRemindersData, setHealthRemindersData, healthRemindersAlertDialog, setHealthRemindersAlertDialog,
  isValidateField, setIsValidateField, token }) => {

  const [timePickerAddTime, setTimePickerAddTime] = useState(null);

  function validateField(value, fieldName) {
    if (fieldName === 'dailyJobInterval') {
      const numValue = Number(value);
      const validateNumber = Number.isInteger(numValue) && numValue >= 1 && numValue <= 7;
      return validateNumber;
    }
    if (fieldName === 'snooze') {
      const numValue = Number(value);
      const validateNumber = Number.isInteger(numValue) && numValue >= 1 && numValue <= 30;
      return validateNumber;
    }
    return true;
  };

  const handleOnChange = useCallback((event, fieldName, type) => {
    const newValue = (() => {
      switch (type) {
        case 'input':
        case 'select':
          if (fieldName === 'dailyJobInterval' || fieldName === 'snooze') {
            const intValue = parseInt(event.target.value);
            return isNaN(intValue) ? event.target.value : intValue;
          }
          return event.target.value;
        case 'inputClear':
          return '';
        case 'switch':
          return !event;
        case 'datepick':
          if (fieldName === 'endTime') {
            const endOfDay = dayjs(event).endOf('day');
            return dayjs(endOfDay).unix()
          }
          return dayjs(event).unix();
        default:
          return event;
      }
    })();

    let newHelperText = '';

    if (type === 'input' && fieldName === 'dailyJobInterval') {
      const isValid = validateField(newValue, fieldName);
      if (!isValid) {
        newHelperText = "Numbers one to seven"
      }
    }
    if (type === 'input' && fieldName === 'snooze') {
      const isValid = validateField(newValue, fieldName);
      if (!isValid) {
        newHelperText = "Numbers one to thirty"
      }
    }

    setIsValidateField(prevState => {
      return prevState.map((item) => {
        if (item.id !== healthRemindersData.id) {
          return item;
        }

        return {
          ...item,
          [fieldName]: newHelperText,
        };
      });
    });

    setHealthRemindersData(prevState => {
      return prevState.map((item) => {
        if (item.id !== healthRemindersData.id) {
          return item;
        }

        return {
          ...item,
          [fieldName]: newValue,
        };
      });
    });
  }, [healthRemindersData.id]);

  function handleAddTimeTimePickChange(newValue) {
    setTimePickerAddTime(newValue);
  }

  const handleAddTimeIconClick = useCallback((action, inputRemovedTime) => {
    setHealthRemindersData(prevState => {
      return prevState.map((item) => {
        if (item.id !== healthRemindersData.id) {
          return item;
        }

        const newTimeValue = timePickerAddTime ? `${dayjs(timePickerAddTime).format('HH')}:${dayjs(timePickerAddTime).format('mm')}:00` : '';
        let newHelperText = '';

        if (newTimeValue && item.dailyJobAtTimes.includes(newTimeValue)) {
          newHelperText = 'The reminder time has been repeated';
          setIsValidateField(prevState => {
            return prevState.map((item) => {
              if (item.id !== healthRemindersData.id) {
                return item;
              }

              return {
                ...item,
                addTime: newHelperText,
              };
            });
          });
          return item;
        }

        let updatedTime = [...item.dailyJobAtTimes];
        if (action === 'add' && newTimeValue) {
          updatedTime.push(newTimeValue);
          updatedTime.sort((a, b) => {
            const timeA = dayjs(a, 'HH:mm');
            const timeB = dayjs(b, 'HH:mm');
            return timeA.isBefore(timeB) ? -1 : 1;
          });
        }

        if (action === 'remove' && inputRemovedTime) {
          updatedTime = updatedTime.filter(time => time !== inputRemovedTime);
        }

        setIsValidateField(prevState => {
          return prevState.map((item) => {
            if (item.id !== healthRemindersData.id) {
              return item;
            }

            return {
              ...item,
              addTime: '',
              patterns: '',
            };
          });
        });

        return {
          ...item,
          dailyJobAtTimes: updatedTime,
        };
      });
    });

    if (action === 'add') {
      setTimePickerAddTime(null);
    }
  }, [healthRemindersData.id, timePickerAddTime, setIsValidateField, setTimePickerAddTime]);

  function handleAlertDialogClick(state, id) {
    setHealthRemindersAlertDialog({ state, id })
  }

  function handleRemovedTableClick() {
    const requestDeleteOneCron = {
      resource: `/users/0/crons/${healthRemindersAlertDialog.id}`,
      verb: "delete",
      accessToken: token,
    }
    ws.send(JSON.stringify(requestDeleteOneCron));
  }

  return (
    <Grid container justifyContent="space-between" alignItems="flex-start" spacing={2} >
      <Grid item xs={2}>
        <Box display="flex" justifyContent="center" alignItems="center" height="100%" position="relative" left="-7%">
          <HeartbeatIcon className={recipientStyle.mainIcon} />
        </Box>
      </Grid>
      <Grid item xs={5}>
        <InputField
          id={`name-${healthRemindersData.id}`}
          label="Name"
          placeholder="Name"
          value={healthRemindersData.name}
          onChange={(event) => handleOnChange(event, 'name', 'input')}
          iconClick={(event) => handleOnChange(event, 'name', 'inputClear')}
          helperText={isValidateField.name}
        />
        <SwitchField
          label="Alarm"
          checked={healthRemindersData.enabled}
          onChange={() => handleOnChange(healthRemindersData.enabled, 'enabled', 'switch')}
        />
        <TypographyField
          label="Reminder"
          textColor="#D3302F"
          text={isValidateField.patterns}
        />
        <AddTimeField
          timeList={healthRemindersData.dailyJobAtTimes}
          removedIconClick={handleAddTimeIconClick}
          timeListId={`healthy-tracking-add-time-Field-${healthRemindersData.id}`}
          timePickerValue={timePickerAddTime}
          timePickerOnChange={handleAddTimeTimePickChange}
          addIconClick={() => handleAddTimeIconClick('add')}
          timePickerHelperText={isValidateField.addTime}
        />
      </Grid>
      <Divider orientation="vertical" variant="middle" flexItem sx={{ margin: '20px 20px 0 20px' }} />
      <Grid item xs={3}>
        <DatepickerField
          label="Start"
          formatDate="YYYY/M/D"
          value={dayjs.unix(healthRemindersData.startTime).format('YYYY/M/D')}
          onChange={(event) => handleOnChange(event, 'startTime', 'datepick')}
          helperText={isValidateField.startTime}
        />
        <InputField
          id={`healthy-tracking-every-${healthRemindersData.id}`}
          label="Every"
          placeholder="one to seven days"
          value={healthRemindersData.dailyJobInterval}
          maxLength={1}
          onChange={(event) => handleOnChange(event, 'dailyJobInterval', 'input')}
          iconClick={(event) => handleOnChange(event, 'dailyJobInterval', 'inputClear')}
          helperText={isValidateField.dailyJobInterval}
        />
        <DatepickerField
          label="Until"
          formatDate="YYYY/M/D"
          value={dayjs.unix(healthRemindersData.endTime).format('YYYY/M/D')}
          onChange={(event) => handleOnChange(event, 'endTime', 'datepick')}
          helperText={isValidateField.endTime}
        />
        <InputField
          id={`healthy-tracking-snooze-${healthRemindersData.id}`}
          label="Snooze"
          placeholder="one to thirty min"
          value={healthRemindersData.snooze}
          maxLength={2}
          onChange={(event) => handleOnChange(event, 'snooze', 'input')}
          iconClick={(event) => handleOnChange(event, 'snooze', 'inputClear')}
          helperText={isValidateField.snooze}
        />
      </Grid>
      <Grid item xs={1}>
        {healthRemindersData.id && (
          <>
            <Box position="relative" top="-8px" sx={{ minWidth: 170, margin: "0 10px" }}>
              <IconButton aria-label="remove-table-icon" onClick={() => handleAlertDialogClick(true, healthRemindersData.id)}>
                <RemovedTableBtn />
              </IconButton>
            </Box>
            {healthRemindersData.id === healthRemindersAlertDialog.id && (
              <AlertDialog
                opneState={healthRemindersAlertDialog.state}
                onClose={() => handleAlertDialogClick(false, null)}
                dialogTitle="Alert"
                dialogContent="Whether to delete reminder messages ?"
                actionButton={
                  <>
                    <Button onClick={() => handleAlertDialogClick(false, null)}>Disagree</Button>
                    <Button onClick={handleRemovedTableClick} autoFocus>Agree</Button>
                  </>
                }
              />
            )}
          </>
        )}
      </Grid>
    </Grid>
  )
};

const HealthReminders = ({ ws, data, setData, alertDialog, setAlertDialog, isValidateField, setIsValidateField, token }) => (
  <Grid container spacing={2}>
    <Grid item xs={12}>
      <Box>
        <TimeSetting
          ws={ws}
          healthRemindersData={data}
          setHealthRemindersData={setData}
          healthRemindersAlertDialog={alertDialog}
          setHealthRemindersAlertDialog={setAlertDialog}
          isValidateField={isValidateField}
          setIsValidateField={setIsValidateField}
          token={token}
        />
      </Box>
    </Grid>
  </Grid>
)

export default HealthReminders;