// routes.js
import React from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";
import { AuthCallback } from "casdoor-react-sdk";
import HomePage from "../HomePage";
import Layout from "../ui/Layout";
import Dashboard from "../pages/Dashboard";
import Summary from "../pages/Summary";
import Recipient from "../pages/Recipient";
import Devices from "../pages/Devices";
import Calendar from "../pages/Calendar";
import Event from "../pages/Event";
import Question from "../pages/Question";
import Policy from "../pages/Policy";
import Settings from "../pages/Settings";
import AddRecipient from "../pages/AddRecipient/index";
import AddGroup from "../pages/AddGroup/index";
import DashboardOld from "../pages/DashboardOld/index";
import * as Setting from "../pages/Login/Setting";
import { isAuthenticated } from "../utils/auth";

const handleSaveTokenFromResponse = (res) => {
  Setting.setSettingToken(res.data);
  Setting.goToLink("/");
  Setting.getRefreshToken(res.refreshToken)
};

const isGetTokenSuccessful = (res) => res.status === "ok";

const ProtectedRoute = ({ element }) => {
  return isAuthenticated() ? element : <Navigate to="/" />;
};

const routes = [
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/callback",
    element: (
      <AuthCallback
        sdk={Setting.CasdoorSdk}
        serverUrl={Setting.ServerUrl}
        saveTokenFromResponse={handleSaveTokenFromResponse}
        isGetTokenSuccessful={isGetTokenSuccessful}
      />
    ),
  },
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "dashboard",
        element: <ProtectedRoute element={<Dashboard />} />,
      },
      {
        path: "summary",
        element: <ProtectedRoute element={<Summary />} />,
      },
      {
        path: "recipient_setting",
        element: <ProtectedRoute element={<Recipient />} />,
      },
      {
        path: "devices",
        element: <ProtectedRoute element={<Devices />} />,
      },
      {
        path: "calendar",
        element: <ProtectedRoute element={<Calendar />} />,
      },
      {
        path: "event",
        element: <ProtectedRoute element={<Event />} />,
      },
      {
        path: "user_setting",
        element: <ProtectedRoute element={<Settings />} />,
      },
      {
        path: "add_recipient",
        element: <ProtectedRoute element={<AddRecipient />} />,
      },
      {
        path: "add_group",
        element: <ProtectedRoute element={<AddGroup />} />,
      },
      {
        path: "IoTDashboard",
        element: <ProtectedRoute element={<DashboardOld />} />,
      },
      {
        path: "question",
        element: <ProtectedRoute element={<Question />} />,
      },
      {
        path: "policy",
        element: <ProtectedRoute element={<Policy />} />,
      },
    ],
  },
];

const router = createBrowserRouter(routes);

export default router;