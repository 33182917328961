import './modal-container.component.scss';

import { memo, useEffect } from 'react';

const ModalContainer = (props) => {
  const {
    id,
    style = {},
    display,
    modalWidth,
    children,
  } = props;

  const displayModal = { display: display ? 'block' : 'none' };

  return (
    <div className={`custom-modal`} style={displayModal}>
      <div className={`custom-modal-content ${'modal-'+modalWidth || 'modal-auto'}`}>
        {display && children}
      </div>
    </div >
  );
}

export default memo(ModalContainer);