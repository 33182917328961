import React, { useState, useEffect } from "react";
import calendarStyle from "./calendar.module.scss";

import { useNavigate } from "react-router-dom";

import { useWebSocket } from "hook/WebSocketContext";
import { useAuth } from "../../../hook/AuthContext";

import dayjs from 'dayjs';

import { useTheme, useMediaQuery } from "@mui/material";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CardContent from "@mui/material/CardContent";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";

import { ReactComponent as ArrowBackIcon } from 'assets/img/icon/btn_calendar_arrow_left.svg';
import { ReactComponent as ArrowForwardIcon } from 'assets/img/icon/btn_calendar_arrow_right.svg';
import { ReactComponent as ExternalExpandIcon } from 'assets/img/icon/btn_external_expand.svg';

import { CHINESE_MONTHS, WEEK_DAYS, FAKE_DATA, AVATAR_IMG } from "./const";

// Calendar style
const calendarContentStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  width: '100%',
  height: '100%',
  maxWidth: '100%',
  maxHeight: 'none',
  '@media (min-width: 1200px)': {
    height: '52%',
  },
  '@media (min-width: 1200px) and (max-width: 1439px)': {
    height: '95%',
  },
  '& .MuiDayCalendar-slideTransition': {
    minHeight: '300px',
    '@media (max-width: 600px)': {
      minHeight: '240px',
    },
  },
  '.MuiPickersCalendarHeader-root': {
    marginBottom: '16px',
    width: '100%',
  },
  '.MuiDayCalendar-header': {
    display: 'flex',
    justifyContent: 'space-around',
  },
  '& .MuiDayCalendar-weekDayLabel': {
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: 1.43,
    color: '#737791',
    height: '50px',
  },
  '.MuiPickersDay-root': {
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    margin: '0 auto',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: 1.43,
    '@media (max-width: 600px)': {
      width: '35px',
      height: '35px',
    },
    '@media (min-width: 1200px)': {
      width: '52px',
      height: '52px',
    },
  },
}

const AvatarGroup = ({ data, length }) => (
  <div className={calendarStyle.avatarGroup}>
    {
      data.map((participant, index) => (
        index <= length && <img
          style={{ marginLeft: index === 0 ? 0 : '-15px' }}
          className={calendarStyle.avatar}
          key={index}
          src={AVATAR_IMG[participant]}
          alt={participant}
        />
      ))
    }
    {data.length > length && <span className={`${calendarStyle.avatar} ${calendarStyle.more}`}>+{length}</span>}
  </div>
);

const CalendarFeild = ({ selectedDate, setSelectedDate, highlightedDays }) => {
  const CustomCalendarHeader = (props) => {
    const { currentMonth, onMonthChange } = props;
    const navigate = useNavigate();

    return (
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <IconButton
            onClick={() => onMonthChange(dayjs(currentMonth).subtract(1, 'month'))}
          >
            <ArrowBackIcon className={calendarStyle.calendarIcon} />
          </IconButton>
          <Typography
            sx={{
              marginRight: '24px', marginLeft: '24px', color: '#737791',
              fontWeight: 600, fontSize: '18px', lineHeight: 1.11, textAlign: 'left'
            }}
          >
            {CHINESE_MONTHS[dayjs(currentMonth).month()]} {dayjs(currentMonth).year()}
          </Typography>
          <IconButton
            onClick={() => onMonthChange(dayjs(currentMonth).add(1, 'month'))}
          >
            <ArrowForwardIcon className={calendarStyle.calendarIcon} />
          </IconButton>
        </Box>
        <Button variant="outlined"
          startIcon={<ExternalExpandIcon className={calendarStyle.calendarIcon} />}
          sx={{
            display: 'flex', gap: '8px', padding: '8px 16px', fontSize: '16px', fontWeight: 600,
            lineHeight: 1.25, color: '#424962', borderRadius: '10px', borderColor: '#424962',
          }}
        // onClick={() => navigate('/calendar')}
        >
          更多資訊
        </Button>
      </Box>
    );
  };

  const ServerDay = (props) => {
    const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

    const dayOfMonth = dayjs(day).format('YYYY-MM-DD')
    const today = dayjs().format('YYYY-MM-DD');

    const isToday = dayOfMonth === today;
    const isSelectedToday = dayjs(selectedDate).format('YYYY-MM-DD') === today;
    const isSelectedDate = !outsideCurrentMonth && highlightedDays.includes(dayOfMonth);

    const isHighlightedDate = dayOfMonth === dayjs(selectedDate).format('YYYY-MM-DD');

    return (
      <PickersDay
        {...other}
        day={day}
        outsideCurrentMonth={outsideCurrentMonth}
        sx={{
          "&.MuiPickersDay-root": {
            position: 'relative',
            border: 'none',
            color: outsideCurrentMonth ? '#cccccc' : isToday ? '#0087a9' : '#737791',
            "&::after": {
              content: '""',
              display: isSelectedDate ? 'block' : 'none',
              width: '5px',
              height: '5px',
              backgroundColor: isHighlightedDate ? '#ffffff' : '#737791',
              borderRadius: '50%',
              position: 'absolute',
              bottom: 10,
              left: '50%',
              transform: 'translateX(-50%)',
              '@media (max-width: 600px)': {
                bottom: 4,
              },
            },
          },
          "&.Mui-selected": {
            backgroundColor: isSelectedToday ? '#0087a9 !important' : '#737791 !important',
            color: '#ffffff',
          },
        }}
      />
    );
  }

  return (
    <>
      {
        highlightedDays && <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateCalendar
            slots={{
              calendarHeader: CustomCalendarHeader,
              day: ServerDay,
            }}
            slotProps={{
              day: {
                highlightedDays,
              },
            }}
            sx={{ ...calendarContentStyle }}
            showDaysOutsideCurrentMonth
            dayOfWeekFormatter={(date) => (WEEK_DAYS[date.day()])}
            fixedWeekNumber={4}
            value={selectedDate}
            onChange={(newDate) => setSelectedDate(newDate)}
          />
        </LocalizationProvider>
      }
    </>
  );
}

const ScheduleList = ({ isSelectedDate, selectedData }) => (
  <div className={calendarStyle.scheduleListContainer}>
    {
      isSelectedDate && selectedData.map((item) => (
        <div style={{ borderColor: item.color[0] }} key={item.id} className={calendarStyle.scheduleList}>
          <div style={{ backgroundColor: item.color[1] }} className={calendarStyle.scheduleItem}>
            <div className={calendarStyle.scheduleItemActivity}>
              <AvatarGroup
                data={item.participants}
                length={2}
              />
              <div className={calendarStyle.info}>
                <div className={calendarStyle.title}>{item.activity}</div>
                <div className={calendarStyle.subtitle}>
                  {item.participants.map((participant, index) => (
                    index <= 2 && <span key={index}>{participant};</span>
                  ))}
                  {item.type}
                </div>
              </div>
            </div>
            <div className={calendarStyle.scheduleItemTimeLine}>
              {
                item.starttime && item.endtime
                  ? <>
                    <div className={calendarStyle.startTime}>{item.starttime}</div>
                    <div className={calendarStyle.endTime}>{item.endtime}</div>
                  </>
                  : <div className={calendarStyle.allDay}>整天</div>
              }
            </div>
          </div>
        </div>
      )) || <Box sx={{ height: '200px', display: 'flex' }}>
        <Typography sx={{ margin: 'auto', color: '#424962', lineHeight: 1.25, fontSize: '16px', fontWeight: 600 }} >
          無行程
        </Typography>
      </Box>
    }
  </div>
)

const Calendar = (props) => {
  const { wsData, setWsData, fullScreenArrange } = props;
  const ws = useWebSocket();
  const { token } = useAuth();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(1440));

  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [highlightedDays, setHighlightedDays] = useState([]);
  const [selectedData, setSelectedData] = useState([]);

  const isSelectedDate = highlightedDays.includes(dayjs(selectedDate).format('YYYY-MM-DD'));

  useEffect(() => {
    const highlightDates = [...new Set(FAKE_DATA.map(event => event.date))];
    setHighlightedDays(highlightDates);
  }, [])

  useEffect(() => {
    const findCurrentScheduleData = FAKE_DATA.filter((item) => (item.date === selectedDate.format('YYYY-MM-DD')));
    setSelectedData(findCurrentScheduleData)
  }, [selectedDate])


  return (
    <CardContent sx={{ padding: '20px 30px 0', height: 'calc(100% - 50px)' }}>
      {fullScreenArrange && <>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={isSmallScreen ? 12 : 10}>
              <CalendarFeild
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                highlightedDays={highlightedDays}
              />
            </Grid>
            <Grid item xs={isSmallScreen ? 12 : 2}>
              <ScheduleList
                isSelectedDate={isSelectedDate}
                selectedData={selectedData}
              />
            </Grid>
          </Grid>
        </Box>
      </>
      }
      {!fullScreenArrange && <>
        <CalendarFeild
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          highlightedDays={highlightedDays}
        />
        <ScheduleList
          isSelectedDate={isSelectedDate}
          selectedData={selectedData}
        />
      </>
      }
    </CardContent>
  )
}

export default Calendar;
