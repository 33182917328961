// page utils
import mainStyle from "./header.module.scss";
import { useState, useEffect, React } from "react";
import { useNavigate } from "react-router-dom";
import { useOutletContext } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import { cloneDeep, isNil } from "lodash";
import dayjs from "dayjs";

import * as Setting from "pages/Login/Setting";
import { useSelector, useDispatch } from "react-redux";
import { counterActions, fetchCount } from "stores/slice/counter";
import { userActions, fetchUser } from "stores/slice/user";

import { useMediaQuery, useTheme } from "@mui/material";
// images
import { ReactComponent as Logo } from "assets/img/icon/logo.svg";
import { ReactComponent as Event } from "assets/img/icon/icn_nav_event.svg";
import { ReactComponent as Language } from "assets/img/icon/icn_nav_language.svg";
import { ReactComponent as User } from "assets/img/icon/icn_nav_user.svg";
import { ReactComponent as Weather } from "assets/img/icon/icn_nav_weather_cloudy.svg";
import { ReactComponent as Clock } from "assets/img/icon/icn_nav_clock.svg";
import { ReactComponent as Upper } from "assets/img/icon/btn-arrow-dropdown-down.svg";
import { ReactComponent as Search } from "assets/img/icon/icn_magnifier.svg";

import { useNotification } from "../../hook/NotificationContext";
import { useWebSocket } from "hook/WebSocketContext";
import { useAuth } from "../../hook/AuthContext";

const Header = (props) => {
  const { unreadCount, setUnReadCount } = useNotification();

  const tmp_userInfo = useSelector((state) => state.user.userInfo);
  const dispatch = useDispatch();
  const currentTime = Math.floor(new Date().getTime() / 1000);

  const { wsData, setWsData } = props;
  const [time, setTime] = useState("");
  const [date, setDate] = useState("");
  const ws = useWebSocket();
  const { token, expiresTime } = useAuth();
  const grayColor = "#424962";

  const [startDate, setStartDate] = useState(dayjs().subtract(15, "day"));
  const startOfStartDate = startDate.startOf("day");
  const startDateTimestamp = startOfStartDate.unix();

  const [endDate, setEndDate] = useState(dayjs());
  const endOfEndDate = endDate.endOf("day");
  const endDateTimestamp = endOfEndDate.unix();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(1024));
  const isMiniScreen = useMediaQuery(theme.breakpoints.down(960));
  const [rightMargin, setRightMargin] = useState(30);

  useEffect(() => {
    if(isSmallScreen){
      if(isMiniScreen){
        setRightMargin(0)
      }else{
        setRightMargin(10)
      }
    }else{
      setRightMargin(30)
    }
  }, [isSmallScreen, isMiniScreen]);

  useEffect(() => {
    if (currentTime < expiresTime) {
      Setting.getUserinfo().then((res) => {
        console.log(res);
        if (res?.status === "ok") {
          // tmp_userInfo = Object.assign(tmp_userInfo, res.data)
          // redux
          dispatch(
            userActions.setUser({
              // userInfo : tmp_userInfo
              userInfo: res.data,
            })
          );
        } else {
          /* Setting.showMessage(`getUserinfo() header error: ${res?.msg}`); */
          localStorage.clear();
          Setting.goToLink("/");
        }
      });
      // console.log('header dispatch')
      // console.log(test)
      // console.log(dispatch)

      // dispatch(fetchCount(test));

      // 清理函数，用于组件卸载时清除用户数据
      // return () => {
      //   dispatch(clearUser());
      // };
    } else {
      localStorage.clear();
      Setting.goToLink("/");
    }

  }, [dispatch]);

  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/event");
  };

  const getCurrentTime = () => {
    const now = new Date();
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");
    setTime(`${hours}:${minutes}`);
  };

  const getCurrentDate = () => {
    const now = new Date();
    const day = now.getDate().toString().padStart(2, "0");
    const month = now.toLocaleString("en-US", { month: "short" });
    setDate(`${day} ${month}`);
  };

  const base64String = tmp_userInfo.portrait;

  useEffect(() => {
    getCurrentTime();
    getCurrentDate();
    const timer = setInterval(getCurrentTime, 1000);
    return () => clearInterval(timer);
  }, []);

  const getData = async () => {
    const eventSendData = {
      resource: `/boxes/0/events`,
      verb: "read",
      accessToken: token,
      data: {
        filter: {
          startTime: startDateTimestamp,
          endTime: endDateTimestamp,
          category:
            "reportAbnormal, notTakeBloodPressure, notTakeMedicine, abuse, falldown, sleepingSickness",
        },
      },
    };

    ws.send(JSON.stringify(eventSendData));
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (
      wsData.updateTag === "event_read" &&
      wsData.updateData &&
      wsData.updateData.category ===
        "reportAbnormal, notTakeBloodPressure, notTakeMedicine, abuse, falldown, sleepingSickness"
    ) {
      const { ackCount } = wsData.updateData;
      localStorage.setItem("ackCount", ackCount);
      const localAckCount = parseInt(localStorage.getItem("ackCount"), 10);
      setUnReadCount(localAckCount);

      console.log("Ack Count:", unreadCount);
    }
  }, [wsData]);

  return (
    <>
      <div className={mainStyle["headerBox"]}>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div style={{ width: "140px", height: "30px" }}>
              <Logo />
            </div>
            <Divider
              orientation="vertical"
              variant="top"
              style={{
                margin: "0 20px 0 10px",
                height: "72px",
                color: "#bad4dd",
              }}
            />
            <Typography
              component="div"
              color={grayColor}
              fontWeight="normal"
              fontSize="24px"
            >
              Welcome {tmp_userInfo.name}
            </Typography>
          </Box>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {/* Time */}
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: rightMargin+'px',
              }}
            >
              <Clock />
              <Box
                ml={2}
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <div
                  style={{
                    color: grayColor,
                    fontWeight: "normal",
                    whiteSpace: "nowrap",
                    fontSize: "13px",
                  }}
                >
                  {date}
                </div>
                <div
                  style={{
                    color: grayColor,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  {time}
                </div>
              </Box>
            </Box>

            {/* Event */}
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: rightMargin+'px',
              }}
            >
              <IconButton onClick={handleClick}>
                <Badge color="error" badgeContent={unreadCount}>
                  <Event />
                </Badge>
              </IconButton>
            </Box>

            {/* Language */}

            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginLeft: rightMargin+'px',
              }}
            >
              <Language style={{ marginRight: "10px" }} />
              <Typography
                component="div"
                color="grayColor"
                fontWeight="normal"
                style={{
                  fontSize: "16px",
                }}
              >
                繁體中文
              </Typography>

              <IconButton
                disableRipple
                sx={{
                  "&:hover": {
                    cursor: "not-allowed",
                  },
                }}
              >
                <Upper />
              </IconButton>
            </Box>

            {/* User */}

            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "139px",
                marginLeft: rightMargin+'px',
              }}
            >
              <Avatar
                sx={{
                  width: 40,
                  height: 40,
                  backgroundColor: base64String ? "transparent" : "gray",
                  border: "2px solid #d3d3d3",
                  borderRadius: "999px",
                }}
                style={{ marginRight: "10px" }}
              >
                {base64String ? (
                  <img
                    src={base64String}
                    alt={tmp_userInfo?.name}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <User />
                )}
              </Avatar>

              <Typography
                component="div"
                color="grayColor"
                fontWeight="normal"
                style={{
                  whiteSpace: "nowrap",
                  fontSize: "16px",
                }}
              >
                {tmp_userInfo.name}
              </Typography>
              <IconButton
                sx={{
                  maxWidth: "50%",
                  height: "auto",
                  "&:hover": {
                    cursor: "not-allowed",
                  },
                }}
                disableRipple
              >
                <Upper />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </div>
    </>
  );
};

export default Header;
