import React, { useState } from 'react';

import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import FieldContainer from "./FieldContainer";

import { ReactComponent as SelectDropdownDownBtn } from 'assets/img/icon/btn_arrow_dropdown_down.svg';
import { ReactComponent as SelectDropdownUpperBtn } from 'assets/img/icon/btn_arrow_dropdown_upper.svg';

export const SelectInput = ({ id, value, onChange, data, placeholder, selectedData, helperText }) => {
    const [open, setOpen] = useState(false);

    return (
        <>
            <Select
                sx={{
                    height: '40px',
                    borderRadius: '10px',
                    color: "#454D76",
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#EEF0F2',
                    },
                    '& .MuiSelect-icon': {
                        top: '50%',
                        transform: 'translateY(-50%)',
                    },
                }}
                IconComponent={open ? SelectDropdownUpperBtn : SelectDropdownDownBtn}
                id={id}
                displayEmpty
                value={value}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                onChange={onChange}
                error={!!helperText}
                renderValue={(selected) => {
                    const selectedItem = data.find(item => item.value === selected);
                    return selectedItem ? selectedItem.label : <span style={{ color: "#999" }}>{placeholder}</span>;
                }}
            >
                <MenuItem value="" disabled>
                    {placeholder}
                </MenuItem>
                {data.map((item, index) => (
                    <MenuItem key={`${id}-${index}`} value={item.value} disabled={selectedData && selectedData.includes(item.label)}>
                        {item.label}
                    </MenuItem>
                ))}
            </Select>
            {helperText && <FormHelperText sx={{ color: '#D3302F' }}>{helperText}</FormHelperText>}
        </>
    )
};

const SelectField = ({ label, id, value, onChange, data, placeholder, helperText }) => (
    <FieldContainer label={label}>
        <Box sx={{ minWidth: 180 }}>
            <FormControl fullWidth>
                <SelectInput
                    id={id}
                    value={value}
                    onChange={onChange}
                    data={data}
                    placeholder={placeholder}
                    helperText={helperText}
                />
            </FormControl>
        </Box>
    </FieldContainer>
);

export default SelectField;