import React from "react";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ReactComponent as Calendar } from "assets/img/icon/icn_menu_schedule.svg";
const grayColor = "#BEBEBE";

const CustomDatePicker = ({ value, onChange, maxDate, minDate }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={["DatePicker"]}>
        <DatePicker
          value={value}
          onChange={onChange}
          format="YYYY/MM/DD"
          maxDate={maxDate}
          minDate={minDate}
          slots={{
            openPickerIcon: Calendar,
          }}
          slotProps={{
            day: {
              sx: {
                "&.MuiPickersDay-root.Mui-selected": {
                  backgroundColor: "#0A87A9",
                },
              },
            },
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: grayColor,
                margin: "5px 0px",
              },
              "&:hover fieldset": { borderColor: grayColor },
              "&.Mui-focused fieldset": {
                borderColor: grayColor,
              },
            },
            width: "150px",
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: grayColor,
              borderRadius: "12px",
            },
          }}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
};

export default CustomDatePicker;
