import React from "react";
import addGroupStyle from '../group.module.scss';

import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

const ImageUploader = ({ imagePreview, setImagePreview }) => {

  function handleImageUpload(event) {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  }

  function handleClick() {
    document.getElementById("imageInput").click();
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <Box
        sx={{
          width: "300px",
          height: "200px",
          backgroundColor: "#e0e0e0",
          marginBottom: "10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {imagePreview && (
          <Box
            component="img"
            src={imagePreview}
            alt="Image Preview"
            sx={{
              maxWidth: "100%",
              maxHeight: "100%",
            }}
          />
        )}
      </Box>

      <input
        id="imageInput"
        type="file"
        accept="image/*"
        hidden
        onChange={handleImageUpload}
      />

      <Button
        sx={{
          fontSize: "16px",
          fontWeight: 600,
          borderRadius: "10px",
          backgroundColor: "#0A87A9",
          padding: "8px 16px",
          "&:hover": {
            backgroundColor: "#05647E",
          },
        }}
        variant="contained"
        onClick={handleClick}
      >
        <div className={addGroupStyle.customAddIcon} />
        Upload Image
      </Button>
    </Box>
  );
}

export default ImageUploader;
