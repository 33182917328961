import React, { useState, useEffect } from "react";
import mainStyle from '../../dashboard.module.scss';
import recipientStyle from '../recipient.module.scss';
import { useWebSocket } from "hook/WebSocketContext";
import { useAuth } from "../../../../hook/AuthContext";
import { cloneDeep } from 'lodash';
import dayjs from "dayjs";
import Grid from "@mui/material/Grid";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from '@mui/material/ListItemButton';
import heart from "assets/img/icon/icn_heartbeat.svg";
import medicineBox from "assets/img/icon/icn_medicine_group.svg";
import medicine1 from "assets/img/icon/icn_medicine_1.svg";
import medicine2 from "assets/img/icon/icn_medicine_2.svg";
import medicine3 from "assets/img/icon/icn_medicine_3.svg";
import arrowDown from "assets/img/icon/btn_arrow_down.svg";
import arrowUp from "assets/img/icon/btn_arrow_upper.svg";

import controller from '../controller';

const TodayList = (props) => {
  const {
    wsData, setWsData
  } = props;

  const ws = useWebSocket();
  const { token } = useAuth();
  const [medicineData, setMedicineData] = useState([]);

  function handleShowDetail(groupId) {
    const newMedicineData = [...medicineData];
    for (let item of newMedicineData) {
      if(item.gId === groupId && item.type === 'takeMedicine'){
        let ifShow = item.showFullInfo
        item.showFullInfo = !ifShow;
      }
    }
    setMedicineData(newMedicineData);
  }

  const updateNotice = async (item) => {
    let defaultData = cloneDeep(medicineData);
    defaultData.forEach((subdata, index) => {
      if(subdata.cron === item.cronId && subdata.dailyTime === item.dailyJobAtTime){
        subdata.showNotice = true;
      }
    })
    setMedicineData(defaultData);
  }

  const notifyData = async (itemAry) => {
    let defaultData = cloneDeep(medicineData);
    let noticeAry = cloneDeep(itemAry);

    noticeAry.forEach((notice, index) => {
      defaultData.forEach((subdata, i) => {
        if(subdata.cron === notice.data.cronId && subdata.dailyTime === notice.data.dailyJobAtTime){
          subdata.showNotice = true;
        }
      })
    })
    setMedicineData(defaultData);

    let cleanWsTag = cloneDeep(wsData); //for fix event page issue
    cleanWsTag.updateTag = 'clean_tag';
    setWsData(cleanWsTag);
  }

  const formatData = async (updateData) => {
    let defaultData = cloneDeep(updateData);
    let todayList = [], sortToday =[];

    defaultData.forEach((subdata, index) => {
      let medicineBox = []
      subdata.medicines.forEach((drug, index) => {
        let tmpDrug = {
          medicineName: drug.name,
          medicineShape: drug.shape,
          medicineQuantity: drug.quantity
        }
        if(tmpDrug.medicineShape === 'capsule'){
          tmpDrug.medicineType = medicine1;
        }else if(tmpDrug.medicineShape === 'tablet'|| tmpDrug.medicineShape === 'powder'){
          tmpDrug.medicineType = medicine2;
        }else{
          tmpDrug.medicineType = medicine3;
        }
        medicineBox.push(tmpDrug)
      })

      subdata.dailyJobAtTimes.forEach((item, index) => {
        let tmpItem = {
          dailyTime: item,
          type: subdata.type,
          enabled: subdata.enabled,
          groupName: subdata.name,
          groupId: subdata.id,
          groupContent: medicineBox,
          hasFullInfo: medicineBox.length > 0? true:false,
          showFullInfo: false,
          showNotice: false,
          description: '',
          cron: subdata.id,
          gId: todayList.length+1,
        }
        let time = item.split(':'), stamp = "";
        stamp = time[0]+':'+time[1];

        tmpItem.timestamp = stamp
        tmpItem.timeNum = parseInt(time[0])*100 + parseInt(time[1])
        todayList.push(tmpItem);
      })
    })

    sortToday = todayList.sort(sortTime)
    setMedicineData(sortToday);
    getNotice();
  };

  function sortTime(a,b){
    return a.timeNum-b.timeNum;
  }

  const getNotice = async () => {
    let now = parseInt(dayjs().format('X'));
    let start = controller.getDate0000(now*1000);
    let end = start+86399;
    const requestCatEvent = {
      "resource": "/boxes/0/events",
      "verb": "read",
      "accessToken": token,
      "data": {
        "filter": {
          "startTime": start,
          "endTime":end,
          "category": "notTakeMedicine, notTakeBloodPressure"
        }
      }
    }
    ws.send(JSON.stringify(requestCatEvent));
  }

  const getData = async () => {
    const requestAllCron = {
      resource: "/users/0/crons",
      verb: "read",
      accessToken: token,
    }
    ws.send(JSON.stringify(requestAllCron));
  }

  useEffect(() => {
    if (wsData.updateTag === "allCron" && wsData.updateData) {
      console.log('Today summary get read ws')
      console.log(wsData.updateData)
      let tmpTodayData = cloneDeep(wsData.updateData);
      // let tmpTodayData = wsData.updateData.filter((item) => item.type === 'takeMedicine');
      formatData(tmpTodayData);
    }

    if (wsData.updateTag === "createCron" || wsData.updateTag === "deleteCron") {
      if(wsData.updateData){
        console.log('Today summary get creat ws')
        console.log(wsData)
        getData();
      }
    }

    if (wsData.updateTag === "abnormalEvent" && wsData.updateData) {
      if(wsData.updateData.type === 'notTakeMedicine' || wsData.updateData.type === 'notTakeBloodPressure'){
        console.log('Today summary get notice ws')
        console.log(wsData)
        let noticeItem = cloneDeep(wsData.updateData.data);
        updateNotice(noticeItem);
      }
    }

    if (wsData.updateTag === "event_read" && wsData.updateData) {
      if(wsData.updateData.category === 'notTakeMedicine, notTakeBloodPressure' && wsData.updateData.data!== null){
        console.log('Today summary get notice ws')
        console.log(wsData)
        let noticeAry = cloneDeep(wsData.updateData.data);
        notifyData(noticeAry);
      }
    }

  }, [wsData])

  const MedicineGroup = (props) => {
    return(
      <>
        {medicineData.map((group, index) => (
          group.enabled === false? <div key={index}></div>:
          <div key={index}>
            <ListItem sx={{p: 0}}
              onClick={() =>handleShowDetail(group.gId)}
              style={{background: group.showNotice? "#FFF4F4": ''}}>
              <ListItemButton sx={{p: 1}} style={{height: '60px'}} disableRipple>
                <ListItemIcon style={{
                  minWidth: '35px',
                  marginLeft: group.type==='takeBloodPressure'?'1px':'0px',}}
                  >
                  <img src={group.type==='takeBloodPressure'? heart: group.showFullInfo? arrowDown: arrowUp}/>
                </ListItemIcon>
                {group.hasFullInfo && <ListItemIcon style={{minWidth: '35px'}}>
                  <img src={medicineBox}/>
                </ListItemIcon>}
                <ListItemText
                  primary={group.groupName}
                  primaryTypographyProps={{
                    style: {
                      color: "#0A87A9",
                      marginLeft: group.type==='takeBloodPressure'?'1px':'0px',
                    },
                  }}
                />
                <ListItemText
                  primary={group.timestamp}
                  primaryTypographyProps={{
                    style: {
                      color: "#6f748f",
                      display:'flex', justifyContent:'flex-end'
                    },
                  }}
                />
              </ListItemButton>
            </ListItem>
            <MedicineDetail show={group.showFullInfo} notice={group.showNotice} data={group.groupContent}/>
          </div>
        ))}

      </>
    )
  };

  const MedicineDetail = (props) => {
    const detailInfo = props.data;
    const showInfo = props.show;
    const showColor = props.notice;
    return(
      <>
        {showInfo && detailInfo.map((item, index) => (
          <ListItem sx={{p: 0}} key={index}
            style={{background: showColor? "#FFF4F4": ''}}>
            <ListItemButton sx={{p: 1}} style={{height: '60px'}} disableRipple>
              <ListItemIcon style={{minWidth: '35px', marginLeft: '35px'}}>
                <img src={item.medicineType}/>
              </ListItemIcon>
              <ListItemText
                primary={item.medicineName}
                primaryTypographyProps={{
                    style: {
                    color: "#0A87A9",
                  },
                }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </>
    )
  };

  return (
    <>
      <Card variant="outlined" style={{ width: '100%', height: '400px', marginTop: '20px', borderRadius: '10px',}}>
        <Box style={{padding: '10px 0px', width: 'auto', height: '380px',}}>
          <Typography style={{padding: '0px 10px', fontWeight:"bold"}} variant="h5" component="div" color="#0A87A9">
            Today
          </Typography>
          <List className={recipientStyle.listContainer}>
            {/* <ListItem sx={{p: 0}} style={{background: "#FFF4F4",}}>
              <ListItemButton sx={{p: 1}} style={{height: '60px'}}>
                <ListItemIcon style={{minWidth: '35px'}}>
                  <img src={heart}/>
                </ListItemIcon>
                <ListItemText
                  primary={'BP measurement'}
                  primaryTypographyProps={{
                    style: {
                      color: "#D3302F", // "#0A87A9"
                    },
                  }}
                />
                <ListItemText
                  primary={'09:00'}
                  primaryTypographyProps={{
                    style: {
                      color: "#D3302F", // "#6f748f"
                      display:'flex', justifyContent:'flex-end'
                    },
                  }}
                />
              </ListItemButton>
            </ListItem> */}
            <MedicineGroup />
          </List>
        </Box>
      </Card>
    </>
  );
};

export default TodayList;
